import { useEffect, useRef } from "react";

export const getHeaders = (token: string) => {
  return {
    "Content-Type": "application/json; charset=utf-8",
    Authorization: `Bearer ${token}`,
  };
};

export const usePrev = (value: any) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

const useOutsideClick = (ref: any, callback: Function) => {
  useEffect(() => {
    const handleClickOutside = (evt: any) => {
      if (evt?.toElement?.parentNode?.type === "button") {
        return;
      }
      if (ref.current && !ref.current?.contains(evt.target)) {
        callback(); //Do what you want to handle in the callback
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
};

export default useOutsideClick;
