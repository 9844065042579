import React, { useRef, useState } from "react";
import { Dropdown, Menu, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faEdit } from "@fortawesome/free-solid-svg-icons";

import useOutsideClick from "../../../../../hooks/helpers";
import { PUBLIC_POLICY } from "../../../../../utils";

const EndpointOptions = (props: any) => {
  const { trigerChangePolicy, record } = props;
  const { policy } = record;
  const wrapperRef = useRef(null);
  const [isOpen, toggleMenu] = useState(false);
  useOutsideClick(wrapperRef, () => toggleMenu(false));
  return (
    <>
      <div ref={wrapperRef}>
        <Dropdown
          overlay={
            <Menu className="cell-options">
              <Menu.Item key="changePolicy">
                <Button
                  type="text"
                  onClick={(e) => {
                    e.stopPropagation();
                    trigerChangePolicy(record);
                    toggleMenu(false);
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} />
                  &nbsp; Change Policy
                </Button>
              </Menu.Item>
            </Menu>
          }
          placement="bottomRight"
          arrow
          onVisibleChange={() => toggleMenu(true)}
          trigger={["click"]}
          visible={isOpen}
        >
          {policy === PUBLIC_POLICY ? (
            <FontAwesomeIcon icon={faEllipsisV} />
          ) : (
            <FontAwesomeIcon icon={faEllipsisV} className={"disabled"} />
          )}
        </Dropdown>
      </div>
    </>
  );
};

export default EndpointOptions;
