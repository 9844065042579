/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Table, Space, Tag, Typography, Empty } from "antd";
import { useSelector } from "react-redux";

import AssignPolicy from "./AssignPolicy";
import EndpointOptions from "./EndpointOptions";
import { customIncludes } from "../../../../../utils";

const getColumns = ({ trigerChangePolicy }: any) => [
  { title: "Function", dataIndex: "name", key: "name" },
  {
    title: "Policy",
    dataIndex: "policy",
    key: "policy",
    render: (text: string, record: any) => (
      <span className="t-primary">{text}</span>
    ),
  },
  {
    title: "Action",
    dataIndex: "id",
    key: "id",
    render: (text: string, record: any) => (
      <EndpointOptions
        trigerChangePolicy={trigerChangePolicy}
        record={record}
      />
    ),
  },
  Table.EXPAND_COLUMN,
];

const Routes = (props: any) => {
  const { routes = [] } = props;
  return (
    <>
      {routes.length > 0
        ? routes.map((route: any, index: number) => {
            return (
              <Space key={index} className="w-100">
                <Tag color="#2db7f5" className="t-uppercase verb-tag">
                  {route.verb}
                </Tag>
                <Typography.Text copyable ellipsis>
                  {route.url}
                </Typography.Text>
              </Space>
            );
          })
        : "No Routes Available"}
    </>
  );
};

const Endpoints = (props: any) => {
  const { stageServices = [] } = useSelector((state: any) => state.services);
  const functionSearchTerm = useSelector(
    (state: any) => state.services.functionSearchTerm
  );
  const [endpoints, setEndPoints] = useState<any[]>([]);
  const [isAssignPolicy, setShowAssign] = useState(false);
  const [functionData, setFunctionData] = useState(null);

  const trigerChangePolicy = (record: any) => {
    setFunctionData(record);
    setShowAssign(true);
  };
  useEffect(() => {
    const _endpoints = stageServices.filter((func: any) => {
      if (!functionSearchTerm) {
        return true;
      } else if (customIncludes(func.name, functionSearchTerm)) {
        return true;
      } else {
        return false;
      }
    });
    setEndPoints(_endpoints);
  }, [functionSearchTerm]);

  const columns = getColumns({ trigerChangePolicy });
  return (
    <>
      <div className="http-endpoints scroll-y" style={{ height: "65vh" }}>
        <Table
          size="small"
          columns={columns}
          rowKey="id"
          expandable={{
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <Button onClick={(e) => onExpand(record, e)}>
                  Hide Routes
                </Button>
              ) : (
                <Button onClick={(e) => onExpand(record, e)}>
                  Show Routes
                </Button>
              ),
            expandedRowRender: (record) => <Routes routes={record?.url} />,
          }}
          dataSource={[...endpoints]}
          locale={{ emptyText: <Empty description="No endpoints created" /> }}
        />
      </div>
      <AssignPolicy
        isAssignPolicy={isAssignPolicy}
        setShowAssign={setShowAssign}
        functionData={functionData}
      />
    </>
  );
};

export default Endpoints;
