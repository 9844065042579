/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, notification } from "antd";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import { useDisableStoreTTL } from "../../../../hooks";
import { actions } from "../../../../reducers/stageStore";
import { actions as accountActions } from "../../../../reducers/account";

import { handleError } from "../../../../utils";

const { confirm } = Modal;

const disableTTL = (executeDisable: any, toggleDisableTTL: any, data: any) => {
  confirm({
    title: "Are you sure? You want to Disable TTL",
    icon: <FontAwesomeIcon icon={faExclamationCircle} className="anticon" />,
    onOk() {
      executeDisable({
        data,
      });
    },
    onCancel() {
      toggleDisableTTL(null);
    },
  });
};

const DisableTTL = (props: any) => {
  const { workspace, stage }: any = useParams();
  const dispatch = useDispatch();
  const { isDisableTTL, selectedStore } = useSelector(
    (state: any) => state.stageStore
  );
  const toggleDisableTTL = (objectStore: any) =>
    dispatch(actions.toggleDisableTTL(objectStore));

  const updateStageObjectStore = (objectStore: any) =>
    dispatch(actions.updateStageObjectStore(objectStore));
  const updateObjectStoreDetailsForAccount = (objectStore: any) =>
    dispatch(
      accountActions.updateObjectStoreDetailsForAccount(
        workspace,
        stage,
        objectStore
      )
    );
  const {
    loading: disableLoading,
    data: disableData,
    error: disableError,
    execute: executeDisable,
  } = useDisableStoreTTL();

  useEffect(() => {
    if (isDisableTTL) {
      disableTTL(executeDisable, toggleDisableTTL, {
        workspaceName: workspace,
        stageName: stage,
        storeName: selectedStore.name,
      });
    }
    // return () => {
    //   toggleDisableTTL(null);
    // };
  }, [isDisableTTL]);

  useEffect(() => {
    if (!disableLoading && !disableError && disableData) {
      updateStageObjectStore({
        ...selectedStore,
        TTLDurationInSec: undefined,
      });
      updateObjectStoreDetailsForAccount({
        ...selectedStore,
        TTLDurationInSec: undefined,
      });
      notification.success({
        message: disableData?.message ?? "TTL disabled successfully",
        duration: 3,
      });
      toggleDisableTTL(null);
    } else if (!disableLoading && disableError) {
      handleError(disableError);
      toggleDisableTTL(null);
    }
  }, [disableLoading, disableData, disableError]);

  return <></>;
};

export default DisableTTL;
