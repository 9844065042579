/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Input, Button, Card, Space, Spin, notification } from "antd";

import { useChangePassword } from "../../../../hooks";
import { actions as layoutActions } from "../../../../reducers/layout";
import { handleError, ROUTES } from "../../../../utils";

const ChangePassword = (props: any) => {
  const [called, setCalled] = useState(false);
  const { AccessToken } = useSelector(
    (state: any) => state.auth.authData ?? {}
  );
  const { loading, error, data, execute } = useChangePassword();
  const dispatch = useDispatch();
  const toggleSideBar = (open: boolean) =>
    dispatch(layoutActions.toggleSideBar(open));
  const onFinish = (values: any) => {
    const { confirmPassword, ...rest } = values;
    execute({
      data: {
        ...rest,
        accessToken: AccessToken,
      },
    });
    setCalled(true);
  };

  useEffect(() => {
    if (!loading && !error && data && called) {
      setCalled(false);
      notification.success({
        message: "Your password has been changed successfully",
      });
      window.location.pathname = ROUTES.WORKSPACES;
    } else if (!loading && error && called) {
      setCalled(false);
      handleError(error);
    }
  }, [loading, error, data, called]);

  useEffect(() => {
    toggleSideBar(false);
    return () => {
      toggleSideBar(true);
    };
  }, []);

  return (
    <div className="d-flex scroll-y" style={{ height: "65vh" }}>
      <Card title="Change Password" bordered={false}>
        <Spin spinning={loading}>
          <Form layout="vertical" name="basic" onFinish={onFinish}>
            <Form.Item
              label="Current Password"
              name="previousPassword"
              rules={[
                { required: true, message: "Please input your old password!" },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="Proposed Password"
              name="proposedPassword"
              rules={[
                { required: true, message: "Please input your new password!" },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              dependencies={["proposedPassword"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("proposedPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Space align="baseline" className="w-100 justify-content-end">
              <Button type="primary" ghost htmlType="submit">
                Update Password
              </Button>
            </Space>
          </Form>
        </Spin>
      </Card>
    </div>
  );
};

export default ChangePassword;
