/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form, Input, Modal, Spin } from "antd";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import cron from "cron-validate";

import { useUpdateScheduler } from "../../../../../hooks";
import { handleError } from "../../../../../utils";
import { actions } from "../../../../../reducers/services";

const EditScheduler = (props: any) => {
  const { editScheduler, toggleEditScheduler, scheduler } = props;
  const { workspace, stage }: any = useParams();
  const [schedulerInfo, setSchedulerInfo] = useState(scheduler);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { loading, error, data, execute } = useUpdateScheduler();
  const updateStageScheduler = (scheduler: any) =>
    dispatch(actions.updateStageScheduler(scheduler));

  useEffect(() => {
    if (!loading && !error && data) {
      updateStageScheduler(schedulerInfo);
      toggleEditScheduler(null);
    } else if (!loading && error) {
      handleError(error);
    }
  }, [loading, error, data]);

  const updateScheduler = () => {
    form
      .validateFields()
      .then((values: any) => {
        form.resetFields();
        const { id, description, expression, functionName } = scheduler;
        setSchedulerInfo({
          ...scheduler,
          ...values,
        });
        execute({
          data: {
            workspace,
            stage,
            id,
            serviceName: functionName,
            description,
            expression,
            ...values,
          },
        });
      })
      .catch((info: any) => {
        console.log("Validate Failed:", info);
      });
  };

  const onClose = () => {
    form.resetFields();
    toggleEditScheduler(null);
  };

  useEffect(() => {
    form.setFieldsValue(scheduler);
  }, [scheduler]);

  return (
    <>
      {editScheduler ? (
        <Modal
          title={`Edit Scheduler`}
          width={1000}
          visible={editScheduler}
          centered
          destroyOnClose={true}
          onOk={() => updateScheduler()}
          onCancel={onClose}
          okText="Confirm"
        >
          <Spin spinning={loading}>
            <Form layout="vertical" form={form} initialValues={scheduler ?? {}}>
              <Form.Item
                label="Cron Expression"
                name="expression"
                rules={[
                  {
                    required: true,
                    message: "Cron Expression is Required",
                  },
                  () => ({
                    validator(_, value) {
                      const cronResult = cron(value, {
                        preset: "aws-cloud-watch",
                      });
                      if (!cronResult.isValid()) {
                        return Promise.reject(
                          new Error("Invalid Cron Expression")
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item label="Description" name="description">
                <Input.TextArea />
              </Form.Item>
            </Form>
          </Spin>
        </Modal>
      ) : null}
    </>
  );
};

export default EditScheduler;
