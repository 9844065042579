import _filter from "lodash/filter";

import {
  updateWorkspaceStage,
  removeStageFromWorkspace,
  updateStageDetailsForWorkspace,
  updateStagePolicyDetailsForWorkspace,
  updateWorkspaceDetails,
  deleteStagePolicyFromWorkspace,
  updateObjectStoreDetailsForWorkspace,
  deleteObjectStoreDetailsForWorkspace,
  updateStageFunctionsForWorkspace,
} from "../utils";

const prefix = "ACCOUNT";

// Action types
const SET_DATA = `${prefix}_SET_DATA`;
const SET_ERROR = `${prefix}_SET_ERROR`;
const SELECT_WORKSPACE = `${prefix}_SELECT`;
const SELECT_STAGE_FOR_DELETE = `${prefix}_SELECT_STAGE_FOR_DELETE`;
const SET_STAGE_SEARCH = `${prefix}_SET_STAGE_SEARCH`;
const SET_REFETCH = `${prefix}_SET_REFETCH`;
const UPDATE_STAGES = `${prefix}_UPDATE_STAGES`;
const DELETE_STAGE = `${prefix}_DELETE_STAGE`;
const UPDATE_STAGE_DETAILS = `${prefix}_UPDATE_STAGE_DETAILS`;
const UPDATE_STAGE_POLICIES = `${prefix}_UPDATE_STAGE_POLICIES`;
const UPDATE_STAGE_FUNCTIONS = `${prefix}_UPDATE_STAGE_FUNCTIONS`;
const UPDATE_WORKSPACES = `${prefix}_UPDATE_WORKSPACES`;
const DELETE_STAGE_POLICY = `${prefix}_DELETE_STAGE_POLICY`;
const DELETE_WORKSPACE_FROM_ACCOUNT = `${prefix}_DELETE_WORKSPACE_FROM_ACCOUNT`;
const UPDATE_OBJECT_STORE_DETAILS = `${prefix}_UPDATE_OBJECT_STORE_DETAILS`;
const DELETE_OBJECT_STORE_DETAILS = `${prefix}_DELETE_OBJECT_STORE_DETAILS`;

// State
const defaultState: { [key: string]: any } = {
  workspaces: [],
  tenantName: null,
  selectedWorkspace: {},
  error: null,
  selectedStage: {
    accountId: null,
    stageName: null,
  },
  openDeleteStageModal: false,
  stageSearchTerm: "",
  shouldRefetch: false,
};

// Actions
const selectWorkspaceData = (data: any) => ({
  type: SET_DATA,
  data,
});

const selectWorkspaceError = (error: any) => ({
  type: SET_ERROR,
  error,
});

const selectWorkspace = (selectedWorkspace: any) => ({
  type: SELECT_WORKSPACE,
  selectedWorkspace,
});

const selectStageForDelete = (selectedStage: any) => ({
  type: SELECT_STAGE_FOR_DELETE,
  selectedStage,
});

const setStageSearchTerm = (stageSearchTerm: string) => ({
  type: SET_STAGE_SEARCH,
  stageSearchTerm,
});

const setRefetch = (shouldRefetch: boolean) => ({
  type: SET_REFETCH,
  shouldRefetch,
});

const updateWorkspaceStages = (workspace: string, stage: any) => ({
  type: UPDATE_STAGES,
  workspace,
  stage,
});

const deleteWorkspaceStage = (
  workspace: string | null,
  stage: string | null
) => ({
  type: DELETE_STAGE,
  workspace,
  stage,
});

const updateStageDetails = (
  workspace: string,
  stage: string,
  stageDetails: any
) => ({
  type: UPDATE_STAGE_DETAILS,
  workspace,
  stage,
  stageDetails,
});

const updateStagePoliciesForAccount = (
  workspace: string,
  stage: string,
  policy: any
) => ({
  type: UPDATE_STAGE_POLICIES,
  workspace,
  stage,
  policy,
});

const updateWorkspaces = (workspace: any) => ({
  type: UPDATE_WORKSPACES,
  workspace,
});

const deleteStagePolicyForAccount = (
  workspace: string,
  stage: string,
  policy: any
) => ({
  type: DELETE_STAGE_POLICY,
  workspace,
  stage,
  policy,
});

const deleteWorkspaceFromAccount = (workspaceName: string) => ({
  type: DELETE_WORKSPACE_FROM_ACCOUNT,
  workspaceName,
});

const updateObjectStoreDetailsForAccount = (
  workspace: string,
  stage: string,
  objectStore: any
) => ({
  type: UPDATE_OBJECT_STORE_DETAILS,
  workspace,
  stage,
  objectStore,
});

const deleteObjectStoreDetailsForAccount = (
  workspace: string,
  stage: string,
  objectStore: any
) => ({
  type: DELETE_OBJECT_STORE_DETAILS,
  workspace,
  stage,
  objectStore,
});

const updateStageFunctionsForAcccount = (
  workspace: string,
  stage: string,
  functions: any
) => ({
  type: UPDATE_STAGE_FUNCTIONS,
  workspace,
  stage,
  functions,
});

export const actions = {
  selectWorkspaceData,
  selectWorkspaceError,
  selectWorkspace,
  selectStageForDelete,
  setStageSearchTerm,
  setRefetch,
  updateWorkspaceStages,
  deleteWorkspaceStage,
  updateStageDetails,
  updateStagePoliciesForAccount,
  updateStageFunctionsForAcccount,
  updateWorkspaces,
  deleteStagePolicyForAccount,
  deleteWorkspaceFromAccount,
  updateObjectStoreDetailsForAccount,
  deleteObjectStoreDetailsForAccount,
};

// Reducers
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = defaultState, action: any) {
  switch (action.type) {
    case SET_DATA:
      return {
        ...state,
        // TODO: API is still returning accounts
        workspaces: action.data.accounts,
        tenantName: action.data?.name,
      };
    case SET_ERROR:
      return { ...state, error: action.error };
    case SELECT_WORKSPACE:
      return { ...state, selectedWorkspace: action.selectedWorkspace };
    case SELECT_STAGE_FOR_DELETE:
      return { ...state, selectedStage: action.selectedStage };
    case SET_STAGE_SEARCH:
      return { ...state, stageSearchTerm: action.stageSearchTerm };
    case SET_REFETCH:
      return { ...state, shouldRefetch: action.shouldRefetch };
    case UPDATE_STAGES:
      return {
        ...state,
        workspaces: updateWorkspaceStage(
          state.workspaces,
          action.workspace,
          action.stage
        ),
      };
    case DELETE_STAGE:
      return {
        ...state,
        workspaces: removeStageFromWorkspace(
          state.workspaces,
          action.workspace,
          action.stage
        ),
      };
    case UPDATE_STAGE_DETAILS:
      return {
        ...state,
        workspaces: updateStageDetailsForWorkspace(
          state.workspaces,
          action.workspace,
          action.stage,
          action.stageDetails
        ),
      };
    case UPDATE_STAGE_POLICIES:
      return {
        ...state,
        workspaces: updateStagePolicyDetailsForWorkspace(
          state.workspaces,
          action.workspace,
          action.stage,
          action.policy
        ),
      };
    case UPDATE_STAGE_FUNCTIONS:
      return {
        ...state,
        workspaces: updateStageFunctionsForWorkspace(
          state.workspaces,
          action.workspace,
          action.stage,
          action.functions
        ),
      };
    case UPDATE_WORKSPACES:
      return {
        ...state,
        workspaces: updateWorkspaceDetails(state.workspaces, action.workspace),
      };
    case DELETE_STAGE_POLICY:
      return {
        ...state,
        workspaces: deleteStagePolicyFromWorkspace(
          state.workspaces,
          action.workspace,
          action.stage,
          action.policy
        ),
      };
    case DELETE_WORKSPACE_FROM_ACCOUNT:
      return {
        ...state,
        workspaces: _filter(
          state.workspaces,
          (workspace: any) => workspace.name !== action.workspaceName
        ),
      };
    case UPDATE_OBJECT_STORE_DETAILS:
      return {
        ...state,
        workspaces: updateObjectStoreDetailsForWorkspace(
          state.workspaces,
          action.workspace,
          action.stage,
          action.objectStore
        ),
      };
    case DELETE_OBJECT_STORE_DETAILS:
      return {
        ...state,
        workspaces: deleteObjectStoreDetailsForWorkspace(
          state.workspaces,
          action.workspace,
          action.stage,
          action.objectStore
        ),
      };
    default:
      return state;
  }
}
