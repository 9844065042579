/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link, useHistory } from "react-router-dom";
import _isEqual from "lodash/isEqual";
import { Tabs, Breadcrumb, PageHeader, Select } from "antd";

import { Services, Metrics, EventGateway, ObjectStore } from "./components";
import { Scaffold } from "../../common";
import { getStageDetails, getStagesByAcccount } from "./helpers";
import { actions as layoutActions } from "../../reducers/layout";
import { actions as serviceActions } from "../../reducers/services";
import { actions as storeActions } from "../../reducers/stageStore";
import { actions as accountActions } from "../../reducers/account";
import { usePrev } from "../../hooks/helpers";
import { ROUTES, TAB_KEYS } from "../../utils";
import { useQuery } from "../../components";

const { TabPane } = Tabs;
const Stage = (props: any) => {
  const { loading } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  let screen = query.get("screen");
  const { workspace, stage } = useParams<any>();
  const workspaces = useSelector((state: any) => state.account.workspaces);
  const { currentTab } = useSelector((state: any) => state.layout);
  const { stageServices } = useSelector((state: any) => state.services);
  const { stageVariables } = useSelector((state: any) => state.stageStore);
  const prevStageServices = usePrev(stageServices);
  const prevStageVariables = usePrev(stageVariables);

  const stageDetails = getStageDetails(workspaces, workspace, stage);
  const stages = getStagesByAcccount(workspaces, workspace);

  const setCurrentTab = (activeKey: string) =>
    dispatch(layoutActions.setCurrentTab(activeKey));
  const setStageServices = (services: any) =>
    dispatch(serviceActions.setStageServices(services));
  const setStageVariables = (variables: any) =>
    dispatch(storeActions.setStageVariables(variables));
  const updateStageDetails = (stageDetails: any) =>
    dispatch(accountActions.updateStageDetails(workspace, stage, stageDetails));
  const setStagePolicies = (policies: any) =>
    dispatch(serviceActions.setStagePolicies(policies));

  const setStageObjectStores = (objectStores: any[]) =>
    dispatch(storeActions.setStageObjectStores(objectStores));

  const onStageSelect = (value: string) => {
    history.push(`${ROUTES.WORKSPACES}/${workspace}/${value}`);
  };

  useEffect(() => {
    if (workspace && stage && stageDetails) {
      if (!currentTab) {
        console.log("in use effect");
        setCurrentTab(TAB_KEYS.EVENTGATEWAY);
      }
      setStageServices(stageDetails?.services);
      setStageVariables(stageDetails?.stageVariables);
      setStagePolicies(stageDetails?.policies);
      setStageObjectStores(stageDetails?.objectStores);
    }
  }, [workspace, stage, stageDetails]);

  useEffect(() => {
    if (
      !_isEqual(stageServices, prevStageServices) ||
      !_isEqual(stageVariables, prevStageVariables)
    ) {
      updateStageDetails({
        stageServices,
        stageVariables,
      });
    }
  }, [stageServices, stageVariables, prevStageVariables, prevStageServices]);

  useEffect(() => {
    return () => {
      setCurrentTab(TAB_KEYS.EVENTGATEWAY);
      setStageServices([]);
      setStageVariables([]);
    };
  }, []);

  useEffect(() => {
    if (screen) {
      setCurrentTab(screen);
    }
  }, [screen]);

  const updateTab = (tabKey: string) => {
    history.push({
      search: `?screen=${tabKey}`,
    });
    setCurrentTab(tabKey);
  };

  const options = stages?.map((stg: string) => {
    return { name: stg, value: stg };
  });

  return (
    <Scaffold loading={loading}>
      <PageHeader title="" className="stage-page-header" ghost={false}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={`${ROUTES.WORKSPACES}/${workspace}`}>{workspace}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Select
              bordered={false}
              style={{ minWidth: 150 }}
              showSearch
              placeholder="choose stage"
              options={options}
              onSelect={onStageSelect}
              value={stageDetails?.name}
              notFoundContent="No results found"
            />
          </Breadcrumb.Item>
        </Breadcrumb>
      </PageHeader>
      <section className="page-content">
        <Tabs activeKey={currentTab} onChange={updateTab}>
          <TabPane
            tab={<span>&nbsp; Event Gateway</span>}
            key={TAB_KEYS.EVENTGATEWAY}
          >
            <EventGateway />
          </TabPane>
          <TabPane tab={<span>&nbsp; Functions</span>} key={TAB_KEYS.FUNCTIONS}>
            <Services />
          </TabPane>
          {/* <TabPane tab={<span>&nbsp; Store</span>} key="store">
            <Store />
          </TabPane> */}
          <TabPane
            tab={<span>&nbsp; Object Store</span>}
            key={TAB_KEYS.OBJECTSTORE}
          >
            <ObjectStore />
          </TabPane>
          <TabPane tab={<span>&nbsp; Metrics</span>} key={TAB_KEYS.METRICS}>
            <Metrics />
          </TabPane>
        </Tabs>
      </section>
    </Scaffold>
  );
};

export default Stage;
