import React, { useCallback, useEffect } from "react";
import { Button, Spin } from "antd";
import { useSelector } from "react-redux";
import { useStripe } from "@stripe/react-stripe-js";

import { useInitiatePayment } from "../../../../../hooks";
import { getOrigin, handleError, ROUTES } from "../../../../../utils";

const ChangeCard = (props: any) => {
  const { billing } = useSelector((state: any) => state.auth);
  const { StripeCustomerId, RechargeParams } = billing;
  const stripe = useStripe();
  const { loading, data, error, execute } = useInitiatePayment();
  const changeCard = async () => {
    execute({
      data: {
        customerId: StripeCustomerId,
        // Stripe will take the value in lowest denomination so multiplying it with 100.
        recharge_amount: 1000,
        currency: RechargeParams?.Currency ?? "",
        success_url: `${getOrigin()}${ROUTES.TXN_SUCCESS}`,
        failure_url: `${getOrigin()}${ROUTES.TXN_FAILURE}`,
      },
    });
  };

  const redirectToCheckout = useCallback(
    async (sessionId) => {
      try {
        await stripe?.redirectToCheckout({
          sessionId,
        });
      } catch (e) {
        console.error(e);
      }
    },
    [stripe]
  );

  useEffect(() => {
    if (!loading && !error && data) {
      redirectToCheckout(data.sessionID);
    } else if (!loading && error) {
      handleError(error);
    }
  }, [loading, data, error, redirectToCheckout]);

  return (
    <>
      <Spin spinning={loading}>
        <Button type="primary" ghost onClick={changeCard}>
          Change Credit Card
        </Button>
      </Spin>
    </>
  );
};
export default ChangeCard;
