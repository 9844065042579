/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Tabs } from "antd";

import { useQuery } from "../../../components";
import { BillingDetails } from "../components";
import OrgAccount from "../components/organization/OrgAccount";

const { TabPane } = Tabs;

const OrgSettings = (props: any) => {
  const query = useQuery();
  let tab = query.get("tab");
  const [currentTab, setCurrentTab] = useState(tab ?? "details");
  return (
    <div className="px-2">
      <Tabs
        tabPosition="left"
        type="card"
        activeKey={currentTab}
        onChange={setCurrentTab}
      >
        {/* <TabPane tab={<span>Details</span>} key="details">
          <OrganizationDetails />
        </TabPane> */}
        {/* <TabPane tab={<span>Workspaces</span>} key="workspaces"></TabPane> */}
        {/* <TabPane
          tab={<span>Users &amp; Teams</span>}
          key="usersAndTeams"
        ></TabPane> */}
        <TabPane tab={<span>Billing</span>} key="billing">
          <BillingDetails />
        </TabPane>
        {/* <TabPane tab={<span>Audit Log</span>} key="auditLog"></TabPane> */}
        <TabPane tab={<span>Account</span>} key="account">
          <OrgAccount />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default OrgSettings;
