import useAxios from "axios-hooks";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { ROOT_URL } from "../../utils";
import { useAuthHeaders } from "../auth";

const getFunctionName = (
  tenant: string,
  workspace: string,
  stage: string,
  service: string
) => {
  return `${tenant}-${workspace}-${stage}-${service}`;
};
const useMetrics = (props: any) => {
  const { service, timePeriod } = props;
  const { workspace, stage } = useParams<any>();
  const { tenantName } = useSelector((state: any) => state.account);
  const headers = useAuthHeaders();

  const [{ data, loading, error }, execute] = useAxios(
    {
      url: `${ROOT_URL}/monitoring/metric`,
      params: {
        functionname: getFunctionName(tenantName, workspace, stage, service),
        timeperiod: timePeriod,
      },
      method: "GET",
      headers,
    },
    { manual: true }
  );

  return {
    data,
    loading,
    error,
    execute,
  };
};

export default useMetrics;
