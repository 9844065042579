import React, { useState } from "react";
import { Button, Space, Table, Modal, Form, Input, Row } from "antd";

import { required, minimum, maximum } from "../../../../../../utils";

const getUserListColumns = (removeUser: Function) => {
  return [
    { title: "Username", dataIndex: "name", key: "name" },
    {
      title: "Password",
      dataIndex: "password",
      key: "password",
      render: (text: string) => <span>*********</span>,
    },
    {
      title: "Action",
      dataIndex: "name",
      key: "name",
      render: (text: string) => (
        <Space size="middle">
          <Button type="text" danger onClick={() => removeUser(text)}>
            Remove
          </Button>
        </Space>
      ),
    },
  ];
};

const Users = (props: any) => {
  const { users, setUsers } = props;
  const [form] = Form.useForm();
  const [isUserAdd, setUserAdd] = useState(false);
  const _userNames = users.map((u: any) => u.name);

  const addUser = (form: any) => {
    form
      .validateFields()
      .then((values: any) => {
        form.resetFields();
        setUsers([...users, values]);
        setUserAdd(false);
      })
      .catch((info: any) => {
        console.log("Add User validation failed:", info);
      });
  };

  const cancel = (form: any) => {
    form.resetFields();
    setUserAdd(false);
  };

  const removeUser = (username: string) => {
    const _users = users.filter((u: any) => u.name !== username);
    setUsers(_users);
  };
  const columns = getUserListColumns(removeUser);
  return (
    <>
      <Row justify="space-between">
        <h3>Authorized Users List</h3>
        <Button type="primary" ghost className="pull-right" onClick={(e) => setUserAdd(true)}>
          Add user
        </Button>
      </Row>
      <Table size="small" columns={columns} dataSource={users} />
      <Modal
        visible={isUserAdd}
        centered
        title="Add User"
        destroyOnClose
        onOk={() => addUser(form)}
        onCancel={() => cancel(form)}
        okText="Save"
        cancelText="Cancel"
      >
        <p>
          Following user will be part of a list of authorized users for the policy created
        </p>
        <Form
          layout="vertical"
          form={form}
          name="variableForm"
          preserve={false}
        >
          <Form.Item
            label="Username"
            name="name"
            rules={[
              required("Username"),
              minimum("Username"),
              maximum("Username"),
              () => ({
                validator(_, value) {
                  if (value && _userNames.includes(value)) {
                    return Promise.reject(new Error("Username already exist"));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
            normalize={(value: string) => value.trim()}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              required("Password"),
              minimum("Password"),
              maximum("Password"),
            ]}
            normalize={(value: string) => value.trim()}
          >
            <Input.Password type="password" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default Users;
