import { notification } from "antd";
import { AxiosError } from "axios";
import { LOCAL_STORE_KEYS } from "./constants";
import { localStorage } from "./utils";

const extractErrorMessage = (errorInfo: any = {}) => {
  const defaultMsg = "Internal server error";
  const { errorMessage, error } = errorInfo;
  return errorMessage || error || defaultMsg;
};
export const handleError = (error: AxiosError) => {
  const errorInfo = error?.response?.data ?? {};
  switch (error.message) {
    case "Network Error":
      notification.warning({
        message: "Session Expired",
        duration: 3,
      });
      console.log(error);
      setTimeout(() => {
        localStorage.removeItem(LOCAL_STORE_KEYS.AUTH_DATA);
        localStorage.removeItem(LOCAL_STORE_KEYS.USER_DATA);
        localStorage.removeItem(LOCAL_STORE_KEYS.BILLING_DATA);
        window.location.pathname = "/";
      }, 3000);
      break;
    default:
      notification.error({
        message: extractErrorMessage(errorInfo),
        duration: 5,
      });
      break;
  }
};
