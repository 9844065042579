import useAxios from "axios-hooks";
import { useParams } from "react-router-dom";

import { ROOT_URL } from "../../utils";
import { useAuthHeaders } from "../auth";

const useServiceLogs = (props: any) => {
  const { serviceName, logType, timePeriod = "3H" } = props;
  const { workspace, stage } = useParams<any>();
  const headers = useAuthHeaders();

  const [{ data, loading, error }, execute] = useAxios(
    {
      url: `${ROOT_URL}/logging/logs`,
      params: {
        account: workspace,
        stage,
        serviceName,
        logType,
        timePeriod,
      },
      method: "GET",
      headers,
    },
    { manual: true }
  );

  return {
    data,
    loading,
    error,
    execute,
  };
};

export default useServiceLogs;
