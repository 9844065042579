/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Drawer,
  List,
  Divider,
  Space,
  Tag,
  Typography,
  Table,
  Button,
} from "antd";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import { useServiceDetails } from "../../../../hooks";
import { actions } from "../../../../reducers/services";
import { globalDateFormat, handleError, TAB_KEYS } from "../../../../utils";

const ENV_VARS_COLUMNS = [
  {
    title: "Key",
    dataIndex: "key",
    key: "key",
  },
  {
    title: "Value",
    dataIndex: "value",
    key: "value",
  },
];

const getSchedulerColumns = (goToSchedulers: any) => [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (cell: string) => (
      <Button type="link" onClick={() => goToSchedulers(cell)}>
        {cell}
      </Button>
    ),
  },
  {
    title: "Reference",
    dataIndex: "reference",
    key: "reference",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "State",
    dataIndex: "state",
    key: "state",
  },
];

const ShowServiceDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { showService, service } = useSelector((state: any) => state.services);
  const [serviceDetails, setServiceDetails] = useState<any>();
  const { loading, error, data, execute } = useServiceDetails({
    service: service?.name,
  });
  const toggleShowService = (service: any) =>
    dispatch(actions.toggleShowService(service));

  useEffect(() => {
    if (!loading && !error && data) {
      setServiceDetails(data);
    } else if (!loading && error) {
      handleError(error);
    }
  }, [loading, error, data]);

  useEffect(() => {
    if (service) {
      execute();
    }
  }, [service]);

  const goToSchedulers = (schedulerId: string) => {
    toggleShowService("");
    history.push({
      search: `?screen=${TAB_KEYS.EVENTGATEWAY}&section=${TAB_KEYS.SCHEDULERS}&scheduler=${schedulerId}`,
    });
  };
  const SCHEDULER_COLUMNS = getSchedulerColumns(goToSchedulers);
  return (
    <Drawer
      title={
        <span>
          <FontAwesomeIcon icon={faInfoCircle} /> &nbsp; Function Details
        </span>
      }
      width={750}
      className="service-details"
      placement="right"
      onClose={() => toggleShowService("")}
      visible={showService}
    >
      <Divider orientation="left">General</Divider>
      <List>
        <List.Item>
          <Space className="justify-content-between w-100">
            <div>Depoyed On:</div>
            <div>{globalDateFormat(serviceDetails?.deployDate)}</div>
          </Space>
        </List.Item>
        <List.Item>
          <Space className="justify-content-between w-100">
            <div>Version:</div>
            <div>{serviceDetails?.version}</div>
          </Space>
        </List.Item>
      </List>
      <Divider orientation="left">Endpoints</Divider>
      <List>
        <List.Item>
          <div className="d-flex">
            <div className="w-100">
              Public URL's:
              {serviceDetails?.url.length === 0 ? " NA " : ""}
            </div>
            {serviceDetails?.url.map((url: any, index: number) => {
              return (
                <Space key={index}>
                  <Tag color="#2db7f5" className="t-uppercase">
                    {url.verb}
                  </Tag>
                  <Typography.Text
                    style={{ width: "550px" }}
                    copyable
                    ellipsis={{ tooltip: url.url }}
                  >
                    {url.url}
                  </Typography.Text>
                </Space>
              );
            })}
          </div>
        </List.Item>
      </List>
      <Divider orientation="left">Schedulers</Divider>
      <Table
        columns={SCHEDULER_COLUMNS}
        dataSource={serviceDetails?.schedulers}
        size="small"
      ></Table>
      <Divider orientation="left">Environment</Divider>
      <Table
        columns={ENV_VARS_COLUMNS}
        dataSource={serviceDetails?.environmentVars}
        size="small"
      ></Table>
    </Drawer>
  );
};

export default ShowServiceDetails;
