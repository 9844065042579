import _all from "lodash/every";
import _isEmpty from "lodash/isEmpty";

import {
  SECURITY_TYPE,
  SIGNATURE_TYPE,
  TRAFFIC_CONST,
  difference,
} from "../../../../../utils";

const VALIDATION_MESSAGES = {
  USERS_REQUIRED: "Users are required for Basic Authentication",
  SIGNATURE_REQUIRED: "One of Secret or Key or JWKS is required",
};

interface PolicyUser {
  name: string;
  password: string;
}
interface PolicyClaim {
  name: string;
  value: string;
}

interface PolicyRequest {
  policyName: string;
  throughput?: number;
  quota?: {
    requests: number;
    period: string;
  };
  ipWhiteList?: {
    list?: string[];
    cidr?: string;
    range?: {
      from: string;
      to: string;
    };
  };
  secret?: string;
  key?: string;
  jwks?: {
    url: string;
  };
  users?: PolicyUser[];
  claims?: PolicyClaim[];
}

export const buildSummary = ({
  form,
  security,
  signatureType,
  users,
  claims,
}: any) => {
  const formValues = form.getFieldsValue(true);
  const { name, throughput, quota, ipWhiteList = {}, ...rest } = formValues;
  const {
    list = undefined,
    cidr = undefined,
    range = undefined,
  } = ipWhiteList ?? {};
  let finalRequest: PolicyRequest = {
    policyName: name,
    throughput: throughput ? Number(throughput) : undefined,
    quota: quota?.requests
      ? {
          requests: Number(quota?.requests),
          period: quota.period,
        }
      : undefined,
    ipWhiteList:
      list || cidr || range
        ? {
            list: list ? list.split(";") : undefined,
            cidr: cidr ? cidr : undefined,
            range: range?.from ? range : undefined,
          }
        : undefined,
  };
  let isValidData = true;
  const validationMessages: string[] = [];
  switch (security) {
    case SECURITY_TYPE.BASIC:
      if (users.length === 0) {
        isValidData = false;
        validationMessages.push(VALIDATION_MESSAGES.USERS_REQUIRED);
      } else {
        finalRequest = {
          ...finalRequest,
          users,
        };
      }
      break;
    case SECURITY_TYPE.JWT:
      if (_all([rest.secret, rest.key, rest.jwks?.url], _isEmpty)) {
        isValidData = false;
        validationMessages.push(VALIDATION_MESSAGES.SIGNATURE_REQUIRED);
      } else {
        finalRequest = {
          ...finalRequest,
          secret: rest.secret ? rest.secret : undefined,
          key: rest.key ? rest.key : undefined,
          jwks: rest.jwks?.url ? rest.jwks : undefined,
          claims,
        };
      }
      break;
    case SECURITY_TYPE.NONE:
    default:
      break;
  }
  return {
    isValidData,
    finalRequest,
    security,
    signatureType,
    validationMessages,
  };
};

export const extractIntialValues = (policy: any) => {
  const { type, ipWhiteList = {}, jwks, key, apiKeyValue } = policy ?? {};
  let result = {
    id: "",
    initialSecurity: type ?? SECURITY_TYPE.NONE,
    initialSignatureType: SIGNATURE_TYPE.SECRET,
    initialWhiteListType: "",
    quota: {
      period: "day",
    },
    users: [],
    claims: [],
    apiKeyValue,
    ...policy,
  };
  const { list, cidr, range } = ipWhiteList ?? {};
  if (jwks?.url) {
    result.initialSignatureType = SIGNATURE_TYPE.JWKS;
  }
  if (key) {
    result.initialSignatureType = SIGNATURE_TYPE.KEY;
  }

  if (list) {
    result.initialWhiteListType = TRAFFIC_CONST.LIST;
    result = {
      ...result,
      ipWhiteList: {
        list: list.join(";"),
      },
    };
  }
  if (range?.from) {
    result.initialWhiteListType = TRAFFIC_CONST.RANGE;
  }
  if (cidr) {
    result.initialWhiteListType = TRAFFIC_CONST.CIDR;
  }

  return result;
};

export const hasChanges = (newData: any, oldData: any) => {
  if (!oldData) {
    return true;
  }
  const { finalRequest = {}, security } = newData ?? {};
  const { id, name, apiKeyValue, ...rest } = oldData ?? {};
  newData = JSON.parse(
    JSON.stringify({
      ...finalRequest,
      type: security,
    })
  );
  oldData = JSON.parse(
    JSON.stringify({
      policyName: name,
      ...rest,
    })
  );
  const diff = difference(newData, oldData);
  return !!Object.keys(diff).length;
};
