import React, { useState, useEffect } from "react";
import { Card, Empty, Form, Input, Spin, Table } from "antd";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import _upperCase from "lodash/upperCase";

import { useCustomerInvoices } from "../../../../../hooks";
import { handleError, GLOBAL_DATE_FORMAT } from "../../../../../utils";

// Time stamp is multiplying with 1000 as the returned value is in seconds rather than milliseconds
const INVOICE_TABLE_COLUMNS = [
  { title: "ID", dataIndex: "number", key: "number" },
  {
    title: "Amount",
    dataIndex: "total",
    key: "total",
    // Multiplying with 100 as the values is sent in paisa / cents
    render: (text: any) => Number(text) / 100,
  },
  {
    title: "Currency",
    dataIndex: "currency",
    key: "currency",
    render: (text: any) => _upperCase(text),
  },
  {
    title: "Start Date",
    dataIndex: "period_start",
    key: "period_start",
    render: (text: any) => moment(text * 1000).format(GLOBAL_DATE_FORMAT),
  },
  {
    title: "End Date",
    dataIndex: "period_end",
    key: "period_end",
    render: (text: any) => moment(text * 1000).format(GLOBAL_DATE_FORMAT),
  },
  {
    title: "Download",
    dataIndex: "PDF_download_URL",
    key: "downloadUrl",
    render: (text: any) => (
      <a href={text} target="_blank" rel="noreferrer" title="Download Invoice">
        <FontAwesomeIcon icon={faDownload} />{" "}
      </a>
    ),
  },
];

const Invoices = (props: any) => {
  const { loading, data, error } = useCustomerInvoices();
  const [invoices, setInvoices] = useState([]);
  useEffect(() => {
    if (!loading && !error && data) {
      setInvoices(data?.invoices);
    } else if (!loading && error) {
      handleError(error);
    }
  }, [loading, data, error]);
  return (
    <Card title="Invoices" bordered={false}>
      <Spin spinning={loading}>
        <h4>Invoice address</h4>
        <p>
          If left blank, invoices will be sent to group of users with 'Biller'
          role
        </p>
        <Form.Item>
          <Input type="string" />
        </Form.Item>
        <h4>Invoice History</h4>
        <Table
          size="small"
          columns={INVOICE_TABLE_COLUMNS}
          dataSource={invoices}
          locale={{
            emptyText: <Empty description="No invoices available yet" />,
          }}
        />
      </Spin>
    </Card>
  );
};
export default Invoices;
