import React from "react";
import { Button, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faSpinner } from "@fortawesome/free-solid-svg-icons";

import ServiceOptions from "./components/services/ServiceOptions";
import StoreOptions from "./components/store/StoreOptions";
import ObjectStoreOptions from "./components/object-store/StoreOptions";
import { globalDateFormat } from "../../utils";
import {
  SERVICE_STARTED,
  SERVICE_STARTING,
  SERVICE_DEPLOYING,
  SERVICE_STOPPING,
  SERVICE_DELETING,
  DEPLOY_FAILED,
  SERVICE_STOPPED,
} from "../../utils";

const { Text } = Typography;

const KNOWN_STATUS = [
  SERVICE_STARTED,
  SERVICE_STARTING,
  SERVICE_DEPLOYING,
  SERVICE_STOPPING,
  SERVICE_DELETING,
  DEPLOY_FAILED,
  SERVICE_STOPPED,
];

const IN_PROCESS_SATES = [
  SERVICE_STARTING,
  SERVICE_DEPLOYING,
  SERVICE_STOPPING,
  SERVICE_DELETING,
];

const funtionStatusFilters = KNOWN_STATUS.map((status: string) => {
  return {
    text: status,
    value: status,
  };
});

const ServiceStatus = (props: any) => {
  const { status } = props;
  return (
    <>
      {[
        SERVICE_STARTED,
        DEPLOY_FAILED,
        SERVICE_STOPPED,
        ...IN_PROCESS_SATES,
      ].includes(status) ? (
        <>
          {status === SERVICE_STARTED ? (
            <Text type="success">{status} </Text>
          ) : null}
          {status === DEPLOY_FAILED ? (
            <Text
              type="danger"
              title="The service will fall back to the previous running version"
            >
              {status} {"  "}
              <sup>
                <FontAwesomeIcon icon={faInfo}></FontAwesomeIcon>
              </sup>
            </Text>
          ) : null}
          {IN_PROCESS_SATES.includes(status) ? (
            <Text disabled>
              {status}
              {"  "}
              <FontAwesomeIcon
                icon={faSpinner}
                className="fa-spin"
              ></FontAwesomeIcon>
            </Text>
          ) : null}
          {status === SERVICE_STOPPED ? (
            <Text type="warning">{status}</Text>
          ) : null}
        </>
      ) : (
        <Text>{status} </Text>
      )}
    </>
  );
};

export const getServicesColumns = (columnAction: any) => [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: true,
    render: (name: string, record: any) => (
      <Button type="link" onClick={(e) => columnAction(record)}>
        {name}
      </Button>
    ),
  },
  {
    title: "Version",
    dataIndex: "version",
    key: "version",
    sorter: true,
  },
  {
    title: "Deployed On",
    dataIndex: "deployDate",
    key: "deployedOn",
    render: (date: string) => globalDateFormat(date),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    sorter: true,
    filters: funtionStatusFilters,
    onFilter: (value: any, record: any) => record.status.indexOf(value) === 0,
    render: (cell: string, row: any) => <ServiceStatus status={cell} />,
  },
  {
    title: "Action",
    key: "action",
    render: (cell: string, row: any) => <ServiceOptions {...row} />,
  },
];

export const getStoreColumms = (hasAction: boolean) => [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: true,
    width: 250,
  },
  {
    title: "Value",
    dataIndex: "value",
    key: "value",
    sorter: true,
    render: (cell: any) => {
      if (typeof cell === "string" || typeof cell === "number") {
        return <Text code>{cell}</Text>;
      } else {
        return (
          <Text code copyable>
            {JSON.stringify(cell, null, 2)}
          </Text>
        );
      }
    },
  },
  hasAction
    ? {
        title: "Action",
        key: "action",
        render: () => <StoreOptions />,
      }
    : {},
];

export const getVersionColumns = () => [
  {
    title: "Version",
    dataIndex: "version",
    key: "version",
    sorter: true,
  },
  {
    title: "Date",
    dataIndex: "deployDate",
    key: "deployDate",
    sorter: true,
    render: (date: string) => globalDateFormat(date),
  },
  {
    title: "Description ",
    key: "description",
    dataIndex: "description",
  },
];

export const getObjectStoreColumms = ({ toggleShowStoreDetails }: any) => [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: true,
    render: (name: string, record: any) => (
      <Button type="link" onClick={(e) => toggleShowStoreDetails(record)}>
        {name}
      </Button>
    ),
  },
  {
    title: "Created At",
    dataIndex: "createdTS",
    key: "value",
    sorter: true,
    render: (date: string) => globalDateFormat(date),
  },
  {
    title: "TTL Duration (seconds)",
    dataIndex: "TTLDurationInSec",
    key: "TTLDurationInSec",
    sorter: true,
    render: (ttl: string) => (ttl ? ttl : "NA"),
  },
  {
    title: "Stream",
    dataIndex: "streamAssociated",
    key: "streamAssociated",
    sorter: true,
    render: (stream: boolean) => (stream ? "Enabled" : "Disabled"),
  },
  {
    title: "Action",
    key: "action",
    render: (cell: string, row: any) => <ObjectStoreOptions {...row} />,
  },
];
