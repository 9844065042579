import useAxios from "axios-hooks";

import { ROOT_URL } from "../../utils";

const useConfirmPassword = () => {
  const [{ data, loading, error }, refetch] = useAxios(
    {
      url: `${ROOT_URL}/security/confirmforgetpassword`,
      method: "POST",
      headers: {
        accept: "application/json",
      },
    },
    { manual: true }
  );

  return {
    data,
    loading,
    error,
    execute: refetch,
  };
};

export default useConfirmPassword;
