/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Tabs } from "antd";

import { ChangePassword } from "../components";
import { useQuery } from "../../../components";

const { TabPane } = Tabs;

const UserSettings = (props: any) => {
  const query = useQuery();
  let tab = query.get("tab");
  const [currentTab, setCurrentTab] = useState(tab ?? "password");
  return (
    <div className="px-2">
      <Tabs
        tabPosition="left"
        type="card"
        activeKey={currentTab}
        onChange={setCurrentTab}
      >
        {/* <TabPane tab={<span>&nbsp; Profile</span>} key="profile">
          <UserProfile />
        </TabPane> */}
        <TabPane tab={<span>&nbsp; Password</span>} key="password">
          <ChangePassword />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default UserSettings;
