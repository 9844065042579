/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, notification } from "antd";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import { useEnableStream, useDisableStream } from "../../../../hooks";
import { actions } from "../../../../reducers/stageStore";
import { actions as accountActions } from "../../../../reducers/account";

import { handleError } from "../../../../utils";

const { confirm } = Modal;

const disableStoreStream = (
  executeDisableStream: any,
  toggleDisableStream: any,
  data: any
) => {
  confirm({
    title: "Are you sure? You want to Disable Stream",
    icon: <FontAwesomeIcon icon={faExclamationCircle} className="anticon" />,
    onOk() {
      executeDisableStream({
        data,
      });
    },
    onCancel() {
      toggleDisableStream(null);
    },
  });
};

const enableStoreStream = (
  executeEnableStream: any,
  toggleEnableStream: any,
  data: any
) => {
  confirm({
    title: "Are you sure? You want to Enable Stream",
    icon: <FontAwesomeIcon icon={faExclamationCircle} className="anticon" />,
    onOk() {
      executeEnableStream({
        data,
      });
    },
    onCancel() {
      toggleEnableStream(null);
    },
  });
};

const Stream = (props: any) => {
  const { workspace, stage }: any = useParams();
  const dispatch = useDispatch();
  const { isEnableStream, isDisableStream, selectedStore } = useSelector(
    (state: any) => state.stageStore
  );
  const toggleEnableStream = (objectStore: any) =>
    dispatch(actions.toggleEnableStream(objectStore));
  const toggleDisableStream = (objectStore: any) =>
    dispatch(actions.toggleDisableStream(objectStore));

  const updateStageObjectStore = (objectStore: any) =>
    dispatch(actions.updateStageObjectStore(objectStore));
  const updateObjectStoreDetailsForAccount = (objectStore: any) =>
    dispatch(
      accountActions.updateObjectStoreDetailsForAccount(
        workspace,
        stage,
        objectStore
      )
    );
  const {
    loading: enableStreamLoading,
    data: enableStream,
    error: enableStreamError,
    execute: executeEnableStream,
  } = useEnableStream();

  const {
    loading: disableStreamLoading,
    data: disableStream,
    error: disableStreamError,
    execute: executeDisableStream,
  } = useDisableStream();

  useEffect(() => {
    if (isEnableStream) {
      enableStoreStream(executeEnableStream, toggleEnableStream, {
        workspaceName: workspace,
        stageName: stage,
        storeName: selectedStore.name,
      });
    }
  }, [isEnableStream]);

  useEffect(() => {
    if (isDisableStream) {
      disableStoreStream(executeDisableStream, toggleDisableStream, {
        workspaceName: workspace,
        stageName: stage,
        storeName: selectedStore.name,
      });
    }
  }, [isDisableStream]);

  useEffect(() => {
    if (!disableStreamLoading && !disableStreamError && disableStream) {
      updateStageObjectStore({
        ...selectedStore,
        streamAssociated: false,
      });
      updateObjectStoreDetailsForAccount({
        ...selectedStore,
        streamAssociated: false,
      });
      notification.success({
        message: disableStream?.message ?? "Stream disabled successfully",
        duration: 3,
      });
      toggleDisableStream(null);
    } else if (!disableStreamLoading && disableStreamError) {
      handleError(disableStreamError);
      toggleDisableStream(null);
    }
  }, [disableStreamLoading, disableStreamError, disableStream]);

  useEffect(() => {
    if (!enableStreamLoading && !enableStreamError && enableStream) {
      updateStageObjectStore({
        ...selectedStore,
        streamAssociated: true,
      });
      updateObjectStoreDetailsForAccount({
        ...selectedStore,
        streamAssociated: true,
      });
      notification.success({
        message: enableStream?.message ?? "Stream enabled successfully",
        duration: 3,
      });
      toggleEnableStream(null);
    } else if (!enableStreamLoading && enableStreamError) {
      toggleEnableStream(null);
      handleError(enableStreamError);
    }
  }, [enableStreamLoading, enableStreamError, disableStream]);

  return <></>;
};

export default Stream;
