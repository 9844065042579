/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Card, Button, Space, Input } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faArrowLeft,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";

import { TAB_KEYS } from "../../../../../utils";
import { actions as functionActions } from "../../../../../reducers/services";
import { actions as policyActions } from "../../../../../reducers/policy";

import EndPoints from "./Endpoints";
import Policies from "../policies/Policies";

const HTTPEndpoints = (props: any) => {
  const dispatch = useDispatch();
  const [screen, setScreen] = useState(TAB_KEYS.ENDPOINTS);
  const [createPolicy, setCreatePolicy] = useState(false);
  const functionSearchTerm = useSelector(
    (state: any) => state.services.functionSearchTerm
  );
  const policySearchTerm = useSelector(
    (state: any) => state.policy.policySearchTerm
  );

  const setFunctionSearchTerm = (searchTerm: string) =>
    dispatch(functionActions.setFunctionSearchTerm(searchTerm));
  const setpolicySearchTerm = (searchTerm: string) =>
    dispatch(policyActions.setPolicySearchTerm(searchTerm));

  useEffect(() => {
    return () => {
      setFunctionSearchTerm("");
      setpolicySearchTerm("");
    };
  }, []);

  return (
    <Card
      title={
        screen === TAB_KEYS.ENDPOINTS ? (
          <span>HTTP Endpoints</span>
        ) : (
          <Space>
            <Button
              type="link"
              icon={<FontAwesomeIcon icon={faArrowLeft} />}
              onClick={(e) => setScreen(TAB_KEYS.ENDPOINTS)}
              title="Go to Policies"
            ></Button>
            <span>Policies</span>
          </Space>
        )
      }
      bordered={false}
      extra={
        screen === TAB_KEYS.ENDPOINTS ? (
          <Space>
            <Input
              value={functionSearchTerm}
              onChange={(e) => setFunctionSearchTerm(e.target.value)}
              placeholder="Search by function"
              suffix={<FontAwesomeIcon icon={faSearch} />}
            />
            <Button
              type="primary"
              className="ant-btn-fw"
              onClick={(e) => setScreen(TAB_KEYS.POLICIES)}
            >
              Policies
            </Button>
          </Space>
        ) : (
          <Space>
            <Input
              value={policySearchTerm}
              onChange={(e) => setpolicySearchTerm(e.target.value)}
              placeholder="Search by name"
              suffix={<FontAwesomeIcon icon={faSearch} />}
            />
            <Button
              type="primary"
              className="ant-btn-fw"
              onClick={(e) => setCreatePolicy(true)}
            >
              <FontAwesomeIcon icon={faPlus} /> &nbsp; Create Policy
            </Button>
          </Space>
        )
      }
    >
      {screen === TAB_KEYS.ENDPOINTS ? <EndPoints /> : null}
      {screen === TAB_KEYS.POLICIES ? (
        <Policies
          createPolicy={createPolicy}
          setCreatePolicy={setCreatePolicy}
        />
      ) : null}
    </Card>
  );
};

export default HTTPEndpoints;
