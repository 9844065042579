/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link, useHistory } from "react-router-dom";
import { PageHeader, Breadcrumb, Tabs, Select } from "antd";

import { Scaffold } from "../../common";
import { Stages } from "./components";
import { filterWorkspace, ROUTES } from "../../utils";
import { actions } from "../../reducers/account";

const { TabPane } = Tabs;

const Workspace = (props: any) => {
  const { loading } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { workspace } = useParams<any>();
  const [dropdownValues, setDropdownValues] = useState([]);
  const { selectedWorkspace, workspaces } = useSelector(
    (state: any) => state.account
  );

  const onWorkspaceSelect = (value: string) => {
    history.push(`${ROUTES.WORKSPACES}/${value}`);
  };

  useEffect(() => {
    dispatch(actions.selectWorkspace({ value: workspace, label: workspace }));
  }, [workspace]);

  useEffect(() => {
    const dropdownValues = workspaces.map((workspace: any) => {
      return {
        value: workspace.name,
        label: workspace.name,
      };
    });
    setDropdownValues(dropdownValues);
  }, [workspaces]);

  const _filtered: any = filterWorkspace(workspaces, selectedWorkspace);
  return (
    <Scaffold loading={loading}>
      <PageHeader className="site-page-header" title="" ghost={false}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={ROUTES.WORKSPACES}>Workspaces</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Select
              bordered={false}
              style={{ minWidth: 150, cursor: "pointer" }}
              showSearch
              placeholder="choose workspace"
              options={dropdownValues}
              onSelect={onWorkspaceSelect}
              value={selectedWorkspace?.label}
              notFoundContent="No results found"
            />
          </Breadcrumb.Item>
        </Breadcrumb>
      </PageHeader>
      <section className="page-content">
        <Tabs activeKey={"stages"}>
          <TabPane tab={<span>Stages</span>} key="stages">
            <Stages
              key={_filtered?.id}
              id={_filtered?.id}
              stages={_filtered?.stages}
              name={_filtered?.name}
            />
          </TabPane>
        </Tabs>
      </section>
    </Scaffold>
  );
};

export default Workspace;
