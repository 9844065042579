/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Form, Input, Spin } from "antd";
import { useParams } from "react-router-dom";

import { useEnableStoreTTL, useUpdateStoreTTL } from "../../../../hooks";
import { actions } from "../../../../reducers/stageStore";
import { actions as accountActions } from "../../../../reducers/account";

import { required, handleError } from "../../../../utils";

const UpsertTTL = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { workspace, stage }: any = useParams();
  const [enableCalled, setEnableCalled] = useState(false);
  const [updateCalled, setUpdateCalled] = useState(false);
  const [formValues, setFormValues] = useState<any>({});
  const [hasErrors, setHasErrors] = useState(false);
  const { isUpSertTTL, selectedStore } = useSelector(
    (state: any) => state.stageStore
  );
  const toggleUpsertTTL = () => dispatch(actions.toggleUpsertTTL({}));
  const updateStageObjectStore = (objectStore: any) =>
    dispatch(actions.updateStageObjectStore(objectStore));
  const updateObjectStoreDetailsForAccount = (objectStore: any) =>
    dispatch(
      accountActions.updateObjectStoreDetailsForAccount(
        workspace,
        stage,
        objectStore
      )
    );
  const { loading, error, data, execute: executeEnable } = useEnableStoreTTL();
  const {
    loading: updateLoading,
    error: updateError,
    data: updateData,
    execute: executeUpdate,
  } = useUpdateStoreTTL();

  useEffect(() => {
    if (!loading && !error && enableCalled && data) {
      setEnableCalled(false);
      setHasErrors(false);
      updateStageObjectStore({
        ...selectedStore,
        TTLDurationInSec: formValues.TTLValue,
      });
      updateObjectStoreDetailsForAccount({
        ...selectedStore,
        TTLDurationInSec: formValues.TTLValue,
      });
      toggleUpsertTTL();
    } else if (!loading && error && enableCalled) {
      setHasErrors(true);
      handleError(error);
    }
  }, [loading, error, enableCalled, data]);

  useEffect(() => {
    if (!updateLoading && !updateError && updateCalled && updateData) {
      setUpdateCalled(false);
      setHasErrors(false);
      updateStageObjectStore({
        ...selectedStore,
        TTLDurationInSec: formValues.TTLValue,
      });
      toggleUpsertTTL();
    } else if (!updateLoading && updateError && updateCalled) {
      setHasErrors(true);
      handleError(updateError);
    }
  }, [updateLoading, updateError, updateData, updateCalled]);

  const upsertTTL = (form: any) => {
    form
      .validateFields()
      .then((values: any) => {
        form.resetFields();
        setFormValues(values);
        const data = {
          workspaceName: workspace,
          stageName: stage,
          storeName: selectedStore.name,
          ...values,
        };
        if (selectedStore?.TTLDurationInSec) {
          executeUpdate({
            data,
          });
          setUpdateCalled(true);
        } else {
          executeEnable({
            data,
          });
          setEnableCalled(true);
        }
      })
      .catch((info: any) => {
        console.log("Validate Failed:", info);
      });
  };
  const _cancelUpsert = (form: any) => {
    form.resetFields();
    setEnableCalled(false);
    setUpdateCalled(false);
    setHasErrors(false);
    toggleUpsertTTL();
  };

  useEffect(() => {
    if (selectedStore) {
      form.setFieldsValue({
        TTLValue: selectedStore.TTLDurationInSec,
      });
    }
  }, [form, selectedStore]);

  return (
    <>
      <Modal
        title={selectedStore?.TTLDurationInSec ? "Update TTL" : "Enable TTL"}
        visible={isUpSertTTL}
        centered
        destroyOnClose
        onOk={() => upsertTTL(form)}
        onCancel={() => _cancelUpsert(form)}
        okButtonProps={{
          disabled: hasErrors,
        }}
        okText="Confirm"
        cancelText="Cancel"
      >
        <Spin spinning={loading || updateLoading}>
          <Form
            layout="vertical"
            form={form}
            name="variableForm"
            initialValues={selectedStore ?? {}}
            preserve={false}
          >
            <Form.Item
              label="TTL Duration (seconds)"
              name="TTLValue"
              rules={[required("TTL Duration")]}
            >
              <Input type="number" />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default UpsertTTL;
