/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Alert, Typography } from "antd";

import { buildSummary } from "../helpers";
import { SECURITY_TYPE } from "../../../../../../utils";

const ThroughPut = (props: any) => {
  const { throughput } = props;
  return (
    <>
      <strong>Throughput:&nbsp;</strong>
      {throughput}
    </>
  );
};

const Quota = (props: any) => {
  const { quota } = props;
  return (
    <>
      <strong>Quota:&nbsp;</strong>
      {`${quota?.requests} req per ${quota?.period}`}
    </>
  );
};

const IPWhiteList = (ipWhiteList: any) => {
  return (
    <>
      <strong>IP Whitelist:&nbsp;</strong>
      {ipWhiteList?.list?.length > 0 ? ipWhiteList.list.join(";") : null}
      {ipWhiteList?.cidr ? ipWhiteList.cidr : null}
      {ipWhiteList?.range?.from
        ? `From ${ipWhiteList.range?.from} To ${ipWhiteList.range?.to}`
        : null}
    </>
  );
};

const APIKey = (props: any) => {
  const { apiKey } = props;
  return (
    <>
      <h3>
        <strong>API Key Details</strong>
      </h3>
      <Typography.Paragraph copyable>{apiKey}</Typography.Paragraph>
    </>
  );
};
const Summary = (props: any) => {
  const {
    form,
    security,
    signatureType,
    users,
    claims,
    setRequestData,
    apiKey,
  } = props;
  const summary = buildSummary({
    form,
    security,
    signatureType,
    users,
    claims,
  });
  const { isValidData, validationMessages = [], finalRequest } = summary;
  useEffect(() => {
    if (isValidData) {
      setRequestData(summary);
    }
  }, [isValidData]);
  return (
    <>
      <h2>Please review your policy</h2>
      {isValidData
        ? null
        : validationMessages.map((message: string, index: number) => {
            return <Alert message={message} type="warning" key={index} />;
          })}
      <h3>
        <strong>Name:</strong> &nbsp; {finalRequest.policyName}
      </h3>
      <h3>
        <strong>Security</strong>
      </h3>
      <ul>
        <li>
          <strong>Security Access:</strong>&nbsp; {security}
        </li>
      </ul>
      <h3>
        <strong>Traffic Control</strong>
      </h3>
      <ul>
        {!!!finalRequest.throughput &&
        !!!finalRequest?.quota?.requests &&
        !!!finalRequest.ipWhiteList
          ? "None"
          : null}
        {finalRequest.throughput ? (
          <li>
            <ThroughPut throughput={finalRequest.throughput} />
          </li>
        ) : null}
        {finalRequest?.quota?.requests ? (
          <li>
            <Quota quota={finalRequest.quota} />
          </li>
        ) : null}
        {finalRequest.ipWhiteList ? (
          <li>
            <IPWhiteList {...finalRequest.ipWhiteList} />
          </li>
        ) : null}
      </ul>
      {security === SECURITY_TYPE.API_KEY && apiKey ? (
        <APIKey apiKey={apiKey} />
      ) : null}
    </>
  );
};
export default Summary;
