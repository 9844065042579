/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Tabs, Breadcrumb, PageHeader } from "antd";

import { Scaffold } from "../../common";
import { useQuery } from "../../components";
import { UserSettings, OrgSettings } from "./containers";

import "./index.less";

const { TabPane } = Tabs;
const Settings = (props: any) => {
  const { loading } = props;
  const query = useQuery();
  let screen = query.get("screen");
  const [currentTab, setCurrentTab] = useState(screen ?? "user");
  return (
    <Scaffold loading={loading}>
      <PageHeader title="" className="stage-page-header" ghost={false}>
        <Breadcrumb>
          <Breadcrumb.Item>Settings</Breadcrumb.Item>
        </Breadcrumb>
      </PageHeader>
      <div className="settings page-content">
        <Tabs activeKey={currentTab} onChange={setCurrentTab}>
          <TabPane tab={<span>User</span>} key="user">
            <UserSettings />
          </TabPane>
          <TabPane tab={<span>Organization</span>} key="organization">
            <OrgSettings />
          </TabPane>
        </Tabs>
      </div>
    </Scaffold>
  );
};

export default Settings;
