export const isNumeric = (value: string) => {
  return /^-?\d+$/.test(value);
};

export const onlyAlphaNumberic = () => {
  return {
    pattern: /^[a-z\d\-_\s]+$/i,
    message: "Only Alphanumeric is supported",
  };
};

export const required = (name: string) => {
  return {
    required: true,
    message: `${name} is required`,
  };
};

export const minimum = (name: string, min: number = 3) => {
  return {
    min,
    message: `${name} must be at least ${min} characters`,
  };
};

export const maximum = (name: string, max: number = 30) => {
  return {
    max,
    message: `${name} cannot be longer than ${max} characters`,
  };
};

export const isValidIP = (ip: string) => {
  const ranges = ip && ip.trim().split(".");

  if (ranges && ranges.length === 4) {
    return ranges.every(
      (range) => range && !/\s/g.test(range) && 255 - Number(range) >= 0
    );
  }

  return false;
};

export const isValidIPs = (input: string) =>
  input && input.split(";").every(isValidIP);

export const isValidCIDR = (name: string) => {
  return {
    pattern: /^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2]))?$/i,
    message: `${name} must be a valid IP`,
  };
};
