import useAxios from "axios-hooks";

import { ROOT_URL, SECURITY_TYPE } from "../../utils";
import { useAuthHeaders } from "../auth";

const getURLFromSecurity = (security: string) => {
  switch (security) {
    case SECURITY_TYPE.BASIC:
      return `${ROOT_URL}/gateway-service/basicauth`;
    case SECURITY_TYPE.API_KEY:
      return `${ROOT_URL}/gateway-service/apikey`;
    case SECURITY_TYPE.JWT:
      return `${ROOT_URL}/gateway-service/jwttoken`;
    default:
      return `${ROOT_URL}/gateway-service/none`;
  }
};

const usePolicyGateway = (security: string, id: string) => {
  const headers = useAuthHeaders();
  const [{ data, loading, error }, execute] = useAxios(
    {
      url: getURLFromSecurity(security),
      method: id ? "PUT" : "POST",
      headers: {
        ...headers,
      },
    },
    { manual: true }
  );

  return {
    data,
    loading,
    error,
    execute,
  };
};

export default usePolicyGateway;
