export const ROUTES = {
  WORKSPACES: "/workspaces",
  WORKSPACE: "/workspaces/:workspace",
  STAGE: "/workspaces/:workspace/:stage",
  FORGOT_PASSWORD: "/forgotPassword",
  COMPLETE_SIGN_UP: "/completeSignup",
  SETTINGS: "/settings",
  SETTINGS_BILLING: "/settings?screen=organization&tab=billing",
  SUBSCRIPTION_SUCCESS: "/subscriptionSuccess",
  TXN_SUCCESS: "/transactionSuccess",
  TXN_FAILURE: "/transactionFailed",
};
