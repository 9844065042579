import React from "react";
import { Badge, Form, Input, Radio, Space } from "antd";

import { SECURITY_TYPE, SIGNATURE_TYPE } from "../../../../../../utils";
import Users from "./Users";
import Claims from "./Claims";

const { TextArea } = Input;

const Security = (props: any) => {
  const {
    id,
    form,
    setSecurityValue,
    security,
    signatureType,
    setSignatureType,
    users,
    setUsers,
    claims,
    setClaims,
  } = props;

  const onSecurityChange = (value: string) => {
    switch (value) {
      case SECURITY_TYPE.BASIC:
        setClaims([]);
        break;
      case SECURITY_TYPE.JWT:
        setUsers([]);
        break;
      default:
        setClaims([]);
        setUsers([]);
    }
    setSecurityValue(value);
  };

  const onSignatureChange = (value: string) => {
    switch (value) {
      case SIGNATURE_TYPE.SECRET:
        form.setFieldsValue({
          key: null,
          jwks: {
            url: null,
          },
        });
        break;
      case SIGNATURE_TYPE.KEY:
        form.setFieldsValue({
          secret: null,
          jwks: {
            url: null,
          },
        });
        break;
      case SIGNATURE_TYPE.JWKS:
        form.setFieldsValue({
          key: null,
          secret: null,
        });
        break;
      default:
        break;
    }
    setSignatureType(value);
  };

  return (
    <>
      <h2>Select your security access:</h2>
      <Radio.Group
        onChange={(e) => onSecurityChange(e.target.value)}
        value={security}
        disabled={!!id}
      >
        <Radio value={SECURITY_TYPE.NONE}>None</Radio>
        <Radio value={SECURITY_TYPE.API_KEY}>API Key</Radio>
        <Radio value={SECURITY_TYPE.BASIC}>Basic Authentication</Radio>
        <Radio value={SECURITY_TYPE.JWT}>JSON Web Token</Radio>
      </Radio.Group>
      {security === SECURITY_TYPE.API_KEY && (
        <div>
          <h3>Authentication Header</h3>
          <p>
            The client sends HTTP requests with the{" "}
            <Badge style={{ backgroundColor: "gray" }} count={"x-api-key"} />{" "}
            header that contains the api key generated by this policy.
            <br />
            This key will be displayed on the summary page.
          </p>
        </div>
      )}
      {security === SECURITY_TYPE.BASIC && (
        <div>
          <h3>Authentication Header</h3>
          <p>
            The client sends HTTP requests with the{" "}
            <Badge
              style={{ backgroundColor: "gray" }}
              count={"Authorization"}
            />{" "}
            header that contains the word <strong>Basic &nbsp;</strong>
            followed by a space and a base64-encoded string username:password
          </p>
          <Users users={users} setUsers={setUsers} />
        </div>
      )}
      {security === SECURITY_TYPE.JWT && (
        <div>
          <h3>Authentication Header</h3>
          <p>
            The client sends HTTP requests with the{" "}
            <Badge
              style={{ backgroundColor: "gray" }}
              count={"Authorization"}
            />{" "}
            header that contains the word <strong>Bearer &nbsp;</strong>
            followed by a valid JWT token
          </p>

          <h3>Signature Verification</h3>
          <Radio.Group
            value={signatureType}
            onChange={(e) => onSignatureChange(e.target.value)}
            className="w-100"
          >
            <Space direction="vertical" className="w-100">
              <Space align="baseline" className="radio-inputs">
                <Radio value={SIGNATURE_TYPE.SECRET}>Secret</Radio>
                <Form.Item
                  name={"secret"}
                  rules={[
                    {
                      required: signatureType === SIGNATURE_TYPE.SECRET,
                      message: "Secret value is required",
                    },
                  ]}
                >
                  <Input
                    placeholder=""
                    disabled={signatureType !== SIGNATURE_TYPE.SECRET}
                  />
                </Form.Item>
              </Space>
            </Space>
            <Space direction="vertical" className="w-100">
              <Space align="baseline" className="radio-inputs">
                <Radio value={SIGNATURE_TYPE.KEY}>Key</Radio>
                <Form.Item
                  name={"key"}
                  rules={[
                    {
                      required: signatureType === SIGNATURE_TYPE.KEY,
                      message: "Key value is required",
                    },
                  ]}
                >
                  <TextArea
                    placeholder={`---BEGIN PUBLIC KEY---
--- END PUBLIC KEY --- `}
                    disabled={signatureType !== SIGNATURE_TYPE.KEY}
                  />
                </Form.Item>
              </Space>
            </Space>
            <Space direction="vertical" className="w-100">
              <Space align="baseline" className="radio-inputs">
                <Radio value={SIGNATURE_TYPE.JWKS}>JWKS</Radio>
                <Form.Item
                  name={["jwks", "url"]}
                  rules={[
                    {
                      required: signatureType === SIGNATURE_TYPE.JWKS,
                      message: "JWKS url value is required",
                    },
                    {
                      type: "url",
                      message: "JWKS must be a valid url",
                    },
                  ]}
                >
                  <Input
                    disabled={signatureType !== SIGNATURE_TYPE.JWKS}
                    placeholder="URL where to retrive the JWKS and filter for potential signature verfication keys"
                  />
                </Form.Item>
              </Space>
            </Space>
          </Radio.Group>
          <Claims claims={claims} setClaims={setClaims} />
        </div>
      )}
    </>
  );
};
export default Security;
