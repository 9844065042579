/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import {
  Modal,
  List,
  Button,
  Space,
  notification,
  Input,
  Spin,
  Empty,
} from "antd";
import _includes from "lodash/includes";
import _lowercase from "lodash/lowerCase";

import { useAssignPolicy } from "../../../../../hooks";
import { handleError } from "../../../../../utils";
import { actions } from "../../../../../reducers/services";

const AssignPolicy = (props: any) => {
  const { isAssignPolicy, functionData, setShowAssign } = props;
  const { stagePolicies = [] } = useSelector((state: any) => state.services);
  const dispatch = useDispatch();
  const { workspace, stage }: any = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedPolicy, setSelectedPolicy] = useState("");
  const updateService = (service: any) =>
    dispatch(actions.updateStageService(service));

  const { loading, data, error, execute } = useAssignPolicy();

  const applyPolicy = (item: any) => {
    setSelectedPolicy(item.name);
    execute({
      data: {
        workspace,
        stage,
        serviceName: functionData.name,
        policyName: item.name,
      },
    });
  };

  useEffect(() => {
    if (!loading && !error && data) {
      setShowAssign(false);
      updateService({
        ...functionData,
        policy: selectedPolicy,
      });
      notification.success({
        message: "Policy assigned successfully",
      });
      setSelectedPolicy("");
    } else if (!loading && error) {
      handleError(error);
    }
  }, [loading, error, data]);

  const _stagePolicies = stagePolicies.filter((policy: any) => {
    return _includes(_lowercase(policy?.name), _lowercase(searchTerm));
  });
  return (
    <Modal
      title="Assign Policy"
      width="80%"
      visible={isAssignPolicy}
      centered
      destroyOnClose
      footer={null}
      onCancel={() => setShowAssign(false)}
    >
      <Spin spinning={loading}>
        <Input
          value={searchTerm}
          placeholder="Search Policies"
          onChange={(e: any) => setSearchTerm(e.target.value)}
        />
        <List
          style={{
            height: "400px",
            overflowY: "auto",
          }}
          size="small"
          itemLayout="horizontal"
          dataSource={_stagePolicies}
          renderItem={(item: any) => (
            <List.Item>
              <Space className="justify-content-between w-100" align="center">
                <p>{item.name}</p>
                <Button
                  type="primary"
                  ghost
                  onClick={() => applyPolicy(item)}
                  disabled={item.name === functionData?.policy}
                >
                  Apply
                </Button>
              </Space>
            </List.Item>
          )}
          locale={{ emptyText: <Empty description="No policies created" /> }}
        />
      </Spin>
    </Modal>
  );
};

export default AssignPolicy;
