/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Form,
  Input,
  Modal,
  Select,
  Spin,
  Typography,
} from "antd";
import { useSelector, useDispatch } from "react-redux";

import { useDeleteWorkspace } from "../../../../../hooks";
import { actions as accountActions } from "../../../../../reducers/account";
import { buildWorkspaceAndStageMap, handleError } from "../../../../../utils";

const { Option } = Select;

const DeleteWorkspace = (props: any) => {
  const dispatch = useDispatch();
  const { workspaces = [] } = useSelector((state: any) => state.account);
  const [isOpen, setIsOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [called, setCalled] = useState(false);
  const [workspaceChoosed, setWorkspaceChoosed] = useState<string | null>(null);
  const [workspaceTyped, setWorkspaceTyped] = useState("");
  const { workspaceOptions } = buildWorkspaceAndStageMap(workspaces);
  const deleteWorkspaceFromAccount = () =>
    dispatch(accountActions.deleteWorkspaceFromAccount(workspaceTyped));
  const { loading, error, data, execute } = useDeleteWorkspace({
    workspaceTyped,
  });
  const _clearValues = () => {
    setWorkspaceTyped("");
    setCalled(false);
    setHasError(false);
    setIsOpen(false);
  };

  const executeDeleteWorkspace = () => {
    execute();
    setCalled(true);
  };

  const handleWorkspaceChange = (value: string) => {
    setWorkspaceChoosed(value);
  };

  useEffect(() => {
    if (!loading && !error && called) {
      setCalled(false);
      deleteWorkspaceFromAccount();
      setIsOpen(false);
    } else if (!loading && error && called) {
      handleError(error);
      setIsOpen(false);
    }
  }, [loading, error, data, called]);
  return (
    <>
      <Button danger onClick={() => setIsOpen(true)}>
        Delete your workspace
      </Button>
      <Typography.Paragraph>
        Once you delete your workspace, there is no going back. Please be
        certain
      </Typography.Paragraph>
      <Modal
        title="Enter the workspace name you want to delete"
        visible={isOpen}
        centered
        destroyOnClose
        onOk={() => executeDeleteWorkspace()}
        onCancel={() => _clearValues()}
        okText="OK"
        cancelText="Cancel"
        okButtonProps={{ disabled: !workspaceTyped || hasError }}
      >
        <>
          <Typography.Paragraph className="t-center">
            The action CANNOT be undone. This will delete the{" "}
            <Badge count={workspaceTyped} /> workspace permanently.
          </Typography.Paragraph>
          <Spin spinning={loading}>
            <Form>
              <Form.Item
                label="Workspace"
                name="workspaceChoosed"
                rules={[{ required: true }]}
              >
                <Select
                  onChange={handleWorkspaceChange}
                  placeholder="Select Workspace"
                  value={workspaceChoosed}
                >
                  {workspaceOptions.map((workspaceName) => (
                    <Option key={workspaceName} value={workspaceName}>
                      {workspaceName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Confirm Workspace"
                name="workspaceTyped"
                rules={[
                  { required: true },
                  () => ({
                    validator(_, value) {
                      if (!value || workspaceChoosed === workspaceTyped) {
                        setHasError(false);
                        return Promise.resolve();
                      } else {
                        setHasError(true);
                        return Promise.reject(
                          new Error("Type the selected workspace to confirm")
                        );
                      }
                    },
                  }),
                ]}
                normalize={(value: string) => value.trim()}
              >
                <Input
                  value={workspaceTyped}
                  onChange={(e) => setWorkspaceTyped(e.target.value)}
                />
              </Form.Item>
            </Form>
          </Spin>
        </>
      </Modal>
    </>
  );
};
export default DeleteWorkspace;
