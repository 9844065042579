/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Dropdown, Menu, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEllipsisV,
  faPlay,
  faStop,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import useOutsideClick from "../../../../hooks/helpers";

import { actions } from "../../../../reducers/stageStore";

const StoreOptions = (props: any) => {
  const { TTLDurationInSec, streamAssociated } = props;
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const [isOpen, toggleMenu] = useState(false);

  const toggleUpsertTTL = (objectStore: any) =>
    dispatch(actions.toggleUpsertTTL(objectStore));
  const toggleDisableTTL = (objectStore: any) =>
    dispatch(actions.toggleDisableTTL(objectStore));
  const toggleEnableStream = (objectStore: any) =>
    dispatch(actions.toggleEnableStream(objectStore));
  const toggleDisableStream = (objectStore: any) =>
    dispatch(actions.toggleDisableStream(objectStore));
  const toggleDeleteStore = (objectStore: any) =>
    dispatch(actions.toggleDeleteStore(objectStore));

  useOutsideClick(wrapperRef, () => toggleMenu(false));
  return (
    <div ref={wrapperRef}>
      <Dropdown
        overlay={
          <Menu className="cell-options">
            {!TTLDurationInSec && (
              <>
                <Menu.Item>
                  <Button
                    type="text"
                    className="t-left"
                    block
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleMenu(false);
                      toggleUpsertTTL(props);
                    }}
                  >
                    <FontAwesomeIcon icon={faPlay} className="fa-fw" />
                    &nbsp; Enable TTL
                  </Button>
                </Menu.Item>
              </>
            )}
            {TTLDurationInSec && (
              <>
                <Menu.Item>
                  <Button
                    type="text"
                    className="t-left"
                    block
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleMenu(false);
                      toggleUpsertTTL(props);
                    }}
                  >
                    <FontAwesomeIcon icon={faEdit} className="fa-fw" />
                    &nbsp; Update TTL
                  </Button>
                </Menu.Item>
                <Menu.Item>
                  <Button
                    type="text"
                    className="t-left"
                    block
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleMenu(false);
                      toggleDisableTTL(props);
                    }}
                  >
                    <FontAwesomeIcon icon={faStop} className="fa-fw" />
                    &nbsp; Disable TTL
                  </Button>
                </Menu.Item>
              </>
            )}
            {!streamAssociated && (
              <>
                <Menu.Item>
                  <Button
                    type="text"
                    className="t-left"
                    block
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleMenu(false);
                      toggleEnableStream(props);
                    }}
                  >
                    <FontAwesomeIcon icon={faPlay} className="fa-fw" />
                    &nbsp; Enable Stream
                  </Button>
                </Menu.Item>
              </>
            )}
            {streamAssociated && (
              <>
                <Menu.Item>
                  <Button
                    type="text"
                    className="t-left"
                    block
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleMenu(false);
                      toggleDisableStream(props);
                    }}
                  >
                    <FontAwesomeIcon icon={faStop} className="fa-fw" />
                    &nbsp; Disable Stream
                  </Button>
                </Menu.Item>
              </>
            )}
            <>
              <Menu.Item>
                <Button
                  type="text"
                  className="t-left"
                  block
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleMenu(false);
                    toggleDeleteStore(props);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} className="fa-fw" />
                  &nbsp; Delete Store
                </Button>
              </Menu.Item>
            </>
          </Menu>
        }
        placement="bottomRight"
        arrow
        onVisibleChange={() => toggleMenu(true)}
        trigger={["click"]}
        visible={isOpen}
      >
        <FontAwesomeIcon icon={faEllipsisV} />
      </Dropdown>
    </div>
  );
};

export default StoreOptions;
