import React, { useState, useEffect } from "react";
import { Card, Empty, Spin, Table } from "antd";
import moment from "moment";

import { usePaymentAudit } from "../../../../../hooks";
import { handleError, GLOBAL_DATE_FORMAT } from "../../../../../utils";

const STATEMENT_TABLE_COLUMNS = [
  {
    title: "Date",
    dataIndex: "CreationTimestamp",
    key: "creationTimestamp",
    render: (text: any) => moment(text).format(GLOBAL_DATE_FORMAT),
  },
  {
    title: "Credit",
    dataIndex: ["auditEntry", "Credit"],
    key: "credit",
  },
  {
    title: "Debit",
    dataIndex: ["auditEntry", "Debit"],
    key: "debit",
  },
  {
    title: "Balance",
    dataIndex: ["auditEntry", "Balance"],
    key: "balance",
  },
];

const PaymentStatment = (props: any) => {
  const { loading, data, error } = usePaymentAudit();
  const [statements, setStatements] = useState([]);
  useEffect(() => {
    if (!loading && !error && data) {
      const { paymentAudit } = data ?? {};
      setStatements(paymentAudit?.Items);
    } else if (!loading && error) {
      handleError(error);
    }
  }, [loading, data, error]);
  return (
    <Card title="Payment History" bordered={false}>
      <Spin spinning={loading}>
        <Table
          size="small"
          key="creationTimestamp"
          columns={STATEMENT_TABLE_COLUMNS}
          dataSource={statements}
          locale={{
            emptyText: (
              <Empty description="No payment statements available yet" />
            ),
          }}
        />
      </Spin>
    </Card>
  );
};
export default PaymentStatment;
