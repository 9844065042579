/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Modal, Spin, Table } from "antd";

import { useServiceVersions, useStartService } from "../../../../hooks";
import { getVersionColumns } from "../../columns";
import { actions } from "../../../../reducers/services";
import { handleError, SERVICE_STARTING } from "../../../../utils";

const StartService = () => {
  const dispatch = useDispatch();
  const { workspace, stage } = useParams<any>();
  const [selectedVersion, setSelectedVersion] = useState("");
  const [called, setCalled] = useState(false);
  const { startService, serviceName } = useSelector(
    (state: any) => state.services
  );
  const { loading, data, execute } = useServiceVersions({ serviceName });
  const toggleStartService = (serviceName: string) =>
    dispatch(actions.toggleStartService(serviceName));
  const updateStageService = (service: any) =>
    dispatch(actions.updateStageServiceStatus(service));
  const {
    loading: startServiceLoading,
    error: startServiceError,
    data: serviceDetails,
    execute: executeStart,
  } = useStartService();
  useEffect(() => {
    if (
      !startServiceLoading &&
      !startServiceError &&
      called &&
      serviceDetails
    ) {
      setCalled(false);
      updateStageService({
        id: serviceDetails?.id,
        name: serviceName,
        status: SERVICE_STARTING,
      });
      toggleStartService("");
    } else if (!startServiceLoading && startServiceError && called) {
      handleError(startServiceError);
      setCalled(false);
      toggleStartService("");
    }
  }, [startServiceError, startServiceLoading, called, serviceDetails]);
  const _executeStartService = () => {
    executeStart({
      data: {
        account: workspace,
        stage,
        serviceName,
        serviceVersion: selectedVersion,
      },
    });
    setCalled(true);
  };
  useEffect(() => {
    if (serviceName) {
      execute();
    }
  }, [serviceName]);

  return (
    <Modal
      title="Version to Deploy"
      width={750}
      visible={startService}
      centered
      onOk={() => _executeStartService()}
      onCancel={() => toggleStartService("")}
      okText="Confirm"
      cancelText="Cancel"
    >
      <Spin spinning={loading || startServiceLoading}>
        <Table
          size="small"
          dataSource={data}
          rowKey="version"
          columns={getVersionColumns()}
          rowSelection={{
            type: "radio",
            onChange: (selectedKeys, selectedRow) => {
              setSelectedVersion(selectedRow[0]?.version);
            },
          }}
        ></Table>
      </Spin>
    </Modal>
  );
};

export default StartService;
