/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Form, Input, Spin } from "antd";
import { useParams } from "react-router-dom";
import moment from "moment";

import { useCreateStore } from "../../../../hooks";
import { actions } from "../../../../reducers/stageStore";
import { actions as accountActions } from "../../../../reducers/account";

import { required, minimum, maximum, handleError } from "../../../../utils";

const UpsertStore = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { workspace, stage }: any = useParams();
  const [createCalled, setCreateCalled] = useState(false);
  const [formValues, setFormValues] = useState<any>({});
  const [hasErrors, setHasErrors] = useState(false);
  const { isUpSertStore, selectedStore } = useSelector(
    (state: any) => state.stageStore
  );
  const toggleUpsertStore = () => dispatch(actions.toggleUpsertStore({}));
  const updateStageObjectStore = (objectStore: any) =>
    dispatch(actions.updateStageObjectStore(objectStore));
  const updateObjectStoreDetailsForAccount = (objectStore: any) =>
    dispatch(
      accountActions.updateObjectStoreDetailsForAccount(
        workspace,
        stage,
        objectStore
      )
    );
  const { loading, error, data, execute: executeCreate } = useCreateStore();

  useEffect(() => {
    if (!loading && !error && createCalled && data) {
      setCreateCalled(false);
      setHasErrors(false);
      const { storeName } = formValues;
      const _objectStore = {
        name: storeName,
        streamAssociated: false,
        createdTS: moment().valueOf(),
      };
      updateStageObjectStore(_objectStore);
      updateObjectStoreDetailsForAccount(_objectStore);
      toggleUpsertStore();
    } else if (!loading && error && createCalled) {
      setHasErrors(true);
      handleError(error);
    }
  }, [loading, error, createCalled, data]);

  const createStore = (form: any) => {
    form
      .validateFields()
      .then((values: any) => {
        form.resetFields();
        setFormValues(values);
        executeCreate({
          data: {
            workspaceName: workspace,
            stageName: stage,
            ...values,
          },
        });
        setCreateCalled(true);
      })
      .catch((info: any) => {
        console.log("Validate Failed:", info);
      });
  };
  const _cancelUpsert = (form: any) => {
    form.resetFields();
    setCreateCalled(false);
    setHasErrors(false);
    toggleUpsertStore();
  };

  useEffect(() => {
    if (selectedStore) {
      form.setFieldsValue(selectedStore);
    }
  }, [form, selectedStore]);

  return (
    <>
      <Modal
        title={selectedStore?.name ? "Edit Store" : "Create Store"}
        visible={isUpSertStore}
        centered
        destroyOnClose
        onOk={() => createStore(form)}
        onCancel={() => _cancelUpsert(form)}
        okButtonProps={{
          disabled: hasErrors,
        }}
        okText="Confirm"
        cancelText="Cancel"
      >
        <Spin spinning={loading}>
          <Form
            layout="vertical"
            form={form}
            name="variableForm"
            initialValues={selectedStore ?? {}}
            preserve={false}
          >
            <Form.Item
              label="Store Name"
              name="storeName"
              rules={[required("Name"), minimum("Name"), maximum("Name")]}
              normalize={(value: string) => value.trim()}
            >
              <Input readOnly={!!selectedStore?.name} />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default UpsertStore;
