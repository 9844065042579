import useAxios from "axios-hooks";

import { ROOT_URL } from "../../utils";
import { useAuthHeaders } from "../auth";

const useEnableStream = () => {
  const headers = useAuthHeaders();
  const [{ data, loading, error }, execute] = useAxios(
    {
      url: `${ROOT_URL}/stores/stream/enable`,
      method: "POST",
      headers: {
        ...headers,
      },
    },
    { manual: true }
  );

  return {
    data,
    loading,
    error,
    execute,
  };
};

export default useEnableStream;
