import useAxios from "axios-hooks";
import { ROOT_URL } from "../../utils";
import { useAuthHeaders } from "../auth";

const useCreateStage = (props: any) => {
  const { workspace, stage, description } = props;
  const headers = useAuthHeaders();

  const [{ data, loading, error }, execute] = useAxios(
    {
      url: `${ROOT_URL}/stages-service/${workspace}/${stage}`,
      method: "PUT",
      headers,
      data: {
        description,
      },
    },
    { manual: true }
  );

  return {
    data,
    loading,
    error,
    execute,
  };
};

export default useCreateStage;
