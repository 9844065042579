import useAxios from "axios-hooks";
import { ROOT_URL } from "../../utils";
import { useAuthHeaders } from "../auth";

const useCreateWorkspace = () => {
  const headers = useAuthHeaders();
  const [{ data, loading, error }, execute] = useAxios(
    {
      url: `${ROOT_URL}/workspace-service/workspace`,
      method: "POST",
      headers,
    },
    { manual: true }
  );

  return {
    data,
    loading,
    error,
    execute,
  };
};

export default useCreateWorkspace;
