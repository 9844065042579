import useAxios from "axios-hooks";

import { ROOT_URL } from "../../utils";
import { useAuthHeaders } from "../auth";

const useAccount = () => {
  const headers = useAuthHeaders();

  const [{ data, loading, error }, refetch] = useAxios(
    {
      url: `${ROOT_URL}/cp/tenantaccount`,
      method: "GET",
      headers,
    },
    {
      manual: true,
    }
  );

  return {
    data,
    loading,
    error,
    refetch,
  };
};

export default useAccount;
