import React from "react";
import { Form, Input } from "antd";

import {
  required,
  minimum,
  maximum,
  onlyAlphaNumberic,
} from "../../../../../../utils";

const Naming = (props: any) => {
  const { id } = props;
  return (
    <>
      <h2>How do you want to name your policy?</h2>
      <h4>
        Give to your policy a descriptive name so you can easily identify it in
        the future
      </h4>
      <Form.Item
        label="Policy Name"
        name="name"
        rules={[
          required("Policy Name"),
          minimum("Policy Name"),
          maximum("Policy Name"),
          onlyAlphaNumberic(),
        ]}
        normalize={(value: string) => value.trim()}
      >
        <Input disabled={!!id} />
      </Form.Item>
    </>
  );
};
export default Naming;
