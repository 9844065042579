import React, { Component } from "react";
import { Result, Space } from "antd";

type ErrorBoundaryProps = {
  [key: string]: any;
};
type ErrorBoundaryState = { hasError: boolean };

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    this.setState({ hasError: true });
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <Result
            status="500"
            title="500"
            subTitle="Sorry, something went wrong."
            extra="Please try again after some time"
          />
          <Space className="d-flex w-100 t-center">
            <a href="/workspaces" className="ant-btn ant-btn-primary">
              Go to Home
            </a>
          </Space>
        </>
      );
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
