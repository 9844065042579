import { combineReducers, createStore } from "redux";
import { devToolsEnhancer } from "redux-devtools-extension";

import layout from "./layout";
import auth from "./auth";
import account from "./account";
import services from "./services";
import stageStore from "./stageStore";
import schedulers from "./schedulers";
import policy from "./policy";

export const reducers = combineReducers({
  layout,
  auth,
  services,
  stageStore,
  account,
  schedulers,
  policy,
});

export const store = createStore(reducers, devToolsEnhancer({}));
