/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Space, Input, Button, Empty } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faPlus } from "@fortawesome/free-solid-svg-icons";

import { filterObjectStores } from "../../helpers";
import { getObjectStoreColumms } from "../../columns";
import { actions } from "../../../../reducers/stageStore";
import UpsertStore from "./UpsertStore";
import UpsertTTL from "./UpsertTTL";
import StoreDetails from "./StoreDetails";
import DisableTTL from "./DisableTTL";
import Stream from "./Stream";
import DeleteStore from "./DeleteStore";

const Store = (props: any) => {
  const dispatch = useDispatch();
  const { objectStores } = useSelector((state: any) => state.stageStore);
  const [storeSearchTerm, setStoreSearchTerm] = useState("");
  const toggleUpsertStore = () => dispatch(actions.toggleUpsertStore({}));
  const toggleShowStoreDetails = (selectedStore: any) =>
    dispatch(actions.toggleShowStoreDetails(selectedStore));
  useEffect(() => {
    return () => {
      setStoreSearchTerm("");
    };
  }, []);
  const _filteredObjectStores = filterObjectStores(
    objectStores,
    storeSearchTerm
  );
  return (
    <div>
      <Space className="justify-content-between w-100 mb-1">
        <Input
          value={storeSearchTerm}
          onChange={(e) => setStoreSearchTerm(e.target.value)}
          placeholder="Search store"
          suffix={<FontAwesomeIcon icon={faSearch} />}
        />
        <Button
          type="primary"
          className="mb-0"
          onClick={() => toggleUpsertStore()}
        >
          <FontAwesomeIcon icon={faPlus} /> &nbsp; Create Store
        </Button>
      </Space>
      <section className="scroll-y" style={{ height: "65vh" }}>
        <Table
          size="small"
          dataSource={_filteredObjectStores}
          columns={getObjectStoreColumms({ toggleShowStoreDetails })}
          rowKey="createdTS"
          locale={{
            emptyText: <Empty description="No store(s) created yet" />,
          }}
        />
      </section>
      <UpsertStore />
      <UpsertTTL />
      <DisableTTL />
      <Stream />
      <DeleteStore />
      <StoreDetails />
    </div>
  );
};

export default Store;
