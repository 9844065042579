import useAxios from "axios-hooks";

import { ROOT_URL } from "../../utils";
import { useAuthHeaders } from "../auth";

const useInitiatePayment = () => {
  const headers = useAuthHeaders();

  const [{ data, loading, error }, execute] = useAxios(
    {
      url: `${ROOT_URL}/payments/manualpayments`,
      method: "PUT",
      headers,
    },
    { manual: true }
  );

  return {
    data,
    loading,
    error,
    execute,
  };
};

export default useInitiatePayment;
