/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Result, notification, Spin } from "antd";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

import { useSubscriptionPostEvent } from "../../../hooks";
import { handleError, ROUTES } from "../../../utils";
import { actions } from "../../../reducers/auth";

const SubscriptionSuccess = () => {
  const { billing } = useSelector((state: any) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const { StripeCustomerId } = billing;
  const { loading, data, error, execute } = useSubscriptionPostEvent();

  const setBillingSubscription = () =>
    dispatch(actions.setBillingSubscription());
  useEffect(() => {
    execute({
      data: {
        customerId: StripeCustomerId,
      },
    });
  }, []);

  useEffect(() => {
    if (!loading && !error && data) {
      notification.success({
        message: data.message ?? "Transaction completed",
        duration: 3,
      });
      setBillingSubscription();
      history.push(ROUTES.WORKSPACES);
    } else if (!loading && error) {
      handleError(error);
    }
  }, [loading, data, error]);

  return (
    <>
      <Spin spinning={loading}>
        <Result
          status="success"
          title="Transaction completed"
          subTitle="Please wait while we activate your account"
        ></Result>
      </Spin>
    </>
  );
};

export default SubscriptionSuccess;
