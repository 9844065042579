import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import KumoLayout from "./layout";
import { store } from "./reducers";
import { ErrorBoundary } from "./components";

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <KumoLayout />
    </Provider>
  </ErrorBoundary>,
  document.getElementById("root")
);
