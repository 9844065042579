/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Result, notification, Spin } from "antd";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { usePaymentPostEvent } from "../../../hooks";
import { handleError, ROUTES } from "../../../utils";
import { actions } from "../../../reducers/auth";
import { useQuery } from "../../../components";

const PaymentSuccess = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query: any = useQuery();
  const sessionId = query.get("session_id");
  const { loading, data, error, execute } = usePaymentPostEvent();
  const setBillingDisabled = () => dispatch(actions.setBillingDisabled());
  useEffect(() => {
    execute({
      data: {
        sessionId,
      },
    });
  }, []);

  useEffect(() => {
    if (!loading && !error && data) {
      notification.success({
        message: data.message ?? "Transaction completed",
        duration: 3,
      });
      setBillingDisabled();
      history.push(ROUTES.WORKSPACES);
    } else if (!loading && error) {
      handleError(error);
    }
  }, [loading, data, error]);

  return (
    <>
      <Spin spinning={loading}>
        <Result
          status="success"
          title="Transaction completed"
          subTitle="Please wait while we activate your account"
        ></Result>
      </Spin>
    </>
  );
};

export default PaymentSuccess;
