import React from "react";
import { Card, Empty } from "antd";
import { Line } from "@ant-design/charts";

import "./Metric.less";
import { buildMetricData } from "../../helpers";

const config = {
  height: 200,
  xField: "x",
  yField: "y",
  point: {
    size: 5,
    shape: "diamond",
  },
};

const Metric = (props: any) => {
  let { metric, loading, empty } = props;
  if (Array.isArray(metric)) {
    metric = metric[0];
  }
  const chartData = buildMetricData(metric, "Timestamps", "Values");
  const _config = {
    ...config,
    data: chartData,
    loading,
  };
  return (
    <Card title={metric?.Label ?? ""} className="metric">
      {empty ? (
        <Empty description="Please choose service name and time period" />
      ) : (
        <Line {..._config} />
      )}
    </Card>
  );
};
export default Metric;
