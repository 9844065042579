import { useSelector } from "react-redux";

const useAuthHeaders = () => {
  const { IdToken } = useSelector((state: any) => state.auth.authData ?? {});

  return {
    "Content-Type": "application/json; charset=utf-8",
    Authorization: `Bearer ${IdToken}`,
  };
};

export default useAuthHeaders;
