import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Dropdown, Menu, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEllipsisV,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import { actions } from "../../../../reducers/stageStore";
import useOutsideClick from "../../../../hooks/helpers";

const StoreOptions = (props: any) => {
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const [isOpen, toggleMenu] = useState(false);

  const toggleDeleteVariable = (variable: any) =>
    dispatch(actions.toggleDeleteVariable(variable));
  const toggleUpsertVariable = (variable: any) =>
    dispatch(actions.toggleUpsertVariable(variable));
  useOutsideClick(wrapperRef, () => toggleMenu(false));
  return (
    <div ref={wrapperRef}>
      <Dropdown
        overlay={
          <Menu className="cell-options">
            <Menu.Item>
              <Button type="text" onClick={() => toggleUpsertVariable(props)}>
                <FontAwesomeIcon icon={faEdit} />
                &nbsp; Edit
              </Button>
            </Menu.Item>
            <Menu.Item>
              <Button type="text" onClick={() => toggleDeleteVariable(props)}>
                <FontAwesomeIcon icon={faTrash} />
                &nbsp; Delete
              </Button>
            </Menu.Item>
          </Menu>
        }
        placement="bottomRight"
        arrow
        onVisibleChange={() => toggleMenu(true)}
        trigger={["click"]}
        visible={isOpen}
      >
        <FontAwesomeIcon icon={faEllipsisV} />
      </Dropdown>
    </div>
  );
};

export default StoreOptions;
