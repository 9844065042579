import React, { useCallback, useEffect } from "react";
import { Button, Result, Spin } from "antd";
import { useSelector } from "react-redux";
import { useStripe } from "@stripe/react-stripe-js";

import { useCreateSubscription } from "../../../../../hooks";
import { getOrigin, handleError, ROUTES } from "../../../../../utils";

const NoSubscription = (props: any) => {
  const { billing } = useSelector((state: any) => state.auth);
  const { StripeCustomerId } = billing;
  const stripe = useStripe();
  const { loading, data, error, execute } = useCreateSubscription();
  const startSubscription = async () => {
    execute({
      data: {
        customerId: StripeCustomerId,
        // TODO: Hardcoding this value till it is added to paymentAccount in login response
        price_id: "price_1JEYcGSCHRAkmQrLUGleZso4",
        success_url: `${getOrigin()}${ROUTES.SUBSCRIPTION_SUCCESS}`,
        failure_url: `${getOrigin()}${ROUTES.TXN_FAILURE}`,
      },
    });
  };

  const redirectToCheckout = useCallback(
    async (sessionId) => {
      try {
        await stripe?.redirectToCheckout({
          sessionId,
        });
      } catch (e) {
        console.error(e);
      }
    },
    [stripe]
  );

  useEffect(() => {
    if (!loading && !error && data) {
      redirectToCheckout(data.sessionID);
    } else if (!loading && error) {
      handleError(error);
    }
  }, [loading, data, error, redirectToCheckout]);
  return (
    <>
      <Spin spinning={loading}>
        <Result
          status="warning"
          title="No Subscription found"
          extra={
            <Button type="primary" key="console" onClick={startSubscription}>
              Add Subscription
            </Button>
          }
        ></Result>
      </Spin>
    </>
  );
};
export default NoSubscription;
