import React, { useEffect, useState } from "react";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Space, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";

import Store from "./StoreList";
import StoreDetails from "./StoreDetails";
import { actions } from "../../../../reducers/stageStore";

const SCREENS = {
  STORE_LIST: "STORE_LIST",
  STORE_DETAILS: "STORE_DETAILS",
};

const ObjectStore = (props: any) => {
  const [screen, setScreen] = useState(SCREENS.STORE_LIST);
  const dispatch = useDispatch();

  const { showStoreDetails, selectedStore = {} } = useSelector(
    (state: any) => state.stageStore
  );
  const toggleShowStoreDetails = (selectedStore: any) =>
    dispatch(actions.toggleShowStoreDetails(selectedStore));
  useEffect(() => {
    if (showStoreDetails) {
      setScreen(SCREENS.STORE_DETAILS);
    } else {
      setScreen(SCREENS.STORE_LIST);
    }
  }, [showStoreDetails]);
  return (
    <div className="px-2">
      {screen === SCREENS.STORE_LIST ? null : (
        <div className="ant-card-head mb-2">
          <Space>
            <Button
              type="link"
              icon={<FontAwesomeIcon icon={faArrowLeft} />}
              onClick={(e) => {
                toggleShowStoreDetails(null);
                setScreen(SCREENS.STORE_LIST);
              }}
              title="Go to Store"
            ></Button>
            <span>{selectedStore?.name} table</span>
          </Space>
        </div>
      )}
      {screen === SCREENS.STORE_LIST && <Store />}
      {screen !== SCREENS.STORE_LIST && <StoreDetails />}
    </div>
  );
};

export default ObjectStore;
