import useAxios from "axios-hooks";

import { ROOT_URL } from "../../utils";
import { useAuthHeaders } from "../auth";

const useStopService = () => {
  const headers = useAuthHeaders();

  const [{ data, loading, error }, execute] = useAxios(
    {
      url: `${ROOT_URL}/deployer-service/stop`,
      method: "POST",
      headers,
    },
    { manual: true }
  );

  return {
    data,
    loading,
    error,
    execute,
  };
};

export default useStopService;

/**
 * Post Data
 * [
 *  {"account": "NonProd"},
 *  {"stage": "UAT"},
 *  {"serviceName": "orders"}
 * ]
 */
