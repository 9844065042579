import React from "react";
import { Card } from "antd";
import { useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import {
  Invoices,
  PaymentMethods,
  PaymentStatement,
  NoSubscription,
  NoBalance,
  ExecutionUnits,
} from "./billing/index";

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY ?? "");

const Billing = (props: any) => {
  const { billing } = useSelector((state: any) => state.auth);

  // This should be always on top as we first check subscription and then go to disabled one.
  if (!billing.SubscriptionAdded) {
    return (
      <Elements stripe={stripePromise}>
        <NoSubscription />
      </Elements>
    );
  }

  if (billing.Disabled) {
    return (
      <Elements stripe={stripePromise}>
        <NoBalance />
      </Elements>
    );
  }

  return (
    <section className="scroll-y" style={{ height: "65vh" }}>
      <Card title="Billing information" bordered={false}>
        <h4>This organisation is billed to</h4>
        <PaymentMethods />
        <h4 className="t-right">Next payment date: 1/2/2021</h4>
      </Card>
      <PaymentStatement />
      <Card title="Usage this month" bordered={false}>
        <ExecutionUnits />
        {/* <Card title="Logs">
          <Row justify="space-between" align="middle">
            <Col>&gt; Total ingested (Gb): 10</Col>
            <Col>Storage (Gb): 50</Col>
            <Col>$ 10</Col>
          </Row>
        </Card> */}
      </Card>
      <Invoices />
    </section>
  );
};
export default Billing;

/**
 *  {
      HK: 'PaymentAccount',
      BalanceExecutionUnits: 4800000,
      Description: '11-Customer created through Kumo API',
      SubscriptionAdded: true,
      Email: 'api.customer11@example.com',
      StripeCustomerId: 'cus_K8LGQEvhoKe1KX',
      RechargeParams: {
        MinExecutionUnits: 250000,
        RechargeAmount: 100,
        Currency: 'inr'
      },
      CreationTimestamp: 1630307181154,
      ExecutionUnits: {
        MemoryUnit: 256,
        DurationUnit: 100,
        ForOneDollar: 24000
      },
      KumohubDomain: 'demo.kumohub.io',
      Disabled: false,
      SK: '1',
      SubscriptionItemID: 'si_K8MEJRFFlp5xvO',
      LastMetricsRun: 1630307181154,
      Name: 'API Customer 11'
    }
 */
