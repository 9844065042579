const prefix = "POLICY";

// Action types
const TOGGLE_EDIT_POLICY = `${prefix}_TOGGLE_EDIT_POLICY`;
const TOGGLE_DELETE_POLICY = `${prefix}_TOGGLE_DELETE_POLICY`;
const SET_SEARCH_TERM = `${prefix}_SET_SEARCH_TERM`;

// State
const defaultState: any = {
  deletePolicy: false,
  editPolicy: false,
  selectedPolicy: null,
  policySearchTerm: "",
};

// Actions
const toggleEditPolicy = (policy: any) => ({
  type: TOGGLE_EDIT_POLICY,
  policy,
});

const toggleDeletePolicy = (policy: any) => ({
  type: TOGGLE_DELETE_POLICY,
  policy,
});

const setPolicySearchTerm = (policySearchTerm: string) => ({
  type: SET_SEARCH_TERM,
  policySearchTerm,
});

export const actions = {
  toggleEditPolicy,
  toggleDeletePolicy,
  setPolicySearchTerm,
};

// Reducers
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = defaultState, action: any) {
  switch (action.type) {
    case TOGGLE_EDIT_POLICY:
      return {
        ...state,
        editPolicy: !state.editPolicy,
        selectedPolicy: action.policy,
      };
    case TOGGLE_DELETE_POLICY:
      return {
        ...state,
        deletePolicy: !state.deletePolicy,
        selectedPolicy: action.policy,
      };
    case SET_SEARCH_TERM:
      return {
        ...state,
        policySearchTerm: action.policySearchTerm,
      };
    default:
      return state;
  }
}
