import useAxios from "axios-hooks";
import { useParams } from "react-router-dom";

import { ROOT_URL } from "../../utils";
import { useAuthHeaders } from "../auth";

const useDeletePolicy = (policyName: string) => {
  const headers = useAuthHeaders();
  const { workspace, stage } = useParams<any>();
  const [{ data, loading, error }, execute] = useAxios(
    {
      url: `${ROOT_URL}/gateway-service`,
      method: "DELETE",
      params: {
        workspace,
        stage,
        policyName,
      },
      headers: {
        ...headers,
      },
    },
    { manual: true }
  );

  return {
    data,
    loading,
    error,
    execute,
  };
};

export default useDeletePolicy;
