import React, { useState } from "react";
import { Button, Form, Input, Modal, Row, Space, Table } from "antd";

import { required, minimum, maximum } from "../../../../../../utils";

const CLAIMS_COLUMNS = [
  { title: "Claim", dataIndex: "name", key: "name" },
  { title: "Value", dataIndex: "value", key: "value" },
  {
    title: "Action",
    key: "action",
    render: (text: string, record: any) => (
      <Space size="middle">
        <Button type="text" danger>
          Remove
        </Button>
      </Space>
    ),
  },
];

const Claims = (props: any) => {
  const { claims, setClaims } = props;
  const [form] = Form.useForm();
  const [isClaimAdd, setClaimAdd] = useState(false);

  const addClaim = (form: any) => {
    form
      .validateFields()
      .then((values: any) => {
        form.resetFields();
        setClaims([...claims, values]);
        setClaimAdd(false);
      })
      .catch((info: any) => {
        console.log("Add User validation failed:", info);
      });
  };

  const cancel = (form: any) => {
    form.resetFields();
    setClaimAdd(false);
  };
  return (
    <>
      <Row justify="space-between">
        <h3>Claims Verification</h3>
        <Button type="primary" ghost className="pull-right" onClick={(e) => setClaimAdd(true)}>
          Add claim
        </Button>
      </Row>
      <Table size="small" columns={CLAIMS_COLUMNS} dataSource={claims} />
      <Modal
        visible={isClaimAdd}
        centered
        title="Add Claim"
        destroyOnClose
        onOk={() => addClaim(form)}
        onCancel={() => cancel(form)}
        okText="Save"
        cancelText="Cancel"
      >
        <Form
          layout="vertical"
          form={form}
          name="variableForm"
          preserve={false}
        >
          <Form.Item
            label="Claim"
            name="name"
            rules={[required("Claim"), minimum("Claim"), maximum("Claim")]}
            normalize={(value: string) => value.trim()}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Value"
            name="value"
            rules={[required("Value"), minimum("Value")]}
            normalize={(value: string) => value.trim()}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default Claims;
