import React, { useState, useEffect } from "react";
import { Card, Col, Row, Spin } from "antd";

import { usePendingUnits } from "../../../../../hooks";
import { handleError } from "../../../../../utils";

const ExecutionUnits = (props: any) => {
  const { loading, data, error } = usePendingUnits();
  const [executionUnits, setExecutionUnits] = useState(0);
  useEffect(() => {
    if (!loading && !error && data) {
      setExecutionUnits(data?.executionUnits);
    } else if (!loading && error) {
      handleError(error);
    }
  }, [loading, data, error]);
  return (
    <Card>
      <Spin spinning={loading}>
        <Card title="Execution time" className="mb-1">
          <Row justify="space-between" align="middle">
            <Col>&gt;&#61; {executionUnits} Units</Col>
          </Row>
        </Card>
      </Spin>
    </Card>
  );
};
export default ExecutionUnits;
