/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Button, Card, Space, Spin, notification } from "antd";

import { useCompleteSignup } from "../hooks";
import { actions as layoutActions } from "../reducers/layout";
import kumologicaLogo from "../assets/logo-black.svg";
import { handleError, localStorage, LOCAL_STORE_KEYS } from "../utils";
import { signOut } from "./utils";

import "./Auth.less";
interface CompleteSignupProps {
  [key: string]: any;
}

const CompleteSignup: React.FC<CompleteSignupProps> = (props: any) => {
  const [called, setCalled] = useState(false);
  const { userId, userName } = JSON.parse(
    localStorage.getItem(LOCAL_STORE_KEYS.USER_DATA) ?? "{}"
  );
  const { session } = JSON.parse(
    localStorage.getItem(LOCAL_STORE_KEYS.AUTH_DATA) ?? "{}"
  );
  const { loading, error, data, execute } = useCompleteSignup();
  const dispatch = useDispatch();
  const toggleSideBar = (open: boolean) =>
    dispatch(layoutActions.toggleSideBar(open));
  const onFinish = (values: any) => {
    const { confirmPassword, ...rest } = values;
    execute({
      data: {
        ...rest,
        userId,
        userName,
        session: session,
      },
    });
    setCalled(true);
  };

  useEffect(() => {
    if (!loading && !error && data && called) {
      setCalled(false);
      notification.success({
        message: "Your password has been changed successfully",
      });
      signOut();
    } else if (!loading && error && called) {
      setCalled(false);
      handleError(error);
    }
  }, [loading, error, data, called]);

  useEffect(() => {
    toggleSideBar(false);
    return () => {
      toggleSideBar(true);
    };
  }, []);

  return (
    <div className="d-flex login-bg">
      <div className="login-wrapper">
        <div className="content">
          <Card className="login">
            <Space className="w-100 justify-content-center">
              <img
                alt="Kumologica logo"
                src={kumologicaLogo}
                className="login-logo"
              />
            </Space>
            <Spin className="login-form" spinning={loading}>
              <Form layout="vertical" name="basic" onFinish={onFinish}>
                <Form.Item
                  label="Proposed Password"
                  name="proposedPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please input your new password!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  label="Confirm Password"
                  name="confirmPassword"
                  dependencies={["proposedPassword"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          !value ||
                          getFieldValue("proposedPassword") === value
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Space align="baseline" className="w-100 justify-content-end">
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Space>
              </Form>
            </Spin>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default CompleteSignup;
