import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Dropdown, Menu, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisV,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import { actions } from "../../../../../reducers/policy";
import useOutsideClick from "../../../../../hooks/helpers";
import { PUBLIC_POLICY_TYPE } from "../../../../../utils";

const PolicyOptions = (props: any) => {
  const { type } = props;
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const [isOpen, toggleMenu] = useState(false);
  const setEditPolicy = () => dispatch(actions.toggleEditPolicy(props));
  const setDeletePolicy = () => dispatch(actions.toggleDeletePolicy(props));
  useOutsideClick(wrapperRef, () => toggleMenu(false));
  return (
    <>
      <div ref={wrapperRef}>
        <Dropdown
          overlay={
            <Menu className="cell-options">
              <Menu.Item key="editPolicy">
                <Button
                  type="text"
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditPolicy();
                    toggleMenu(false);
                  }}
                >
                  <FontAwesomeIcon icon={faEdit} />
                  &nbsp; Edit
                </Button>
              </Menu.Item>
              <Menu.Item key="deletePolicy">
                <Button
                  type="text"
                  onClick={(e) => {
                    e.stopPropagation();
                    setDeletePolicy();
                    toggleMenu(false);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                  &nbsp;Delete
                </Button>
              </Menu.Item>
            </Menu>
          }
          placement="bottomRight"
          arrow
          disabled={type === PUBLIC_POLICY_TYPE}
          onVisibleChange={() => toggleMenu(true)}
          trigger={["click"]}
          visible={isOpen}
        >
          <FontAwesomeIcon
            icon={faEllipsisV}
            title={
              type === PUBLIC_POLICY_TYPE
                ? "System policy cannot be modified"
                : ""
            }
          />
        </Dropdown>
      </div>
    </>
  );
};

export default PolicyOptions;
