/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Badge, Form, Spin, Typography, Button, Select } from "antd";

import { useDeleteStage } from "../../../../../hooks";
import { actions as accountActions } from "../../../../../reducers/account";
import { handleError, buildWorkspaceAndStageMap } from "../../../../../utils";

const { Option } = Select;

const DeleteStage = (props: any) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [called, setCalled] = useState(false);
  const [workspace, setWorkspace] = useState<string | null>(null);
  const [stage, setStage] = useState<string | null>(null);
  const [stages, setStages] = useState<string[]>([]);
  const { workspaces = [] } = useSelector((state: any) => state.account);
  const { workspaceOptions, ...mapData } =
    buildWorkspaceAndStageMap(workspaces);
  const { loading, error, execute } = useDeleteStage({
    workspace,
    stage,
  });
  const selectStageForDelete = (selectedStage: any) =>
    dispatch(accountActions.selectStageForDelete(selectedStage));
  const deleteWorkspaceStage = (
    workspace: string | null,
    stage: string | null
  ) => dispatch(accountActions.deleteWorkspaceStage(workspace, stage));

  const executeDeleteStage = () => {
    execute();
    setCalled(true);
  };

  useEffect(() => {
    if (!loading && !error && called && workspace && stage) {
      deleteWorkspaceStage(workspace, stage);
      setCalled(false);
      selectStageForDelete({});
      setIsOpen(false);
      setWorkspace(null);
      setStage(null);
    } else if (!loading && error && called) {
      handleError(error);
      setIsOpen(false);
    }
  }, [loading, error]);

  const handleWorkspaceChange = (value: string) => {
    setWorkspace(null);
    setStage(null);
    setStages(mapData[value]);
  };

  const handleStageChange = (value: string) => {
    setStage(value);
  };

  const _clearValues = () => {
    selectStageForDelete({});
    setCalled(false);
    setIsOpen(false);
  };
  return (
    <>
      <Button danger onClick={() => setIsOpen(true)}>
        Delete stage
      </Button>
      <Typography.Paragraph>
        Once you delete stage from workspace, there is no going back. Please be
        certain
      </Typography.Paragraph>
      <Modal
        title="Choose the stage details you want to delete"
        visible={isOpen}
        centered
        destroyOnClose
        onOk={executeDeleteStage}
        onCancel={() => _clearValues()}
        okText="OK"
        cancelText="Cancel"
        okButtonProps={{ disabled: !workspace || !stage }}
      >
        <>
          <Typography.Paragraph className="t-center">
            The action CANNOT be undone. This will delete the{" "}
            <Badge count={stage} /> stage permanently.
          </Typography.Paragraph>
          <Spin spinning={loading}>
            <Form>
              <Form.Item label="Workspace Name" name="workspace">
                <Select
                  onChange={handleWorkspaceChange}
                  placeholder="Select Workspace"
                  value={workspace}
                >
                  {workspaceOptions.map((workspaceName) => (
                    <Option key={workspaceName} value={workspaceName}>
                      {workspaceName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Stage Name" name="stage">
                <Select
                  onChange={handleStageChange}
                  value={stage}
                  placeholder="Select Stage"
                >
                  {stages.map((stage) => (
                    <Option key={stage} value={stage}>
                      {stage}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </Spin>
        </>
      </Modal>
    </>
  );
};

export default DeleteStage;
