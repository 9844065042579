import React from "react";
import { Alert, Form, Input, Radio, Select, Space } from "antd";
import _some from "lodash/some";

import {
  TRAFFIC_CONST,
  isValidIPs,
  isValidCIDR,
  isValidIP,
  SECURITY_TYPE,
} from "../../../../../../utils";

const { Option } = Select;

const trafficValidator = (security: string, getFieldValue: Function) => {
  if (security === SECURITY_TYPE.NONE) {
    const throughput = getFieldValue("throughput");
    const requests = getFieldValue(["quota", "requests"]);
    //const period = getFieldValue(["quota", "period"]);
    const list = getFieldValue(["ipWhiteList", "list"]);
    const cidr = getFieldValue(["ipWhiteList", "cidr"]);
    const rangeFrom = getFieldValue(["ipWhiteList", "range", "from"]);
    const rangeTo = getFieldValue(["ipWhiteList", "range", "to"]);
    const requiredValues = [
      throughput,
      requests,
      list,
      cidr,
      rangeFrom,
      rangeTo,
    ];
    console.log(requiredValues);
    if (_some(requiredValues)) {
      return Promise.resolve();
    } else {
      return Promise.reject(
        new Error("Either one of field in Throughput or IP is required")
      );
    }
  } else {
    return Promise.resolve();
  }
};

const TrafficControl = (props: any) => {
  const { whiteListType, setWhiteListType, form, security } = props;

  const onWhiteListChange = (value: string) => {
    switch (value) {
      case TRAFFIC_CONST.LIST:
        form.setFieldsValue({
          ipWhiteList: {
            cidr: null,
            range: {
              from: null,
              to: null,
            },
          },
        });
        break;
      case TRAFFIC_CONST.CIDR:
        form.setFieldsValue({
          ipWhiteList: {
            list: null,
            range: {
              from: null,
              to: null,
            },
          },
        });
        break;
      case TRAFFIC_CONST.RANGE:
        form.setFieldsValue({
          ipWhiteList: {
            cidr: null,
            list: null,
          },
        });
        break;
      default:
    }
    setWhiteListType(value);
  };
  return (
    <>
      {security === SECURITY_TYPE.NONE && (
        <Alert
          message="Either Throttling details or IP details are requried to create None Policy"
          type="warning"
        />
      )}
      <h2>Do you want to restrict ingress traffic?</h2>
      <h3>Throttling</h3>
      <div className="">
        <Form.Item
          label="Throughput"
          name="throughput"
          labelAlign="left"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                return trafficValidator(security, getFieldValue);
              },
            }),
          ]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item label="Quota" labelCol={{ span: 5 }} labelAlign="left">
          <Space>
            <Form.Item
              name={["quota", "requests"]}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    return trafficValidator(security, getFieldValue);
                  },
                }),
              ]}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              name={["quota", "period"]}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value && getFieldValue(["quota", "requests"])) {
                      return Promise.reject(
                        new Error("Quota Period value is required")
                      );
                    }
                    return Promise.resolve();
                  },
                }),
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    return trafficValidator(security, getFieldValue);
                  },
                }),
              ]}
            >
              <Select placeholder="--Select Period--">
                <Option value="day">Day</Option>
                <Option value="week">Week</Option>
                <Option value="month">Month</Option>
              </Select>
            </Form.Item>
          </Space>
        </Form.Item>
      </div>
      <h3>IP Whitelist</h3>
      <div>
        <Radio.Group
          value={whiteListType}
          onChange={(e) => onWhiteListChange(e.target.value)}
          className="w-100"
        >
          <Space direction="vertical" className="w-100">
            <Space align="baseline" className="radio-inputs">
              <Radio value={TRAFFIC_CONST.LIST}>List</Radio>
              <Form.Item
                name={["ipWhiteList", "list"]}
                rules={[
                  {
                    required: whiteListType === TRAFFIC_CONST.LIST,
                    message: "Whitelist IPs are required",
                  },
                  () => ({
                    validator(_, value) {
                      if (value && !isValidIPs(value)) {
                        return Promise.reject(
                          new Error(
                            'Must be valid IPs by seperating each with ";" '
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      return trafficValidator(security, getFieldValue);
                    },
                  }),
                ]}
              >
                <Input
                  placeholder="Use semicolon (;) to seperate different IPs"
                  disabled={whiteListType !== TRAFFIC_CONST.LIST}
                />
              </Form.Item>
            </Space>
          </Space>
          <Space direction="vertical" className="w-100">
            <Space align="baseline" className="radio-inputs">
              <Radio value={TRAFFIC_CONST.CIDR}>CIDR</Radio>
              <Form.Item
                name={["ipWhiteList", "cidr"]}
                rules={[
                  {
                    required: whiteListType === TRAFFIC_CONST.CIDR,
                    message: "Whitelist CIDR details are required",
                  },
                  isValidCIDR("CIDR"),
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      return trafficValidator(security, getFieldValue);
                    },
                  }),
                ]}
              >
                <Input disabled={whiteListType !== TRAFFIC_CONST.CIDR} />
              </Form.Item>
            </Space>
          </Space>
          <Space direction="vertical" className="w-100">
            <Space align="baseline" className="radio-inputs">
              <Radio value={TRAFFIC_CONST.RANGE}>Range</Radio>
              <Form.Item
                name={["ipWhiteList", "range", "from"]}
                rules={[
                  {
                    required: whiteListType === TRAFFIC_CONST.RANGE,
                    message: "Whitelist from range is required",
                  },
                  () => ({
                    validator(_, value) {
                      if (value && !isValidIP(value)) {
                        return Promise.reject(new Error("Must be a valid IP"));
                      }
                      return Promise.resolve();
                    },
                  }),
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      return trafficValidator(security, getFieldValue);
                    },
                  }),
                ]}
              >
                <Input
                  placeholder="From"
                  type="string"
                  disabled={whiteListType !== TRAFFIC_CONST.RANGE}
                />
              </Form.Item>
              <Form.Item
                name={["ipWhiteList", "range", "to"]}
                rules={[
                  {
                    required: whiteListType === TRAFFIC_CONST.RANGE,
                    message: "Whitelist to range is required",
                  },
                  () => ({
                    validator(_, value) {
                      if (value && !isValidIP(value)) {
                        return Promise.reject(new Error("Must be a valid IP"));
                      }
                      return Promise.resolve();
                    },
                  }),
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      return trafficValidator(security, getFieldValue);
                    },
                  }),
                ]}
              >
                <Input
                  placeholder="To"
                  type="string"
                  disabled={whiteListType !== TRAFFIC_CONST.RANGE}
                />
              </Form.Item>
            </Space>
          </Space>
        </Radio.Group>
      </div>
    </>
  );
};
export default TrafficControl;
