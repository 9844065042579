import useAxios from "axios-hooks";
import { ROOT_URL } from "../../utils";
import { useAuthHeaders } from "../auth";

const useDeleteWorkspace = (props: any) => {
  const { workspace } = props;
  const headers = useAuthHeaders();

  const [{ data, loading, error }, execute] = useAxios(
    {
      url: `${ROOT_URL}/workspace-service/workspace?workspace=${workspace}`,
      method: "DELETE",
      headers,
    },
    { manual: true }
  );

  return {
    data,
    loading,
    error,
    execute,
  };
};

export default useDeleteWorkspace;
