/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Modal, Form, Input, Spin, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { useCreateStage } from "../../../hooks";
import { actions as accountActions } from "../../../reducers/account";
import {
  required,
  minimum,
  onlyAlphaNumberic,
  maximum,
  handleError,
} from "../../../utils";

const CreateStage = (props: any) => {
  const { workspace } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isOpen, toggleModal] = useState(false);
  const [called, setCalled] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [stageName, setStageName] = useState("");
  const [description, setStageDescription] = useState("");
  const { loading, error, data, execute } = useCreateStage({
    workspace,
    stage: stageName,
    description,
  });
  const updateWorkspaceStages = (workspace: string, stage: any) =>
    dispatch(accountActions.updateWorkspaceStages(workspace, stage));

  const executeCreateStage = (form: any) => {
    form
      .validateFields()
      .then((values: any) => {
        form.resetFields();
        execute();
        setCalled(true);
      })
      .catch((info: any) => {
        console.log("Validate Failed:", info);
      });
  };

  useEffect(() => {
    if (!loading && !error && called && data) {
      console.log(data);
      updateWorkspaceStages(workspace, {
        id: data?.id,
        name: stageName,
        description,
        createdDate: new Date().getTime(),
        stageVariables: [],
        services: [],
        policies: [],
      });
      setHasErrors(false);
      toggleModal(false);
      setCalled(false);
    } else if (!loading && error && called) {
      setHasErrors(true);
      handleError(error);
    }
  }, [loading, error, data]);

  const _onClose = () => {
    setCalled(false);
    setHasErrors(false);
    toggleModal(!isOpen);
  };
  return (
    <>
      <Button
        type="primary"
        className="mb-0 ant-btn-fw"
        onClick={() => toggleModal(true)}
      >
        <FontAwesomeIcon icon={faPlus} />
        &nbsp; Create Stage
      </Button>
      <Modal
        title="Create Stage"
        visible={isOpen}
        centered
        destroyOnClose
        onOk={() => executeCreateStage(form)}
        onCancel={_onClose}
        okButtonProps={{ disabled: !stageName || hasErrors }}
        okText="Create Stage"
        cancelText="Cancel"
      >
        <Spin spinning={loading}>
          <Form layout="vertical" form={form}>
            <Form.Item
              label="Stage Name"
              name="stageName"
              rules={[
                required("Stage Name"),
                minimum("Stage Name"),
                maximum("Stage Name", 10),
                onlyAlphaNumberic(),
              ]}
              normalize={(value: string) => value.trim()}
            >
              <Input
                value={stageName}
                onChange={(e) => setStageName(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                onlyAlphaNumberic(),
                minimum("Description", 5),
                maximum("Description", 100),
              ]}
            >
              <Input.TextArea
                value={description}
                onChange={(e) => setStageDescription(e.target.value)}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default CreateStage;
