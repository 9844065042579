/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Modal, Space, Input, Empty } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";

import { useParams } from "react-router-dom";

import CreateService from "./CreateService";
import RefreshServices from "./RefreshServices";
import StartService from "./StartService";
import ShowServiceDetails from "./ShowServiceDetails";
import ShowServiceLogs from "./ShowServiceLogs";

import { filterServices } from "../../helpers";
import { getServicesColumns } from "../../columns";

import { useStopService, useDeleteService } from "../../../../hooks";
import { actions } from "../../../../reducers/services";
import {
  SERVICE_STOPPING,
  SERVICE_DELETING,
  handleError,
} from "../../../../utils";

const { confirm } = Modal;

const Servies = (props: any) => {
  const { workspace, stage } = useParams<any>();
  const dispatch = useDispatch();
  const [serviceSearchTerm, setServiceSearchTerm] = useState("");
  const [deleteCalled, setDeleteCalled] = useState(false);
  const [stopCalled, setStopCalled] = useState(false);
  const {
    loadingServices,
    deleteService,
    stopService,
    serviceName,
    serviceVersion,
    stageServices,
  } = useSelector((state: any) => state.services);
  const _filteredServices = filterServices(stageServices, serviceSearchTerm);
  const toggleDeleteService = (serviceName: string) =>
    dispatch(actions.toggleDeleteService(serviceName, 0));
  const toggleStopService = (serviceName: string) =>
    dispatch(actions.toggleStopService(serviceName));
  const toggleShowService = (service: any) =>
    dispatch(actions.toggleShowService(service));
  const updateService = (service: any) =>
    dispatch(actions.updateStageServiceStatus(service));

  const {
    loading: stopServiceLoading,
    error: stopServiceError,
    execute: executeStop,
  } = useStopService();
  const {
    loading: deleteServiceLoading,
    error: deleteServiceError,
    execute: executeDelete,
  } = useDeleteService();
  useEffect(() => {
    if (!stopServiceLoading && !stopServiceError && stopCalled) {
      setStopCalled(false);
      updateService({
        name: serviceName,
        status: SERVICE_STOPPING,
      });
      toggleStopService("");
    } else if (!stopServiceLoading && stopServiceError && stopCalled) {
      setStopCalled(false);
      toggleStopService("");
      handleError(stopServiceError);
    }
  }, [stopServiceLoading, stopServiceError, stopCalled]);
  useEffect(() => {
    if (!deleteServiceLoading && !deleteServiceError && deleteCalled) {
      setDeleteCalled(false);
      updateService({
        name: serviceName,
        status: SERVICE_DELETING,
      });
      toggleDeleteService("");
    } else if (!deleteServiceLoading && deleteServiceError && deleteCalled) {
      setDeleteCalled(false);
      toggleDeleteService("");
      handleError(deleteServiceError);
    }
  }, [deleteServiceLoading, deleteServiceError, deleteCalled]);

  useEffect(() => {
    if (stopService) {
      executeStop({
        data: {
          account: workspace,
          stage,
          serviceName,
        },
      });
      setStopCalled(true);
    }
  }, [stopService]);

  const _executeDeleteService = () => {
    executeDelete({
      data: {
        account: workspace,
        stage,
        serviceName,
        serviceVersion,
      },
    });
    setDeleteCalled(true);
  };

  useEffect(() => {
    if (deleteService) {
      confirm({
        title: `Do you want to delete function ${serviceName}?`,
        icon: (
          <FontAwesomeIcon icon={faExclamationCircle} className="anticon" />
        ),
        onOk() {
          _executeDeleteService();
        },
        onCancel() {
          toggleDeleteService("");
        },
      });
    }
  }, [deleteService]);

  useEffect(() => {
    return () => {
      setServiceSearchTerm("");
    };
  }, []);

  return (
    <div className="px-2">
      <Space className="justify-content-between w-100 mb-1">
        <Input
          value={serviceSearchTerm}
          onChange={(e) => setServiceSearchTerm(e.target.value)}
          placeholder="Search services"
          suffix={<FontAwesomeIcon icon={faSearch} />}
        />
        <Space>
          <CreateService />
          <RefreshServices />
        </Space>
      </Space>
      <section className="scroll-y" style={{ height: "65vh" }}>
        <Table
          size="small"
          loading={loadingServices}
          dataSource={_filteredServices}
          rowKey="id"
          columns={getServicesColumns(toggleShowService)}
          locale={{
            emptyText: <Empty description="No functions created yet" />,
          }}
        />
      </section>
      <StartService />
      <ShowServiceDetails />
      <ShowServiceLogs />
    </div>
  );
};

export default Servies;
