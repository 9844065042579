import React from "react";
import { Spin } from "antd";
const Scaffold = (props: any) => {
  const { loading, error } = props;

  if (loading)
    return (
      <Spin>
        <div className="d-flex h-100 w-100"></div>
      </Spin>
    );
  if (error) return <div>Error</div>;
  return <div className="scaffold">{props.children}</div>;
};

export default Scaffold;
