import React from "react";
import { useSelector, useDispatch } from "react-redux";

import Login from "./Login";

import { actions } from "../reducers/auth";
import { localStorage, LOCAL_STORE_KEYS } from "../utils";

// export const AuthRoute = ({
//   component: Component,
//   path,
//   ...rest
// }: RouteProps) => {
//   const dispatch = useDispatch();
//   const setAuthData = (authData: any) =>
//     dispatch(actions.setAuthData(authData));
//   const setUserData = (userData: any) =>
//     dispatch(actions.setUserData(userData));
//   const { authData, userData } = useSelector((state: any) => state.auth);
//   const authDataFromStore = localStorage.getItem(LOCAL_STORE_KEYS.AUTH_DATA);
//   const userDataFromStore = localStorage.getItem(LOCAL_STORE_KEYS.USER_DATA);
//   if (!authData && authDataFromStore) {
//     setAuthData(JSON.parse(authDataFromStore));
//   }
//   if (!userData?.email && userDataFromStore) {
//     setUserData(JSON.parse(userDataFromStore));
//   }
//   if (authData && userData?.email) {
//     return <Route component={Component} path={path} {...rest} />;
//   } else {
//     <Redirect to="/login" />;
//   }
// };

const AuthenticatedAppWrapper = (props: any) => {
  const dispatch = useDispatch();
  const setAuthData = (authData: any) =>
    dispatch(actions.setAuthData(authData));
  const setUserData = (userData: any) =>
    dispatch(actions.setUserData(userData));
  const setBillingData = (billing: any) =>
    dispatch(actions.setBillingData(billing));
  const { authData, userData, billing } = useSelector(
    (state: any) => state.auth
  );
  const authDataFromStore = localStorage.getItem(LOCAL_STORE_KEYS.AUTH_DATA);
  const userDataFromStore = localStorage.getItem(LOCAL_STORE_KEYS.USER_DATA);
  const billingDataFromStore = localStorage.getItem(
    LOCAL_STORE_KEYS.BILLING_DATA
  );

  if (!authData && authDataFromStore) {
    setAuthData(JSON.parse(authDataFromStore));
  }
  if (!userData && userDataFromStore) {
    setUserData(JSON.parse(userDataFromStore));
  }
  if (!billing && billingDataFromStore) {
    setBillingData(JSON.parse(billingDataFromStore));
  }
  if (authData && userData?.email) {
    return props.children;
  } else {
    return <Login />;
  }
};
export default AuthenticatedAppWrapper;
