import {
  updateStagePolicyDetailsByName,
  updateStageServiceDetailsByName,
  updateStageSchedulerById,
  deleteStagePolicyByName,
} from "../utils";

const prefix = "SERVICES";

// Action types
const TOGGLE_DELETE_SERVICE = `${prefix}_TOGGLE_DELETE_SERVICE`;
const TOGGLE_START_SERVICE = `${prefix}_TOGGLE_START_SERVICE`;
const TOGGLE_STOP_SERVICE = `${prefix}_TOGGLE_STOP_SERVICE`;
const TOGGLE_SHOW_SERVICE = `${prefix}_TOGGLE_SHOW_SERVICE`;
const TOGGLE_SHOW_SERVICE_LOGS = `${prefix}_TOGGLE_SHOW_SERVICE_LOGS`;
const SET_STAGE_SERVICES = `${prefix}_SET_STAGE_SERVICES`;
const UPDATE_STAGE_SERVICES = `${prefix}_UPDATE_STAGE_SERVICES`;
const UPDATE_STAGE_SERVICE = `${prefix}_UPDATE_STAGE_SERVICE`;
const UPDATE_STAGE_SERVICE_STATUS = `${prefix}_UPDATE_STAGE_SERVICE_STATUS`;
const SET_LOADING = `${prefix}_SET_LOADING`;
const SET_STAGE_POLICIES = `${prefix}_SET_STAGE_POLICIES`;
const UPDATE_STAGE_POLICY = `${prefix}_UPDATE_STAGE_POLICY`;
const UPDATE_STAGE_SCHEDULER = `${prefix}_UPDATE_STAGE_SCHEDULER`;
const DELETE_STAGE_POLICY = `${prefix}_DELETE_STAGE_POLICY`;
const SET_FUNCTION_SEARCH = `${prefix}_SET_FUNCTION_SEARCH`;

// State
const defaultState: any = {
  loadingServices: false,
  deleteService: false,
  startService: false,
  stopService: false,
  showService: false,
  showServiceLogs: false,
  serviceName: null,
  serviceVersion: 0,
  service: null,
  stageServices: [],
  stagePolicies: [],
  functionSearchTerm: "",
};

// Actions
const toggleStartService = (serviceName: string) => ({
  type: TOGGLE_START_SERVICE,
  serviceName,
});

const toggleStopService = (serviceName: string) => ({
  type: TOGGLE_STOP_SERVICE,
  serviceName,
});

const toggleDeleteService = (serviceName: string, serviceVersion: number) => ({
  type: TOGGLE_DELETE_SERVICE,
  serviceName,
  serviceVersion,
});

const toggleShowService = (service: any) => ({
  type: TOGGLE_SHOW_SERVICE,
  service,
});

const toggleServiceLogs = (serviceName: string) => ({
  type: TOGGLE_SHOW_SERVICE_LOGS,
  serviceName,
});

const setStageServices = (stageServices: any) => ({
  type: SET_STAGE_SERVICES,
  stageServices,
});

const updateStageService = (service: any) => ({
  type: UPDATE_STAGE_SERVICE,
  service,
});

const updateStageServices = (services: any) => ({
  type: UPDATE_STAGE_SERVICES,
  services,
});

const toggleLoadingServices = () => ({
  type: SET_LOADING,
});

const updateStageServiceStatus = (service: any) => ({
  type: UPDATE_STAGE_SERVICE_STATUS,
  service,
});

const updateStagePolicy = (policy: any) => ({
  type: UPDATE_STAGE_POLICY,
  policy,
});

const setStagePolicies = (policies: any) => ({
  type: SET_STAGE_POLICIES,
  policies,
});

const updateStageScheduler = (scheduler: any) => ({
  type: UPDATE_STAGE_SCHEDULER,
  scheduler,
});

const deleteStagePolicy = (policy: any) => ({
  type: DELETE_STAGE_POLICY,
  policy,
});

const setFunctionSearchTerm = (functionSearchTerm: string) => ({
  type: SET_FUNCTION_SEARCH,
  functionSearchTerm,
});

export const actions = {
  toggleDeleteService,
  toggleStartService,
  toggleStopService,
  toggleShowService,
  toggleServiceLogs,
  setStageServices,
  updateStageService,
  updateStageServices,
  toggleLoadingServices,
  updateStageServiceStatus,
  updateStagePolicy,
  setStagePolicies,
  updateStageScheduler,
  deleteStagePolicy,
  setFunctionSearchTerm,
};

// Reducers
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = defaultState, action: any) {
  switch (action.type) {
    case TOGGLE_START_SERVICE:
      return {
        ...state,
        startService: !state.startService,
        serviceName: action.serviceName,
      };
    case TOGGLE_STOP_SERVICE:
      return {
        ...state,
        stopService: !state.stopService,
        serviceName: action.serviceName,
      };
    case TOGGLE_DELETE_SERVICE:
      return {
        ...state,
        deleteService: !state.deleteService,
        serviceName: action.serviceName,
        serviceVersion: action.serviceVersion,
      };
    case TOGGLE_SHOW_SERVICE:
      return {
        ...state,
        showService: !state.showService,
        service: action.service,
      };
    case TOGGLE_SHOW_SERVICE_LOGS:
      return {
        ...state,
        showServiceLogs: !state.showServiceLogs,
        serviceName: action.serviceName,
      };
    case SET_STAGE_SERVICES:
      return { ...state, stageServices: action.stageServices };
    case UPDATE_STAGE_SERVICE:
      return {
        ...state,
        stageServices: updateStageServiceDetailsByName(
          state.stageServices,
          action.service
        ),
      };
    case UPDATE_STAGE_SERVICES:
      return { ...state, stageServices: action.services };
    case SET_LOADING:
      return { ...state, loadingServices: !state.loadingServices };
    case UPDATE_STAGE_SERVICE_STATUS:
      return {
        ...state,
        stageServices: updateStageServiceDetailsByName(
          state.stageServices,
          action.service
        ),
      };
    case UPDATE_STAGE_POLICY:
      return {
        ...state,
        stagePolicies: updateStagePolicyDetailsByName(
          state.stagePolicies,
          action.policy
        ),
      };
    case SET_STAGE_POLICIES:
      return {
        ...state,
        stagePolicies: action.policies,
      };
    case UPDATE_STAGE_SCHEDULER:
      return {
        ...state,
        stageServices: updateStageSchedulerById(
          state.stageServices,
          action.scheduler
        ),
      };
    case DELETE_STAGE_POLICY:
      return {
        ...state,
        stagePolicies: deleteStagePolicyByName(
          state.stagePolicies,
          action.policy
        ),
      };
    case SET_FUNCTION_SEARCH:
      return {
        ...state,
        functionSearchTerm: action.functionSearchTerm,
      };
    default:
      return state;
  }
}
