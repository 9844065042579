/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Space, Button, Select, Row, Col, Form } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";

import Metric from "./Metric";
import { useMetrics } from "../../../../hooks";
import { TIME_PERIODS, handleError } from "../../../../utils";
import { getServicesList } from "../../helpers";

const DefaultMetrics: { [key: string]: any } = {
  invocations: [{ Label: "Invocations", Timestamps: [], Values: [] }],
  duration: [{ Label: "Duration", Timestamps: [], Values: [] }],
  concurrent: [{ Label: "ConcurrentExecutions", Timestamps: [], Values: [] }],
  errors: [{ Label: "Errors", Timestamps: [], Values: [] }],
};

const Metrics = () => {
  const { stageServices } = useSelector((state: any) => state.services);
  const [service, setSelectedService] = useState("");
  const [metrics, setMetrics] = useState(DefaultMetrics);
  const [empty, setEmpty] = useState(true);
  const [called, setCalled] = useState(false);
  const [timePeriod, setTimePeriod] = useState(300);
  const { loading, error, data, execute } = useMetrics({
    service,
    timePeriod,
  });
  const servicesList = getServicesList(stageServices);
  useEffect(() => {
    if (service) {
      execute();
      setCalled(true);
      setEmpty(false);
    }
  }, [service, timePeriod]);

  useEffect(() => {
    if (!loading && !error && data && called) {
      setMetrics(data);
      setCalled(false);
    } else if (!loading && error && called) {
      setMetrics(DefaultMetrics);
      setCalled(false);
      handleError(error);
    }
  }, [loading, error, data]);

  const _reload = () => {
    execute();
    setCalled(true);
  };
  return (
    <div className="px-2">
      <Space className="justify-content-end mb-1 w-100">
        <Form layout="inline">
          <Form.Item label="Service Name" name="serviceName">
            <Select
              style={{ width: "250px" }}
              options={servicesList}
              placeholder="Select Service"
              clearIcon
              labelInValue
              value={service}
              onChange={(selected: any) => {
                return setSelectedService(selected.label as string);
              }}
            />
          </Form.Item>
          <Form.Item label="Time Period" name="timePeriod" className="m-0">
            <Select
              style={{ width: "250px" }}
              options={TIME_PERIODS}
              placeholder="Select Time Period"
              clearIcon
              value={timePeriod}
              onChange={(value) => setTimePeriod(value)}
            />
          </Form.Item>
        </Form>
        <Button onClick={() => _reload()}>
          <FontAwesomeIcon icon={faSyncAlt} />
          &nbsp; Refresh
        </Button>
      </Space>
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        className="scroll-y"
        style={{ height: "65vh" }}
      >
        {metrics &&
          Object.keys(metrics).map((metricKey: string) => {
            return (
              <Col key={metricKey} span={12} className="mb-1">
                <Metric
                  key={metricKey}
                  metric={metrics[metricKey]}
                  loading={loading}
                  empty={empty}
                />
              </Col>
            );
          })}
      </Row>
    </div>
  );
};

export default Metrics;
