/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form, Input, Button, Card, Space, Spin, Col, Row } from "antd";
import { useDispatch } from "react-redux";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

import { actions } from "../reducers/auth";
import kumohubLogo from "../assets/kumohub-logo.png";
import { useLogin } from "../hooks";
import { extractData } from "./utils";
import { handleError, ROUTES } from "../utils";

import "./Auth.less";

interface LoginTopProps {
  [key: string]: any;
}

const LoginTop: React.FC<LoginTopProps> = (props: any) => {
  const [called, setCalled] = useState(false);
  const { loading, error, data, execute } = useLogin();
  const dispatch = useDispatch();
  const setAuthData = (authData: any) =>
    dispatch(actions.setAuthData(authData));
  const setUserData = (userData: any) =>
    dispatch(actions.setUserData(userData));
  const setBillingData = (billing: any) =>
    dispatch(actions.setBillingData(billing));

  const onFinish = (values: any) => {
    execute({
      data: {
        ...values,
      },
    });
    setCalled(true);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (!loading && !error && data && called) {
      const isNewUser = data?.challengeName === "NEW_PASSWORD_REQUIRED";
      const { authData, userData, billing } = extractData(data, isNewUser);
      setBillingData(billing);
      setAuthData(authData);
      setUserData(userData);
      if (isNewUser) {
        window.location.pathname = ROUTES.COMPLETE_SIGN_UP;
      } else {
        window.location.pathname = ROUTES.WORKSPACES;
      }
    } else if (!loading && error && called) {
      setCalled(false);
      handleError(error);
    }
  }, [loading, data, error]);
  return (
    <div className="d-flex login-bg">
      <div className="login-wrapper">
        <div className="content">
          <Card className="login">
            <Space className="w-100 justify-content-center">
              <img alt="Kumohub" src={kumohubLogo} className="login-logo" />
            </Space>
            <h3 className="t-center">Log in to your account</h3>
            <Spin className="login-form" spinning={loading}>
              <Form
                layout="vertical"
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  label="Email address"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Email address!",
                    },
                  ]}
                >
                  <Input prefix={<UserOutlined />} />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input.Password prefix={<LockOutlined />} />
                </Form.Item>
                <Button type="primary" block size="large" htmlType="submit">
                  Log In
                </Button>
              </Form>
            </Spin>
          </Card>
          <div className="mt-1 t-center">
            <a href="/forgotPassword" className="t-default">
              Forgot your password ?
            </a>
          </div>
        </div>
        <div className="footer">
          <Row>
            <Col>
              <a href="/">Terms and conditions</a>
            </Col>
            <Col>
              <a href="/">Privacy</a>
            </Col>
          </Row>
          <p className="t-default t-center mt-1">&copy; 2022 Kumologica.com</p>
        </div>
      </div>
    </div>
  );
};

export default LoginTop;
