/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Layout } from "antd";

import "./Layout.less";

import { actions as accountActions } from "../reducers/account";
import { Header } from "./components";
import {
  Workspace,
  Stage,
  Workspaces,
  Settings,
  PaymentSuccess,
  SubscriptionSuccess,
} from "../pages";
import { useAccount } from "../hooks";
import { handleError, ROUTES } from "../utils";
import { ForgotPassword, CompleteSignup } from "../auth";
import { AuthenticatedAppWrapper } from "../auth";

const { Content } = Layout;

interface KumoLayoutProps {
  [key: string]: any;
}

const KumoLayout: React.FC<KumoLayoutProps> = (props) => {
  const dispatch = useDispatch();
  const { loading, data, error, refetch } = useAccount();
  const [called, setCalled] = useState(false);
  const { authData, userData } = useSelector((state: any) => state.auth);
  const selectWorkspaceData = (data: any) =>
    dispatch(accountActions.selectWorkspaceData(data));
  const selectWorkspaceError = (error: any) =>
    dispatch(accountActions.selectWorkspaceError(error));

  useEffect(() => {
    if (!loading && data && !error && called) {
      selectWorkspaceData(data);
      setCalled(false);
    } else if (!loading && error && called) {
      selectWorkspaceError(error);
      setCalled(false);
      handleError(error);
    }
  }, [loading, data, error]);

  useEffect(() => {
    if (authData && userData) {
      refetch();
      setCalled(true);
    }
  }, [authData, userData]);

  return (
    <BrowserRouter>
      <Switch>
        <Route
          path={ROUTES.FORGOT_PASSWORD}
          exact
          render={() => <ForgotPassword />}
        ></Route>
        <Route
          path={ROUTES.COMPLETE_SIGN_UP}
          exact
          render={() => <CompleteSignup loading={loading} />}
        />
        <AuthenticatedAppWrapper>
          <Header style={{ position: "fixed", zIndex: 1, width: "100%" }} />
          <Layout>
            <Content>
              <Switch>
                <Route
                  path={ROUTES.WORKSPACES}
                  exact
                  render={() => <Workspaces loading={loading} />}
                />
                <Route
                  path={ROUTES.WORKSPACE}
                  exact
                  render={() => <Workspace loading={loading} />}
                />
                <Route
                  path={ROUTES.STAGE}
                  exact
                  render={() => <Stage loading={loading} />}
                />
                <Route
                  path={ROUTES.SETTINGS}
                  exact
                  render={() => <Settings loading={loading} />}
                />
                <Route
                  path={ROUTES.TXN_SUCCESS}
                  exact
                  render={() => <PaymentSuccess />}
                />
                <Route
                  path={ROUTES.SUBSCRIPTION_SUCCESS}
                  exact
                  render={() => <SubscriptionSuccess />}
                />
              </Switch>
            </Content>
          </Layout>
        </AuthenticatedAppWrapper>
      </Switch>
    </BrowserRouter>
  );
};

export default KumoLayout;
