export const API_URL = process.env.REACT_APP_API_URL;
export const API_KEY = process.env.REACT_APP_API_KEY;
export const SCHEDULER_API_URL = process.env.REACT_APP_SCHEDULER_API_URL;

export const ROOT_URL = process.env.REACT_APP_KL_CP_ROOT_URL;

// Services
export const SERVICE_STARTED = "Running";
export const SERVICE_STARTING = "Starting";
export const SERVICE_DEPLOYING = "Deploying";
export const SERVICE_STOPPING = "Stopping";
export const SERVICE_DELETING = "Deleting";
export const DEPLOY_FAILED = "Deploy Failed";
export const SERVICE_STOPPED = "Stopped";

// Schedulers
export const SCHEDULER_ENABLED = "Enabled";
export const SCHEDULER_DISABLED = "Disabled";

export const TIME_PERIODS = [
  {
    value: 300,
    label: "Last 5 mins",
  },
  {
    value: 1800,
    label: "Last 30 mins",
  },
  {
    value: 86400,
    label: "Last 24 hrs",
  },
];

export const SECURITY_TYPE = {
  NONE: "None",
  API_KEY: "ApiKey",
  BASIC: "BasicAuth",
  JWT: "JwtToken",
};

export const SIGNATURE_TYPE = {
  SECRET: "secret",
  KEY: "key",
  JWKS: "jwks",
};

export const TRAFFIC_CONST = {
  IP_WHITE_LIST: "ipWhiteListing",
  LIST: "list",
  CIDR: "cidr",
  RANGE: "range",
};

export const LOCAL_STORE_KEYS = {
  AUTH_DATA: "authData",
  USER_DATA: "userData",
  BILLING_DATA: "billing",
};

// Policy
export const PUBLIC_POLICY = "Public";
export const PUBLIC_POLICY_TYPE = "System";

export const GLOBAL_DATE_FORMAT = "MMM DD YYYY HH:mm";

export const TAB_KEYS = {
  EVENTGATEWAY: "d20b1b91d124",
  FUNCTIONS: "61507901f520",
  OBJECTSTORE: "5c80fbca31ab",
  METRICS: "ac91e5139f5c",
  SCHEDULERS: "08433165f172",
  ENDPOINTS: "f1b70bb93276",
  POLICIES: "8a74d6385e96",
};
