import useAxios from "axios-hooks";

import { ROOT_URL } from "../../utils";
import { useAuthHeaders } from "../auth";

const useCustomerInvoices = () => {
  const headers = useAuthHeaders();

  const [{ data, loading, error }] = useAxios({
    url: `${ROOT_URL}/payments/invoices`,
    method: "GET",
    headers,
  });

  return {
    data,
    loading,
    error,
  };
};

export default useCustomerInvoices;
