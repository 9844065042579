import { localStorage, LOCAL_STORE_KEYS } from "../utils";

export const extractData = (data: any, isNewUser: boolean) => {
  let authData = {};
  let userData = {};
  let billing = {};
  if (isNewUser) {
    authData = { session: data?.session };
    userData = {
      challengeName: data?.challengeName,
      userId: data?.userId,
      userName: data?.loginUserName,
    };
  } else {
    authData = data?.AuthenticationResult ?? {};
    userData = {
      email: data?.email,
      userName: data?.userName,
      company: data?.companyName,
      userId: data?.userId,
    };
    billing = {
      ...(data?.paymentAccount ?? {}),
    };
  }
  localStorage.setItem(LOCAL_STORE_KEYS.AUTH_DATA, JSON.stringify(authData));
  localStorage.setItem(LOCAL_STORE_KEYS.USER_DATA, JSON.stringify(userData));
  localStorage.setItem(LOCAL_STORE_KEYS.BILLING_DATA, JSON.stringify(billing));
  return {
    authData,
    userData,
    billing,
  };
};

export const signOut = () => {
  localStorage.removeItem(LOCAL_STORE_KEYS.AUTH_DATA);
  localStorage.removeItem(LOCAL_STORE_KEYS.USER_DATA);
  localStorage.removeItem(LOCAL_STORE_KEYS.BILLING_DATA);
  window.location.pathname = "/";
  //window.location.reload();
};
