import useAxios from "axios-hooks";
import { useParams } from "react-router-dom";

import { ROOT_URL } from "../../utils";
import { useAuthHeaders } from "../auth";

const useServiceDetails = (props: any) => {
  const { service } = props;
  const { workspace, stage } = useParams<any>();
  const headers = useAuthHeaders();

  const [{ data, loading, error }, execute] = useAxios(
    {
      url: `${ROOT_URL}/services-service/${workspace}/${stage}/${service}`,
      method: "GET",
      headers,
    },
    { manual: true }
  );

  return {
    data,
    loading,
    error,
    execute,
  };
};

export default useServiceDetails;
