import React, { useState, useEffect } from "react";
import { Card, Col, Row, Spin, Comment } from "antd";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCcVisa, faStripe } from "@fortawesome/free-brands-svg-icons";

import { usePaymentMethods } from "../../../../../hooks";
import { handleError } from "../../../../../utils";
import ChangeCard from "./ChangeCard";

const getCardIcon = (brand: string) => {
  switch (brand) {
    case "visa":
      return faCcVisa;
    default:
      return faStripe;
  }
};

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY ?? "");
const PaymentMethods = (props: any) => {
  const { loading, data, error } = usePaymentMethods();
  const [paymentMethods, setPaymentMethods] = useState([]);
  useEffect(() => {
    if (!loading && !error && data) {
      setPaymentMethods(data?.paymentMethods);
    } else if (!loading && error) {
      handleError(error);
    }
  }, [loading, data, error]);
  return (
    <Elements stripe={stripePromise}>
      <Card>
        <Spin spinning={loading}>
          {paymentMethods.map((method: any) => {
            return (
              <Row justify="space-between" align="middle" key={method.id}>
                <Col>
                  <Comment
                    content={`Card ending with ${method.last4}`}
                    avatar={
                      <FontAwesomeIcon
                        size="3x"
                        icon={getCardIcon(method.brand)}
                      />
                    }
                  />
                </Col>
                <Col>
                  <ChangeCard />
                </Col>
              </Row>
            );
          })}
        </Spin>
      </Card>
    </Elements>
  );
};
export default PaymentMethods;
