import React from "react";
import { Link } from "react-router-dom";
import { Card } from "antd";
import moment from "moment";
import "./Stage.less";

const from = (timeStamp: number) => moment(Date.now()).diff(timeStamp, "days");

const Stage = (props: any) => {
  const { workspace, name, createdDate, services, description = "" } = props;
  return (
    <Link className="stage" to={`/workspaces/${workspace}/${name}`}>
      <Card
        title={name}
        actions={[
          <div title="Services">
            {services.length > 0 ? (
              <span>{`${services.length} function${
                services.length > 1 ? "s" : ""
              }`}</span>
            ) : (
              <span>No function</span>
            )}
          </div>,
          <div>{`Created ${from(createdDate)} days ago`}</div>,
        ]}
      >
        <p style={{ color: "rgba(0, 0, 0, 0.45)" }}>{description ?? ""}</p>
      </Card>
    </Link>
  );
};

export default Stage;
