/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Tabs } from "antd";

import HTTPEndpoints from "./endPoints";
import Schedulers from "./schedulers";
import { useQuery } from "../../../../components";
import { TAB_KEYS } from "../../../../utils";

import "./EventGateway.less";

const { TabPane } = Tabs;

const EventGateway = (props: any) => {
  const [currentTab, setCurrentTab] = useState(TAB_KEYS.ENDPOINTS);
  const query = useQuery();
  let section = query.get("section");

  useEffect(() => {
    if (section) {
      setCurrentTab(section);
    }
  }, [section]);
  return (
    <div className="px-2">
      <Tabs
        tabPosition="left"
        type="card"
        activeKey={currentTab}
        onChange={setCurrentTab}
      >
        <TabPane
          tab={<span>&nbsp; HTTP Endpoints</span>}
          key={TAB_KEYS.ENDPOINTS}
        >
          <HTTPEndpoints />
        </TabPane>
        <TabPane tab={<span>&nbsp; Schedulers</span>} key={TAB_KEYS.SCHEDULERS}>
          <Schedulers />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default EventGateway;
