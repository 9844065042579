import _filter from "lodash/filter";

import {
  updateStageVariables as _updateStageVariables,
  updateStageObjectStore as _updateStageObjectStore,
} from "../utils";

const prefix = "STORE";

// Action types
const TOGGLE_DELETE_VARIABLE = `${prefix}_TOGGLE_DELETE_VARIABLE`;
const TOGGLE_UPSERT_VARIABLE = `${prefix}_TOGGLE_UPSERT_VARIABLE`;
const SET_STAGE_VARIABLES = `${prefix}_SET_STAGE_VARIABLES`;
const UPDATE_STAGE_VARIABLES = `${prefix}_UPDATE_STAGE_VARIABLES`;
const DELETE_STAGE_VARIABLE = `${prefix}_DELETE_STAGE_VARIABLE`;

const SET_STAGE_OBJECT_STORES = `${prefix}_SET_STAGE_OBJECT_STORES`;
const UPDATE_STAGE_OBJECT_STORE = `${prefix}_UPDATE_STAGE_OBJECT_STORE`;
const DELETE_STAGE_OBJECT_STORE = `${prefix}_DELETE_STAGE_OBJECT_STORE`;
const TOGGLE_UPSERT = `${prefix}_TOGGLE_UPSERT`;
const TOGGLE_UPSERT_TTL = `${prefix}_TOGGLE_UPSERT_TTL`;
const TOGGLE_DISABLE_TTL = `${prefix}_TOGGLE_DISABLE_TTL`;
const TOGGLE_ENABLE_STREAM = `${prefix}_TOGGLE_ENABLE_STREAM`;
const TOGGLE_DISABLE_STREAM = `${prefix}_TOGGLE_DISABLE_STREAM`;
const TOGGLE_DELETE_STORE = `${prefix}_TOGGLE_DELETE_STORE`;
const TOGGLE_SHOW_DETAILS = `${prefix}_TOGGLE_SHOW_DETAILS`;

// State
const defaultState: any = {
  isDeleting: false,
  deleteVariable: null,
  updateVariable: null,
  isUpSert: false,
  stageVariables: [],

  objectStores: [],
  isUpSertStore: false,
  isEnableStream: false,
  isDisableStream: false,
  isUpSertTTL: false,
  isDisableTTL:false,
  isDeleteStore:false,
  showStoreDetails: false,
  selectedStore: null,
};

// Actions
const toggleDeleteVariable = (variable: any) => ({
  type: TOGGLE_DELETE_VARIABLE,
  variable,
});

const toggleUpsertVariable = (variable: any) => ({
  type: TOGGLE_UPSERT_VARIABLE,
  variable,
});

const setStageVariables = (stageVariables: any) => ({
  type: SET_STAGE_VARIABLES,
  stageVariables,
});

const updateStageVariables = (variable: any) => ({
  type: UPDATE_STAGE_VARIABLES,
  variable,
});

const deleteStageVariable = (variableId: number) => ({
  type: DELETE_STAGE_VARIABLE,
  variableId,
});

// Object Store

const setStageObjectStores = (objectStores: any) => ({
  type: SET_STAGE_OBJECT_STORES,
  objectStores,
});

const updateStageObjectStore = (objectStore: any) => ({
  type: UPDATE_STAGE_OBJECT_STORE,
  objectStore,
});

const toggleUpsertStore = (objectStore: any) => ({
  type: TOGGLE_UPSERT,
  objectStore,
});

const toggleUpsertTTL = (objectStore: any) => ({
  type: TOGGLE_UPSERT_TTL,
  objectStore,
});

const toggleDisableTTL = (objectStore: any) => ({
  type: TOGGLE_DISABLE_TTL,
  objectStore,
});

const toggleEnableStream = (objectStore: any) => ({
  type: TOGGLE_ENABLE_STREAM,
  objectStore,
});

const toggleDisableStream = (objectStore: any) => ({
  type: TOGGLE_DISABLE_STREAM,
  objectStore,
});

const toggleShowStoreDetails = (objectStore: any) => ({
  type: TOGGLE_SHOW_DETAILS,
  objectStore,
});

const toggleDeleteStore = (objectStore: any) => ({
  type: TOGGLE_DELETE_STORE,
  objectStore,
});

const deleteObjectStore = (storeName:string) => ({
  type: DELETE_STAGE_OBJECT_STORE,
  storeName
})

export const actions = {
  toggleDeleteVariable,
  toggleUpsertVariable,
  setStageVariables,
  updateStageVariables,
  deleteStageVariable,
  setStageObjectStores,
  toggleUpsertStore,
  toggleUpsertTTL,
  toggleDisableTTL,
  updateStageObjectStore,
  toggleEnableStream,
  toggleDisableStream,
  toggleShowStoreDetails,
  toggleDeleteStore,
  deleteObjectStore
};

// Reducers
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = defaultState, action: any) {
  switch (action.type) {
    case TOGGLE_DELETE_VARIABLE:
      return {
        ...state,
        isDeleting: !state.isDeleting,
        deleteVariable: action.variable,
      };
    case TOGGLE_UPSERT_VARIABLE:
      return {
        ...state,
        isUpSert: !state.isUpSert,
        updateVariable: action.variable,
      };
    case SET_STAGE_VARIABLES:
      return { ...state, stageVariables: action.stageVariables };
    case UPDATE_STAGE_VARIABLES:
      return {
        ...state,
        stageVariables: _updateStageVariables(
          state.stageVariables,
          action.variable
        ),
      };
    case DELETE_STAGE_VARIABLE:
      return {
        ...state,
        stageVariables: _filter(
          state.stageVariables,
          (variable: any) => variable.id !== action.variableId
        ),
      };
    case SET_STAGE_OBJECT_STORES:
      return { ...state, objectStores: action.objectStores };
    case UPDATE_STAGE_OBJECT_STORE:
      return {
        ...state,
        objectStores: _updateStageObjectStore(
          state.objectStores,
          action.objectStore
        ),
      };
    case TOGGLE_UPSERT:
      return {
        ...state,
        isUpSertStore: !state.isUpSertStore,
        selectedStore: action.objectStore,
      };
    case TOGGLE_UPSERT_TTL:
      return {
        ...state,
        isUpSertTTL: !state.isUpSertTTL,
        selectedStore: action.objectStore,
      };
    case TOGGLE_DISABLE_TTL:
      return {
          ...state,
          isDisableTTL: !state.isDisableTTL,
          selectedStore: action.objectStore,
      };
    case TOGGLE_ENABLE_STREAM:
      return {
        ...state,
        isEnableStream: !state.isEnableStream,
        selectedStore: action.objectStore,
      };
    case TOGGLE_DISABLE_STREAM:
        return {
          ...state,
          isDisableStream: !state.isDisableStream,
          selectedStore: action.objectStore,
        };
    case TOGGLE_SHOW_DETAILS:
      return {
        ...state,
        showStoreDetails: !state.showStoreDetails,
        selectedStore: action.objectStore,
      };
    case TOGGLE_DELETE_STORE:
        return {
          ...state,
          isDeleteStore: !state.isDeleteStore,
          selectedStore: action.objectStore,
        };
    case DELETE_STAGE_OBJECT_STORE:
        return {
          ...state,
          objectStores: _filter(
            state.objectStores,
            (store: any) => store.name !== action.storeName
          ),
        };
    default:
      return state;
  }
}
