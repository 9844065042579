/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { PageHeader, List } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import { Scaffold } from "../../common";
import { ROUTES } from "../../utils";
import { CreateWorkspace } from "./components";

import "./index.less";

const Workspaces = (props: any) => {
  const { loading } = props;
  const history = useHistory();
  const { workspaces = [] } = useSelector((state: any) => state.account);
  const { billing } = useSelector((state: any) => state.auth);

  useEffect(() => {
    if (!billing?.SubscriptionAdded || billing.Disabled) {
      history.push(ROUTES.SETTINGS_BILLING);
    }
  }, [billing]);

  const _dropdownValues = workspaces.map((workspace: any) => {
    return {
      value: workspace.name,
      label: workspace.name,
    };
  });

  return (
    <Scaffold loading={loading}>
      <PageHeader
        className="site-page-header custom"
        title="Choose a workspace"
        ghost={false}
      >
        <CreateWorkspace />
      </PageHeader>
      <section className="workspaces-wrapper px-2 page-content">
        <div className="workspaces">
          <List
            itemLayout="horizontal"
            bordered
            dataSource={[..._dropdownValues]}
            renderItem={(item) => (
              <Link to={`${ROUTES.WORKSPACES}/${item.value}`}>
                <List.Item
                  actions={[<FontAwesomeIcon icon={faChevronRight} />]}
                >
                  {item.label}
                </List.Item>
              </Link>
            )}
          />
        </div>
      </section>
    </Scaffold>
  );
};

export default Workspaces;
