/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-delete react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Empty, Table, Modal, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import UpsertPolicy from "./UpsertPolicy";
import PolicyOptions from "./PolicyOptions";
import { actions } from "../../../../../reducers/policy";
import { actions as accountActions } from "../../../../../reducers/account";
import { actions as serviceActions } from "../../../../../reducers/services";
import { useDeletePolicy } from "../../../../../hooks";
import { customIncludes, handleError } from "../../../../../utils";
import { useParams } from "react-router";

const COLUMNS = [
  { title: "Policy Name", dataIndex: "name", key: "name" },
  { title: "Policy Type", dataIndex: "type", key: "type" },
  {
    title: "Action",
    key: "id",
    render: (cell: string, row: any) => <PolicyOptions {...row} />,
  },
];

const { confirm } = Modal;

const Policies = (props: any) => {
  const { createPolicy, setCreatePolicy } = props;
  const dispatch = useDispatch();
  const [isDeleteCalled, setDeleteCalled] = useState(false);
  const { workspace, stage }: any = useParams();
  const { stagePolicies = [] } = useSelector((state: any) => state.services);
  const [policies, setPolicies] = useState<any[]>([]);
  const { editPolicy, deletePolicy, selectedPolicy } = useSelector(
    (state: any) => state.policy
  );
  const policySearchTerm = useSelector(
    (state: any) => state.policy.policySearchTerm
  );
  const toggleEditPolicy = () => dispatch(actions.toggleEditPolicy(null));
  const toggleDeletePolicy = () => dispatch(actions.toggleDeletePolicy(null));
  const deleteStagePolicy = (policy: any) =>
    dispatch(serviceActions.deleteStagePolicy(policy));
  const deleteStagePolicyForAccount = (policy: any) =>
    dispatch(
      accountActions.deleteStagePolicyForAccount(workspace, stage, policy)
    );
  const {
    loading: deleteLoading,
    error: deleteError,
    execute: executeDelete,
    data: deleteResponse,
  } = useDeletePolicy(selectedPolicy?.name);

  const closeModal = (form: any) => {
    form.resetFields();
    setCreatePolicy(false);
    toggleEditPolicy();
  };

  useEffect(() => {
    if (deletePolicy && selectedPolicy) {
      confirm({
        title: "Are you sure?",
        icon: (
          <FontAwesomeIcon icon={faExclamationCircle} className="anticon" />
        ),
        onOk() {
          executeDelete();
          setDeleteCalled(true);
        },
        onCancel() {
          toggleDeletePolicy();
        },
      });
    }
  }, [deletePolicy]);

  // Delete Policy Effect
  useEffect(() => {
    if (!deleteLoading && !deleteError && isDeleteCalled) {
      deleteStagePolicy(selectedPolicy);
      deleteStagePolicyForAccount(selectedPolicy);
      toggleDeletePolicy();
      setDeleteCalled(false);
    } else if (!deleteLoading && deleteError && isDeleteCalled) {
      handleError(deleteError);
      setDeleteCalled(false);
    }
  }, [deleteLoading, deleteError, deleteResponse]);

  useEffect(() => {
    const _policies = stagePolicies.filter((policy: any) => {
      if (!policySearchTerm) {
        return true;
      } else if (customIncludes(policy.name, policySearchTerm)) {
        return true;
      } else {
        return false;
      }
    });
    setPolicies(_policies);
  }, [policySearchTerm]);

  return (
    <Spin spinning={deleteLoading}>
      <div
        className="http-endpoints policies scroll-y"
        style={{ height: "65vh" }}
      >
        <Table
          size="small"
          columns={COLUMNS}
          scroll={{ y: 320 }}
          rowKey="id"
          dataSource={[...policies]}
          locale={{ emptyText: <Empty description="No policies created" /> }}
        />
      </div>
      {createPolicy || editPolicy ? (
        <UpsertPolicy
          upsertPolicy={createPolicy || editPolicy}
          closeModal={closeModal}
        />
      ) : null}
    </Spin>
  );
};

export default Policies;
