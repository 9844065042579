/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Space, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";

import { useServices } from "../../../../hooks";
import { actions as serviceActions } from "../../../../reducers/services";
import { actions as accountActions } from "../../../../reducers/account";
import { handleError } from "../../../../utils";

const RefreshServices = (props: any) => {
  const { workspace, stage } = useParams<any>();
  const dispatch = useDispatch();
  const [called, setCalled] = useState(false);
  const { loading, error, data, execute } = useServices();
  const updateServices = (services: any) =>
    dispatch(serviceActions.updateStageServices(services));
  const updateStageFunctionsForAcccount = (functions: any) =>
    dispatch(
      accountActions.updateStageFunctionsForAcccount(
        workspace,
        stage,
        functions
      )
    );
  const toggleLoadServies = () =>
    dispatch(serviceActions.toggleLoadingServices());

  useEffect(() => {
    if (!loading && !error && called && data) {
      updateServices(data);
      updateStageFunctionsForAcccount(data);
      setCalled(false);
      toggleLoadServies();
    } else if (!loading && error && called) {
      setCalled(false);
      toggleLoadServies();
      handleError(error);
    }
  }, [loading, error, data]);

  const _execute = () => {
    execute();
    setCalled(true);
    toggleLoadServies();
  };
  return (
    <Space className="w-100 justify-content-end">
      <Button className="mb-0" onClick={_execute}>
        <FontAwesomeIcon icon={faSyncAlt} />
        &nbsp; Refresh
      </Button>
    </Space>
  );
};

export default RefreshServices;
