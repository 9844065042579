const prefix = 'LAYOUT';

// Action types
const TOGGLE_SIDEBAR = `${prefix}_TOGGLE_SIDEBAR`;
const SET_CURRENT_TAB = `${prefix}_SET_CURRENT_TAB`;
// State
const defaultState: any = {
  hasSidebar:true,
  currentTab:null
};

// Actions
const toggleSideBar = (open:boolean) => ({
  type: TOGGLE_SIDEBAR,
  open
});

const setCurrentTab = (currentTab:string) => ({
  type: SET_CURRENT_TAB,
  currentTab
});


export const actions = {
    toggleSideBar,
    setCurrentTab
};

// Reducers
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = defaultState, action: any) {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return { ...state, hasSidebar: action.open };
    case SET_CURRENT_TAB:
      return { ...state, currentTab: action.currentTab };
    default:
      return state;
  }
}
