import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import _toString from "lodash/toString";

/**
 * Updates workspaces with the new stage values
 * @param workspaces list of workspaces
 * @param workspace workspace working on
 * @param stage stage to be updated
 * @returns updated worspace with stage details
 */
export const updateWorkspaceStage = (
  workspaces: any,
  workspace: string,
  stage: any
) => {
  const accIndex = workspaces.findIndex((acc: any) => acc.name === workspace);
  if (accIndex !== -1) {
    workspaces[accIndex].stages = [...workspaces[accIndex].stages, stage];
  } else {
    workspaces = [...workspaces, stage];
  }
  return workspaces;
};

/**
 * Updates workspaces with the removed stage
 * @param workspaces list of workspaces
 * @param workspace workspace working on
 * @param stage stage to be removed
 * @returns updated worspaces after removing stage
 */
export const removeStageFromWorkspace = (
  workspaces: any,
  workspace: string,
  stage: string
) => {
  const accIndex = workspaces.findIndex((acc: any) => acc.name === workspace);
  if (accIndex !== -1) {
    workspaces[accIndex].stages = _filter(
      workspaces[accIndex].stages,
      (stg) => stg.name !== stage
    );
  }
  return workspaces;
};

export const updateStageVariables = (variables: any, updatedVar: any) => {
  const variableIndex = variables.findIndex(
    (variable: any) => variable.name === updatedVar.name
  );
  if (variableIndex !== -1) {
    variables[variableIndex] = updatedVar;
  } else {
    variables = [...variables, updatedVar];
  }
  return variables;
};

/**
 * Updates services with service provided
 * @param services list of services
 * @param service service to update
 * @returns updated services
 */
export const updateStageServiceDetailsByName = (
  services: any,
  service: any
) => {
  const serviceIndex = services.findIndex(
    (serv: any) => serv.name === service.name
  );
  if (serviceIndex !== -1) {
    services[serviceIndex] = { ...services[serviceIndex], ...service };
  } else {
    services = [...services, service];
  }
  return services;
};

/**
 * Updates workspaces with the stage details
 * @param workspaces list of workspaces
 * @param workspace workspace working on
 * @param stage stage working on
 * @param stageDetails stageDetails to be updated
 * @returns updated worspaces after updating stage details
 */
export const updateStageDetailsForWorkspace = (
  workspaces: any,
  workspace: string,
  stage: string,
  stageDetails: any
) => {
  const accIndex = workspaces.findIndex((acc: any) => acc.name === workspace);
  if (accIndex !== -1) {
    const accountStages = workspaces[accIndex]?.stages ?? [];
    const stageIndex = accountStages.findIndex(
      (stg: any) => stg.name === stage
    );
    if (stageIndex !== -1) {
      accountStages[stageIndex] = {
        ...accountStages[stageIndex],
        services: _isEmpty(stageDetails.stageServices)
          ? accountStages[stageIndex].services
          : stageDetails.stageServices,
        stageVariables: _isEmpty(stageDetails.stageVariables)
          ? accountStages[stageIndex].stageVariables
          : stageDetails.stageVariables,
      };
      workspaces[accIndex].stages = accountStages;
    }
  }
  return workspaces;
};

export const updateStagePolicyDetailsByName = (policies: any, policy: any) => {
  const index = policies.findIndex((serv: any) => serv.id === policy.id);
  if (index !== -1) {
    policies[index] = { ...policies[index], ...policy };
  } else {
    policies = [...policies, policy];
  }
  return policies;
};

/**
 * Updates workspaces with the policy
 * @param workspaces list of workspaces
 * @param workspace workspace working on
 * @param stage stage working on
 * @param policy policy to be updated
 * @returns updated worspaces after updating policy
 */
export const updateStagePolicyDetailsForWorkspace = (
  workspaces: any,
  workspace: string,
  stage: string,
  policy: any
) => {
  // Find Workspace
  const accIndex = workspaces.findIndex((acc: any) => acc.name === workspace);
  if (accIndex !== -1) {
    const accountStages = workspaces[accIndex]?.stages ?? [];
    const stageIndex = accountStages.findIndex(
      (stg: any) => stg.name === stage
    );
    // Find Stage
    if (stageIndex !== -1) {
      const stage = accountStages[stageIndex];
      const { policies } = stage;
      // Find Policy
      const policyIndex = policies.findIndex(
        (pol: any) => pol.id === policy.id
      );
      if (policyIndex === -1) {
        stage.policies = [...stage.policies, policy];
      } else {
        stage.policies[policyIndex] = policy;
      }
      accountStages[stageIndex] = stage;
      workspaces[accIndex].stages = accountStages;
    }
  }
  return workspaces;
};

/**
 * Updates workspaces with the functions
 * @param workspaces list of workspaces
 * @param workspace workspace working on
 * @param stage stage working on
 * @param functions functions to be updated
 * @returns updated worspaces after updating functions
 */
export const updateStageFunctionsForWorkspace = (
  workspaces: any,
  workspace: string,
  stage: string,
  functions: any
) => {
  // Find Workspace
  const accIndex = workspaces.findIndex((acc: any) => acc.name === workspace);
  if (accIndex !== -1) {
    const accountStages = workspaces[accIndex]?.stages ?? [];
    const stageIndex = accountStages.findIndex(
      (stg: any) => stg.name === stage
    );
    // Find Stage
    if (stageIndex !== -1) {
      const stage = accountStages[stageIndex];
      stage["services"] = functions;
      accountStages[stageIndex] = stage;
      workspaces[accIndex].stages = accountStages;
    }
  }
  return workspaces;
};

/**
 * Updates workspaces with policy deleted
 * @param workspaces list of workspaces
 * @param workspace workspace working on
 * @param stage stage working on
 * @param policy policy to be removed
 * @returns updated worspaces after removing policy
 */
export const deleteStagePolicyFromWorkspace = (
  workspaces: any,
  workspace: string,
  stage: string,
  policy: any
) => {
  // Find Workspace
  const accIndex = workspaces.findIndex((acc: any) => acc.name === workspace);
  if (accIndex !== -1) {
    const accountStages = workspaces[accIndex]?.stages ?? [];
    const stageIndex = accountStages.findIndex(
      (stg: any) => stg.name === stage
    );
    // Find Stage
    if (stageIndex !== -1) {
      const stage = accountStages[stageIndex];
      const { policies } = stage;
      // Find Policy
      const _updatdePolicies = policies.filter(
        (pol: any) => _toString(pol?.id) !== _toString(policy?.id)
      );
      stage.policies = _updatdePolicies;
      accountStages[stageIndex] = stage;
      workspaces[accIndex].stages = accountStages;
    }
  }
  return workspaces;
};

export const deleteStagePolicyByName = (policies: any[], policy: any) => {
  return _filter(policies, (p: any) => {
    return _toString(p?.id) !== _toString(policy?.id);
  });
};

export const updateWorkspaceDetails = (workspaces: any[], workspace: any) => {
  workspaces.push({
    ...workspace,
    stages: [],
  });
  return workspaces;
};

/**
 * Updates schedulers
 * @param stageServices list of schedulers
 * @param scheduler scheduler to be updated
 * @returns updated schedulers
 */
export const updateStageSchedulerById = (
  stageServices: any[],
  scheduler: any
) => {
  const { functionName, id } = scheduler;
  const functionIndex = stageServices.findIndex(
    (s: any) => s.name === functionName
  );
  if (functionIndex !== -1) {
    const functionDetails = stageServices[functionIndex];
    const { schedulers } = functionDetails;
    const schedulerIndex = schedulers.findIndex((sch: any) => sch.id === id);
    if (schedulerIndex !== -1) {
      schedulers[schedulerIndex] = {
        ...schedulers[schedulerIndex],
        ...scheduler,
      };
      functionDetails.schedulers = schedulers;
    }
    stageServices[functionIndex] = functionDetails;
  }
  return stageServices;
};

/**
 *
 * @param objectStores list of objectStores from state
 * @param objectStore objectStore details to be updated.
 * @returns updated objestStores
 */
export const updateStageObjectStore = (
  objectStores: any[],
  objectStore: any
) => {
  const storeIndex = objectStores.findIndex(
    (store: any) => store.name === objectStore.name
  );
  if (storeIndex !== -1) {
    objectStores[storeIndex] = objectStore;
  } else {
    objectStores = [...objectStores, objectStore];
  }
  return objectStores;
};

/**
 *
 * @param workspaces list of workspaces from state
 * @param workspace workspace to be filtered
 * @param stage stage to be filterd from workspace
 * @param objectStore objectStore details to be updated.
 * @returns updated objestStores
 */

export const updateObjectStoreDetailsForWorkspace = (
  workspaces: any,
  workspace: string,
  stage: string,
  objectStore: any
) => {
  const wrkIndex = workspaces.findIndex((wrk: any) => wrk.name === workspace);
  if (wrkIndex !== -1) {
    const workspaceStages = workspaces[wrkIndex]?.stages ?? [];
    const stageIndex = workspaceStages.findIndex(
      (stg: any) => stg.name === stage
    );
    if (stageIndex !== -1) {
      workspaceStages[stageIndex] = {
        ...workspaceStages[stageIndex],
        objectStores: updateStageObjectStore(
          workspaceStages[stageIndex].objectStores,
          objectStore
        ),
      };
      workspaces[wrkIndex].stages = workspaceStages;
    }
  }
  return workspaces;
};

/**
 *
 * @param workspaces list of workspaces from state
 * @param workspace workspace to be filtered
 * @param stage stage to be filterd from workspace
 * @param objectStore objectStore details to be deleted.
 * @returns updated objestStores
 */

export const deleteObjectStoreDetailsForWorkspace = (
  workspaces: any,
  workspace: string,
  stage: string,
  objectStore: any
) => {
  const wrkIndex = workspaces.findIndex((wrk: any) => wrk.name === workspace);
  if (wrkIndex !== -1) {
    const workspaceStages = workspaces[wrkIndex]?.stages ?? [];
    const stageIndex = workspaceStages.findIndex(
      (stg: any) => stg.name === stage
    );
    if (stageIndex !== -1) {
      workspaceStages[stageIndex] = {
        ...workspaceStages[stageIndex],
        objectStores: _filter(
          workspaceStages[stageIndex].objectStores,
          (store: any) => {
            return store.name !== objectStore.name;
          }
        ),
      };
      workspaces[wrkIndex].stages = workspaceStages;
    }
  }
  return workspaces;
};
