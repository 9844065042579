/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, Spin, Empty, Table, Row, Col, Input, Space } from "antd";

import { useStores } from "../../../../hooks";
import { handleError } from "../../../../utils";
import { getStoreColumms } from "../../columns";
import { faSearch, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { filterStoreValues } from "../../helpers";

const StoreDetails = () => {
  const { showStoreDetails, selectedStore = {} } = useSelector(
    (state: any) => state.stageStore
  );
  const { loading, error, data, execute } = useStores();
  const [called, setCalled] = useState(false);
  const [recentQuery, setRecentQuery] = useState(null);
  const [storeSearchTerm, setStoreSearchTerm] = useState("");
  const [storeValues, setStoreValues] = useState([]);
  const [lastEvaluatedKey, setLastEvaulatedKey] = useState(null);
  const [prevEvaluatedKey, setPrevEvaulatedKey] = useState(null);
  const { workspace, stage }: any = useParams();

  useEffect(() => {
    return () => {
      setStoreSearchTerm("");
    };
  }, []);

  useEffect(() => {
    if (!loading && !error && data && called) {
      const { Items = [], LastEvaluatedKey } = data;
      setStoreValues(Items);
      if (lastEvaluatedKey) {
        setPrevEvaulatedKey(lastEvaluatedKey);
      }
      setLastEvaulatedKey(LastEvaluatedKey);
      setCalled(false);
    } else if (!loading && error && called) {
      handleError(error);
      setCalled(false);
    }
  }, [loading, error, data]);

  useEffect(() => {
    if (selectedStore && showStoreDetails) {
      fetchStoreDetails();
      setCalled(true);
    }
  }, [selectedStore, showStoreDetails]);

  useEffect(() => {
    return () => {
      setStoreValues([]);
      setLastEvaulatedKey(null);
      setPrevEvaulatedKey(null);
    };
  }, []);

  const fetchStoreDetails = (paginationInfo?: any) => {
    setStoreSearchTerm("");
    const data = {
      workspaceName: workspace,
      stageName: stage,
      storeName: selectedStore?.name,
      pageSize: 15,
      ...(paginationInfo ? paginationInfo : {}),
    };
    setRecentQuery(data);
    execute({
      data,
    });
  };

  const getPreviousPage = () => {
    fetchStoreDetails({
      prevEvaluatedKey,
    });
  };

  const getNextPage = () => {
    fetchStoreDetails({
      lastEvaluatedKey,
    });
  };

  const reload = () => {
    execute({
      data: recentQuery,
    });
    setCalled(true);
  };

  const _filteredValues = filterStoreValues(storeValues, storeSearchTerm);

  return (
    <>
      {showStoreDetails ? (
        <Spin spinning={loading}>
          <Space className="justify-content-between w-100 mb-1">
            <Input
              value={storeSearchTerm}
              onChange={(e) => setStoreSearchTerm(e.target.value)}
              placeholder="Search store"
              suffix={<FontAwesomeIcon icon={faSearch} />}
            />
            <Button className="mb-0" onClick={() => reload()}>
              <FontAwesomeIcon icon={faSyncAlt} /> &nbsp; Refresh
            </Button>
          </Space>
          <div className="scroll-y" style={{ height: "55vh" }}>
            <Table
              size="small"
              dataSource={[..._filteredValues]}
              columns={getStoreColumms(false)}
              rowKey="name"
              locale={{
                emptyText: (
                  <Empty description="No store values(s) created yet" />
                ),
              }}
              pagination={false}
            />
            <Row justify="space-between" className="mt-2">
              <Col span="2">
                <Button disabled={!prevEvaluatedKey} onClick={getPreviousPage}>
                  Previous
                </Button>
              </Col>
              <Col span="2">
                <Button disabled={!lastEvaluatedKey} onClick={getNextPage}>
                  Next
                </Button>
              </Col>
            </Row>
          </div>
        </Spin>
      ) : (
        <></>
      )}
    </>
  );
};

export default StoreDetails;
