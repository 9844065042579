/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Spin, Button } from "antd";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { useCreateWorkspace } from "../../../hooks";
import {
  required,
  minimum,
  maximum,
  onlyAlphaNumberic,
  handleError,
} from "../../../utils";
import { actions } from "../../../reducers/account";

const CreateWorkspace = (props: any) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isOpen, toggleModal] = useState(false);
  const [called, setCalled] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [workspaceName, setWorkspaceName] = useState("");
  const updateWorkspaces = (workspace: any) =>
    dispatch(actions.updateWorkspaces(workspace));
  const { loading, error, data, execute } = useCreateWorkspace();

  const executeCreateWorkspace = (form: any) => {
    form
      .validateFields()
      .then((values: any) => {
        form.resetFields();
        execute({
          data: {
            workspace: workspaceName,
          },
        });
        setCalled(true);
      })
      .catch((info: any) => {
        console.log("Validate Failed:", info);
      });
  };

  useEffect(() => {
    if (!loading && !error && called && data) {
      // Update state with new workspace
      toggleModal(false);
      setCalled(false);
      setHasErrors(false);
      updateWorkspaces({
        ...data,
        name: workspaceName,
      });
      setWorkspaceName("");
    } else if (!loading && error && called) {
      setHasErrors(true);
      handleError(error);
    }
  }, [loading, error, data]);

  const _onClose = () => {
    setCalled(false);
    setHasErrors(false);
    toggleModal(!isOpen);
  };

  return (
    <>
      <Button type="primary" className="mb-0" onClick={() => toggleModal(true)}>
        <FontAwesomeIcon icon={faPlus} />
        &nbsp; Create Workspace
      </Button>
      <Modal
        title="Create Workspace"
        visible={isOpen}
        centered
        destroyOnClose
        onOk={() => executeCreateWorkspace(form)}
        onCancel={_onClose}
        okButtonProps={{ disabled: !workspaceName || hasErrors }}
        okText="Create Workspace"
        cancelText="Cancel"
      >
        <Spin spinning={loading}>
          <Form layout="vertical" form={form}>
            <Form.Item
              label="Workspace Name"
              name="workspaceName"
              rules={[
                required("Workspace Name"),
                minimum("Workspace Name"),
                maximum("Workspace Name", 10),
                onlyAlphaNumberic(),
              ]}
              normalize={(value: string) => value.trim()}
            >
              <Input
                value={workspaceName}
                onChange={(e) => setWorkspaceName(e.target.value)}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default CreateWorkspace;
