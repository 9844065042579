import React from "react";
import { Card, Typography } from "antd";
import DeleteWorkspace from "./DeleteWorkspace";
import DeleteStage from "./DeleteStage";

const DangerZone = (props: any) => {
  return (
    <Card
      title={<Typography.Text type="danger">Danger Zone</Typography.Text>}
      bordered={false}
    >
      <DeleteWorkspace />
      <hr />
      <DeleteStage />
    </Card>
  );
};

export default DangerZone;
