import { localStorage, LOCAL_STORE_KEYS } from "../utils";

const prefix = "AUTH";

// Action types
const SET_DATA = `${prefix}_SET_DATA`;
const SET_USER_DATA = `${prefix}_SET_USER_DATA`;
const SET_BILLING_DATA = `${prefix}_SET_BILLING_DATA`;
const SET_BILLING_SUBSCRIPTION = `${prefix}_SET_BILLING_SUBSCRIPTION`;
const SET_BILLING_DISABLED = `${prefix}_SET_BILLING_DISABLED`;

// State
const defaultState: any = {
  userData: null,
  authData: null,
  billing: null,
};

// Actions
const setAuthData = (authData: any) => ({
  type: SET_DATA,
  authData,
});

const setUserData = (userData: any) => ({
  type: SET_USER_DATA,
  userData,
});

const setBillingData = (billing: any) => ({
  type: SET_BILLING_DATA,
  billing,
});

const setBillingSubscription = () => ({
  type: SET_BILLING_SUBSCRIPTION,
});

const setBillingDisabled = () => ({
  type: SET_BILLING_DISABLED,
});

export const actions = {
  setAuthData,
  setUserData,
  setBillingData,
  setBillingSubscription,
  setBillingDisabled,
};

// Reducers
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = defaultState, action: any) {
  switch (action.type) {
    case SET_DATA:
      return { ...state, authData: action.authData };
    case SET_USER_DATA:
      return { ...state, userData: action.userData };
    case SET_BILLING_DATA:
      return { ...state, billing: action.billing };
    case SET_BILLING_SUBSCRIPTION: {
      const _billing = { ...state.billing, SubscriptionAdded: true };
      localStorage.setItem(
        LOCAL_STORE_KEYS.BILLING_DATA,
        JSON.stringify(_billing)
      );
      return {
        ...state,
        billing: _billing,
      };
    }
    case SET_BILLING_DISABLED: {
      const _billing = { ...state.billing, Disabled: false };
      localStorage.setItem(
        LOCAL_STORE_KEYS.BILLING_DATA,
        JSON.stringify(_billing)
      );
      return { ...state, billing: _billing };
    }
    default:
      return state;
  }
}
