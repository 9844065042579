const prefix = "SCHEDULER";

// Action types
const TOGGLE_ENABLE_SCHEDULER = `${prefix}_TOGGLE_ENABLE`;
const TOGGLE_DISABLE_SCHEDULER = `${prefix}_TOGGLE_DISABLE`;
const TOGGLE_EDIT_SCHEDULER = `${prefix}_TOGGLE_EDIT`;
const TOGGLE_RUN_NOW_SCHEDULER = `${prefix}_TOGGLE_RUN_NOW`;

// State
const defaultState: any = {
  enableScheduler: false,
  disableScheduler: false,
  editScheduler: false,
  runNowScheduler: false,
  lambdaARN: null,
  scheduler: null,
};

// Actions
const toggleEnableScheduler = (scheduler: any) => ({
  type: TOGGLE_ENABLE_SCHEDULER,
  scheduler,
});

const toggleDisableScheduler = (scheduler: any) => ({
  type: TOGGLE_DISABLE_SCHEDULER,
  scheduler,
});

const toggleEditScheduler = (scheduler: any) => ({
  type: TOGGLE_EDIT_SCHEDULER,
  scheduler,
});

const toggleRunNowScheduler = (scheduler: any) => ({
  type: TOGGLE_RUN_NOW_SCHEDULER,
  scheduler,
});

export const actions = {
  toggleEnableScheduler,
  toggleDisableScheduler,
  toggleEditScheduler,
  toggleRunNowScheduler,
};

// Reducers
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = defaultState, action: any) {
  switch (action.type) {
    case TOGGLE_ENABLE_SCHEDULER:
      return {
        ...state,
        enableScheduler: !state.enableScheduler,
        scheduler: action.scheduler,
      };
    case TOGGLE_DISABLE_SCHEDULER:
      return {
        ...state,
        disableScheduler: !state.disableScheduler,
        scheduler: action.scheduler,
      };
    case TOGGLE_EDIT_SCHEDULER:
      return {
        ...state,
        editScheduler: !state.editScheduler,
        scheduler: action.scheduler,
      };
    case TOGGLE_RUN_NOW_SCHEDULER:
      return {
        ...state,
        runNowScheduler: !state.runNowScheduler,
        scheduler: action.scheduler,
      };
    default:
      return state;
  }
}
