import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Dropdown, Menu, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisV,
  faPlay,
  faStop,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";

import { actions } from "../../../../../reducers/schedulers";
import { SCHEDULER_ENABLED, SCHEDULER_DISABLED } from "../../../../../utils";
import useOutsideClick from "../../../../../hooks/helpers";

const SchedulerOptions = (props: any) => {
  const { state: status } = props;
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();
  const [isOpen, toggleMenu] = useState(false);

  const toggleEnableScheduler = () =>
    dispatch(actions.toggleEnableScheduler(props));
  const toggleDisableScheduler = () =>
    dispatch(actions.toggleDisableScheduler(props));
  const toggleEditScheduler = () =>
    dispatch(actions.toggleEditScheduler(props));
  const toggleRunNowScheduler = () =>
    dispatch(actions.toggleRunNowScheduler(props));

  useOutsideClick(wrapperRef, () => toggleMenu(false));

  return (
    <div ref={wrapperRef}>
      <Dropdown
        overlay={
          <Menu className="cell-options">
            <Menu.Item key="editScheduler">
              <Button
                type="text"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleEditScheduler();
                  toggleMenu(false);
                }}
              >
                <FontAwesomeIcon icon={faEdit} />
                &nbsp; Edit
              </Button>
            </Menu.Item>
            <Menu.Item key="runNowScheduler">
              <Button
                type="text"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleRunNowScheduler();
                  toggleMenu(false);
                }}
              >
                <FontAwesomeIcon icon={faPlay} />
                &nbsp; Run Now
              </Button>
            </Menu.Item>
            {status === SCHEDULER_DISABLED && (
              <Menu.Item key="enableScheduler">
                <Button
                  type="text"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleEnableScheduler();
                    toggleMenu(false);
                  }}
                >
                  <FontAwesomeIcon icon={faPlay} />
                  &nbsp; Enable
                </Button>
              </Menu.Item>
            )}
            {status === SCHEDULER_ENABLED && (
              <Menu.Item key="disableScheduler">
                <Button
                  type="text"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleDisableScheduler();
                    toggleMenu(false);
                  }}
                >
                  <FontAwesomeIcon icon={faStop} />
                  &nbsp; Disable
                </Button>
              </Menu.Item>
            )}
          </Menu>
        }
        placement="bottomRight"
        arrow
        onVisibleChange={() => toggleMenu(true)}
        trigger={["click"]}
        visible={isOpen}
      >
        <FontAwesomeIcon icon={faEllipsisV} />
      </Dropdown>
    </div>
  );
};

export default SchedulerOptions;
