import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Dropdown, Menu, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisV,
  faPlay,
  faStop,
  faTrash,
  faStream,
} from "@fortawesome/free-solid-svg-icons";

import {
  DEPLOY_FAILED,
  SERVICE_DELETING,
  SERVICE_STARTED,
  SERVICE_STOPPED,
} from "../../../../utils";
import { actions } from "../../../../reducers/services";
import useOutsideClick from "../../../../hooks/helpers";

const ServiceOptions = (props: any) => {
  const { status, name: serviceName, version } = props;
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const [isOpen, toggleMenu] = useState(false);
  const toggleDeleteService = (serviceName: string, serviceVersion: number) =>
    dispatch(actions.toggleDeleteService(serviceName, serviceVersion));
  const toggleStartService = (serviceName: string) =>
    dispatch(actions.toggleStartService(serviceName));
  const toggleStopService = (serviceName: string) =>
    dispatch(actions.toggleStopService(serviceName));
  const toggleServiceLogs = (serviceName: string) =>
    dispatch(actions.toggleServiceLogs(serviceName));
  useOutsideClick(wrapperRef, () => toggleMenu(false));
  return (
    <div ref={wrapperRef}>
      <Dropdown
        overlay={
          <Menu className="cell-options">
            <Menu.Item key="service_started_stopped">
              {status === SERVICE_STOPPED && (
                <Button
                  type="text"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleMenu(false);
                    toggleStartService(serviceName);
                  }}
                >
                  <FontAwesomeIcon icon={faPlay} />
                  &nbsp; Start
                </Button>
              )}{" "}
              {status === SERVICE_STARTED && (
                <Button
                  type="text"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleMenu(false);
                    toggleStopService(serviceName);
                  }}
                >
                  <FontAwesomeIcon icon={faStop} />
                  &nbsp;Stop
                </Button>
              )}
            </Menu.Item>
            {status !== SERVICE_DELETING && (
              <Menu.Item key="service_logs">
                <Button
                  type="text"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleMenu(false);
                    toggleServiceLogs(serviceName);
                  }}
                >
                  <FontAwesomeIcon icon={faStream} />
                  &nbsp; Logs
                </Button>
              </Menu.Item>
            )}
            {[SERVICE_STOPPED, SERVICE_STARTED, DEPLOY_FAILED].includes(
              status
            ) && (
              <Menu.Item key="service_delete">
                <Button
                  type="text"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleMenu(false);
                    toggleDeleteService(serviceName, version);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                  &nbsp;Delete
                </Button>
              </Menu.Item>
            )}
          </Menu>
        }
        placement="bottomRight"
        arrow
        onVisibleChange={() => toggleMenu(true)}
        visible={isOpen}
        trigger={["click"]}
      >
        <FontAwesomeIcon icon={faEllipsisV} />
      </Dropdown>
    </div>
  );
};

export default ServiceOptions;
