/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form, Input, Button, Card, Space, Spin, Result } from "antd";
import { UserOutlined, LockOutlined, KeyOutlined } from "@ant-design/icons";

import kumohubLogo from "../assets/kumohub-logo.png";
import { useForgotPassword, useConfirmPassword } from "../hooks";
import { handleError } from "../utils";

import "./Auth.less";

interface ForgotPasswordProps {
  [key: string]: any;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = (props: any) => {
  const [forgotCalled, setForgotCalled] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [confirmCalled, setConfirmCalled] = useState(false);
  const [forgotSuccess, setForgotSuccess] = useState(false);

  const {
    loading: forgotLoading,
    error: forgotError,
    data: forgotData,
    execute: executeForgotPassword,
  } = useForgotPassword();

  const {
    loading: confirmLoading,
    error: confirmError,
    data: confirmData,
    execute: executeConfirmPassword,
  } = useConfirmPassword();

  const onFinish = (values: any) => {
    if (forgotSuccess) {
      const { confirmPassword, ...rest } = values;
      executeConfirmPassword({
        data: {
          ...rest,
        },
      });
      setConfirmCalled(true);
    } else {
      executeForgotPassword({
        data: {
          ...values,
        },
      });
      setForgotCalled(true);
    }
  };

  useEffect(() => {
    if (!forgotLoading && !forgotError && forgotData && forgotCalled) {
      setForgotSuccess(true);
      setSuccessMessage(
        `Please check your ${forgotData?.CodeDeliveryDetails?.Destination} e-mail for confirmation code`
      );
      setForgotCalled(false);
    } else if (!forgotLoading && forgotError && forgotCalled) {
      setForgotSuccess(false);
      handleError(forgotError);
      setForgotCalled(false);
    }
  }, [forgotLoading, forgotError, forgotData, forgotCalled]);

  useEffect(() => {
    if (!confirmLoading && !confirmError && confirmData && confirmCalled) {
      setSuccessMessage("Your password reset has successful");
      setTimeout(() => {
        window.location.pathname = "/login";
      }, 2000);
      setConfirmCalled(false);
    } else if (!confirmLoading && confirmError && confirmCalled) {
      handleError(confirmError);
      setConfirmCalled(false);
    }
  }, [confirmLoading, confirmError, confirmData, confirmCalled]);

  return (
    <div className="d-flex login-bg">
      <div className="login-wrapper">
        <div className="content">
          <Card className="login">
            <Space className="w-100 justify-content-center">
              <img alt="Kumohub" src={kumohubLogo} className="login-logo" />
            </Space>
            <h3 className="t-center">Reset Password</h3>
            <p>
              Enter your email address below and we'll send you a code to reset
              your password.
            </p>
            <Spin className="login-form" spinning={forgotLoading}>
              {successMessage && (
                <Result status="success" title={successMessage}></Result>
              )}
              <Form layout="vertical" name="basic" onFinish={onFinish}>
                <Form.Item
                  label="Email Address"
                  name="username"
                  rules={[
                    { required: true, message: "Please input your email!" },
                  ]}
                >
                  <Input prefix={<UserOutlined />} />
                </Form.Item>
                {forgotSuccess && (
                  <>
                    <Form.Item
                      label="Password"
                      name="proposedPassword"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input.Password prefix={<LockOutlined />} />
                    </Form.Item>
                    <Form.Item
                      label="Confirm Password"
                      name="confirmPassword"
                      dependencies={["proposedPassword"]}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password!",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              !value ||
                              getFieldValue("proposedPassword") === value
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                "The two passwords that you entered do not match!"
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password prefix={<LockOutlined />} />
                    </Form.Item>
                    <Form.Item
                      label="Confirmation Code"
                      name="confirmationCode"
                      rules={[
                        {
                          required: true,
                          message: "Please input your confirmation code!",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input.Password prefix={<KeyOutlined />} />
                    </Form.Item>
                  </>
                )}
                <Button type="primary" htmlType="submit" block size="large">
                  Reset Password
                </Button>
              </Form>
            </Spin>
          </Card>
          <div className="mt-1 t-center">
            <a href="/login" className="t-default">
              Login
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
