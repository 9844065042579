/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Space, Input, Empty } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import Stage from "./Stage";
import CreateStage from "./CreateStage";
import { filterStages } from "../../../utils";
import { actions } from "../../../reducers/account";

const Stages = (props: any) => {
  const { stages = [], name } = props;
  const dispatch = useDispatch();
  const { stageSearchTerm } = useSelector((state: any) => state.account);
  const setStageSearchTerm = (searchTerm: string) =>
    dispatch(actions.setStageSearchTerm(searchTerm));
  const _filteredStages = filterStages(stages, stageSearchTerm);
  useEffect(() => {
    return () => {
      setStageSearchTerm("");
    };
  }, []);
  return (
    <div className="px-2">
      <Space align="center" className="justify-content-between w-100 mb-2 px-0">
        <Input
          key="stageSearch"
          value={stageSearchTerm}
          onChange={(e) => setStageSearchTerm(e.target.value)}
          placeholder="Search stages"
          suffix={<FontAwesomeIcon icon={faSearch} />}
        />
        <CreateStage workspace={name} />
      </Space>
      {_filteredStages.length > 0 ? (
        <section className="scroll-y" style={{ height: "65vh" }}>
          <Row gutter={[16, 16]} className="m-0">
            {_filteredStages.map((stage: any) => {
              return (
                <Col key={stage.name} span={6}>
                  <Stage {...stage} workspace={name} />
                </Col>
              );
            })}
          </Row>
        </section>
      ) : (
        <section
          className="scroll-y d-flex justify-content-center"
          style={{ height: "65vh" }}
        >
          <Empty description={"No stages in workspace yet"} />
        </section>
      )}
    </div>
  );
};
export default Stages;
