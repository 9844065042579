/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, notification } from "antd";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import { useDeleteStore } from "../../../../hooks";
import { actions } from "../../../../reducers/stageStore";
import { actions as accountActions } from "../../../../reducers/account";

import { handleError } from "../../../../utils";

const { confirm } = Modal;

const deleteStore = (
  executeDisable: any,
  toggleDeleteStore: any,
  data: any
) => {
  confirm({
    title: "Are you sure? You want to Delete Store",
    icon: <FontAwesomeIcon icon={faExclamationCircle} className="anticon" />,
    onOk() {
      executeDisable({
        data,
      });
    },
    onCancel() {
      toggleDeleteStore(null);
    },
  });
};

const DeleteStore = (props: any) => {
  const { workspace, stage }: any = useParams();
  const dispatch = useDispatch();
  const { isDeleteStore, selectedStore } = useSelector(
    (state: any) => state.stageStore
  );
  const toggleDeleteStore = (objectStore: any) =>
    dispatch(actions.toggleDeleteStore(objectStore));
  const deleteStageObjectStore = (storeName: string) =>
    dispatch(actions.deleteObjectStore(storeName));
  const deleteObjectStoreDetailsForAccount = (objectStore: any) =>
    dispatch(
      accountActions.deleteObjectStoreDetailsForAccount(
        workspace,
        stage,
        objectStore
      )
    );
  const {
    loading: deleteStoreLoading,
    data: deleteStoreData,
    error: deleteStoreError,
    execute: executeDeleteStore,
  } = useDeleteStore();

  useEffect(() => {
    if (isDeleteStore) {
      deleteStore(executeDeleteStore, toggleDeleteStore, {
        workspaceName: workspace,
        stageName: stage,
        storeName: selectedStore.name,
      });
    }
    // return () => {
    //   toggleDisableTTL(null);
    // };
  }, [isDeleteStore]);

  useEffect(() => {
    if (!deleteStoreLoading && !deleteStoreError && deleteStoreData) {
      deleteStageObjectStore(selectedStore.name);
      deleteObjectStoreDetailsForAccount({
        ...selectedStore,
      });
      notification.success({
        message: deleteStoreData?.message ?? "Store deleted successfully",
        duration: 3,
      });
      toggleDeleteStore(null);
    } else if (!deleteStoreLoading && deleteStoreError) {
      handleError(deleteStoreError);
      toggleDeleteStore(null);
    }
  }, [deleteStoreLoading, deleteStoreError, deleteStoreData]);

  return <></>;
};

export default DeleteStore;
