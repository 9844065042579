/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  Card,
  Steps,
  Button,
  Row,
  Col,
  Form,
  message,
  Spin,
  Result,
  Space,
  Typography,
} from "antd";
import { useParams } from "react-router";

import { usePolicyGateway } from "../../../../../hooks";
import { Naming, Security, TrafficControl, Summary } from "./upsertSteps";
import { handleError, SECURITY_TYPE } from "../../../../../utils";
import { actions as serviceActions } from "../../../../../reducers/services";
import { actions as accountActions } from "../../../../../reducers/account";
import { extractIntialValues, hasChanges } from "./helpers";

const { Step } = Steps;

const getSteps = (props: any) => [
  {
    title: "Naming",
    content: <Naming {...props} />,
  },
  {
    title: "Security",
    content: <Security {...props} />,
  },
  {
    title: "Traffic Control",
    content: <TrafficControl {...props} />,
  },
  {
    title: "Summary",
    content: <Summary {...props} />,
  },
];

const UpsertPolicy = (props: any) => {
  const { upsertPolicy, closeModal } = props;
  const dispatch = useDispatch();
  const { workspace, stage } = useParams<any>();
  const [current, setCurrent] = useState(0);
  const [txCompleted, setTxCompleted] = useState(false);
  const [isValidData, setIsValidData] = useState(true);
  const { selectedPolicy } = useSelector((state: any) => state.policy);
  const extractedValues = extractIntialValues(selectedPolicy);
  const {
    initialSecurity,
    initialSignatureType,
    initialWhiteListType,
    apiKeyValue,
    users: _users,
    claims: _claims,
    ...initialValues
  } = extractedValues;
  const [security, setSecurityValue] = useState(
    initialSecurity ?? SECURITY_TYPE.NONE
  );
  const [signatureType, setSignatureType] = useState(initialSignatureType);
  const [whiteListType, setWhiteListType] = useState(initialWhiteListType);
  const [apiKey, setAPIKey] = useState(apiKeyValue);
  const [users, setUsers] = useState(_users ?? []);
  const [claims, setClaims] = useState(_claims ?? []);
  const [requestData, setRequestData] = useState<any>();
  const [called, setCalled] = useState(false);
  const [form] = Form.useForm();
  const {
    loading,
    error,
    data,
    execute: executeCreate,
  } = usePolicyGateway(security, initialValues?.id);
  const updateStagePolicy = (policy: any) =>
    dispatch(serviceActions.updateStagePolicy(policy));
  const updateStagePoliciesForAccount = (policy: any) =>
    dispatch(
      accountActions.updateStagePoliciesForAccount(workspace, stage, policy)
    );
  useEffect(() => {
    if (!loading && !error && called) {
      setCalled(false);
      const { finalRequest, security } = requestData;
      const policy = {
        id: data?.id ?? initialValues?.id,
        type: security,
        name: finalRequest.policyName,
        ...finalRequest,
      };
      updateStagePolicy(policy);
      updateStagePoliciesForAccount(policy);
      setTxCompleted(true);
      setAPIKey(data?.apiKey);
    } else if (!loading && error && called) {
      setCalled(false);
      handleError(error);
    }
  }, [loading, error, called, data]);

  useEffect(() => {
    if (security === SECURITY_TYPE.BASIC && users.length === 0) {
      setIsValidData(false);
    } else {
      setIsValidData(true);
    }
  }, [security, users]);

  useEffect(() => {
    return () => {
      setCurrent(0);
      setTxCompleted(false);
    };
  }, []);

  const next = () => {
    form
      .validateFields()
      .then((values) => {
        console.log(values);
        setCurrent(current + 1);
      })
      .catch((info: any) => {
        console.log("Validate Failed:", info);
      });
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const submitPolicy = () => {
    const { isValidData, finalRequest } = requestData;
    if (!isValidData) {
      message.warning("Resolve validation errors before creation");
    } else {
      executeCreate({
        data: {
          workspace,
          stage,
          ...finalRequest,
        },
      });
      setCalled(true);
    }
  };
  const steps = getSteps({ id: initialValues?.id });

  const _hasChanges = hasChanges(requestData, selectedPolicy);

  return (
    <Modal
      title={initialValues?.id ? "Edit Policy" : "Create Policy"}
      width={900}
      visible={upsertPolicy}
      centered
      destroyOnClose
      footer={null}
      onCancel={() => closeModal(form)}
    >
      <Card>
        <Form form={form} initialValues={initialValues}>
          <Spin spinning={loading}>
            {!txCompleted && (
              <>
                <Row>
                  <Col span="5">
                    <Steps direction="vertical" current={current}>
                      {steps.map((item) => (
                        <Step key={item.title} title={item.title} />
                      ))}
                    </Steps>
                  </Col>
                  <Col span="19">
                    <div className="steps-content">
                      {React.cloneElement(steps[current].content, {
                        security,
                        setSecurityValue,
                        signatureType,
                        setSignatureType,
                        whiteListType,
                        setWhiteListType,
                        users,
                        setUsers,
                        claims,
                        setClaims,
                        form,
                        setRequestData,
                        apiKey,
                      })}
                    </div>
                  </Col>
                </Row>
                <Row align="stretch" justify="space-between">
                  <Button
                    style={{ margin: "0 8px" }}
                    onClick={() => prev()}
                    disabled={current === 0}
                  >
                    Previous
                  </Button>
                  {current < steps.length - 1 && (
                    <Button
                      type="primary"
                      onClick={() => next()}
                      disabled={!isValidData}
                    >
                      Next
                    </Button>
                  )}
                  {current === steps.length - 1 && !txCompleted && (
                    <Button
                      type="primary"
                      onClick={submitPolicy}
                      disabled={!_hasChanges}
                      title={
                        initialValues?.id && !_hasChanges
                          ? "Please make a change to update"
                          : ""
                      }
                    >
                      {initialValues?.id ? "Update" : "Create"}
                    </Button>
                  )}
                </Row>
              </>
            )}
            {txCompleted && (
              <Result
                status="success"
                title={
                  initialValues?.id
                    ? "Policy updated successfully"
                    : "Policy created successfully"
                }
                subTitle={
                  apiKey && (
                    <Space>
                      <Typography.Paragraph strong>
                        API Key Details :{" "}
                      </Typography.Paragraph>
                      <Typography.Paragraph copyable>
                        {apiKey}
                      </Typography.Paragraph>
                    </Space>
                  )
                }
              />
            )}
          </Spin>
        </Form>
      </Card>
    </Modal>
  );
};

export default UpsertPolicy;
