/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Spin, Tabs, Space, Select, Button } from "antd";
import _isEmpty from "lodash/isEmpty";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { actions } from "../../../../reducers/services";
import { useServiceLogs } from "../../../../hooks";
import { TIME_PERIODS, handleError } from "../../../../utils";
import { LogViewer, ColorMode } from "../../../../components";

const ShowServiceLogs = () => {
  const dispatch = useDispatch();
  const [logType, setLogType] = useState("deployment");
  const [called, setCalled] = useState(false);
  const [logData, setLogData] = useState<any>([]);
  const [timePeriod, setTimePeriod] = useState(300);
  const { showServiceLogs, serviceName } = useSelector(
    (state: any) => state.services
  );
  const toggleServiceLogs = (service: any) =>
    dispatch(actions.toggleServiceLogs(service));
  const { loading, error, data, execute } = useServiceLogs({
    serviceName,
    logType,
  });
  useEffect(() => {
    if (showServiceLogs && serviceName && logType && timePeriod) {
      execute();
      setCalled(true);
    }
  }, [showServiceLogs, serviceName, logType, timePeriod]);

  useEffect(() => {
    if (!loading && !error && called) {
      setLogData(_isEmpty(data) ? [] : data);
      setCalled(false);
    } else if (!loading && error && called) {
      setLogData([]);
      setCalled(false);
      handleError(error);
    }
  }, [loading, error, data, called]);

  const reloadLogs = () => {
    execute();
    setCalled(true);
  };

  return (
    <Modal
      title="Logs"
      width="80%"
      destroyOnClose
      visible={showServiceLogs}
      centered
      maskClosable={false}
      footer={null}
      onCancel={() => toggleServiceLogs("")}
    >
      <Space className="justify-content-end w-100">
        <Button onClick={() => reloadLogs()}>
          <FontAwesomeIcon icon={faSyncAlt} />
          &nbsp; Refresh
        </Button>
        <Select
          style={{ width: "200px" }}
          options={TIME_PERIODS}
          className="ml-auto"
          placeholder="Select Time Period"
          clearIcon
          value={timePeriod}
          onChange={(value) => setTimePeriod(value)}
        />
      </Space>
      <Tabs defaultActiveKey={logType} onChange={setLogType}>
        <Tabs.TabPane tab="Deployment" key="deployment">
          <Spin spinning={loading}>
            <div>
              <LogViewer logs={logData} colorMode={ColorMode.Light} />
            </div>
          </Spin>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Application" key="service">
          <Spin spinning={loading}>
            <div>
              <LogViewer logs={logData} colorMode={ColorMode.Light} />
            </div>
          </Spin>
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
};

export default ShowServiceLogs;
