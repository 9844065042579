/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Divider, Input } from "antd";
import moment from "moment";
import _includes from "lodash/includes";

import "./style.css";

export enum ColorMode {
  Light,
  Dark,
}

export interface Props {
  name?: string;
  prompt?: string;
  colorMode?: ColorMode;
  logs: any;
}

const logTimeStamp = (time: any) => {
  return moment(time).format("DD:MM:YYY hh:mm:ss Z");
};

const transformLogsToLineData = (logs: any, searchTerm: string) => {
  const filteredLogs: string[] = [];
  logs?.forEach((log: any) => {
    if (_includes(log.message, searchTerm)) {
      filteredLogs.push(`${logTimeStamp(log.timestamp)}: \n ${log.message}`);
    }
  });
  return filteredLogs;
};

const LogViewer = (props: Props) => {
  const { logs } = props;

  const lastLineRef = useRef<null | HTMLElement>(null);

  const [lineData, setLineData] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    const _lineData = transformLogsToLineData(logs ?? [], searchTerm);
    setLineData(_lineData);
  }, [logs, searchTerm]);

  // An effect that handles scrolling into view the last line of terminal input or output
  useEffect(() => {
    setTimeout(
      () => lastLineRef?.current?.scrollIntoView({ behavior: "smooth" }),
      500
    );
  }, [lineData.length]);

  const renderedLineData = lineData.map((ld: string, i: number) => {
    const classes = ["react-terminal-line"];
    // `lastLineRef` is used to ensure the terminal scrolls into view to the last line; make sure to add the ref to the last
    // redendered line if input prompt is not shown, i.e. `onInput` is not declared; see 'render prompt' below
    if (lineData.length === i + 1) {
      return (
        <>
          <span className={classes.join(" ")} key={i} ref={lastLineRef}>
            {ld}
          </span>
          <Divider />
        </>
      );
    } else {
      return (
        <>
          <span className={classes.join(" ")} key={i}>
            {ld}
          </span>
          <Divider />
        </>
      );
    }
  });

  const classes = ["react-terminal-wrapper"];
  if (props.colorMode === ColorMode.Light) {
    classes.push("react-terminal-light");
  }
  return (
    <>
      <Input.Search
        placeholder="Search Logs"
        className="mb-1"
        onSearch={setSearchTerm}
      />
      <div className={classes.join(" ")} data-terminal-name={props.name}>
        <div className="react-terminal">{renderedLineData}</div>
      </div>
    </>
  );
};

export default LogViewer;
