/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Space,
  Button,
  Modal,
  Form,
  Spin,
  Tabs,
  Input,
  InputNumber,
  Select,
  Row,
  Col,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

import _map from "lodash/map";

import { useCreateService } from "../../../../hooks";
import { actions as serviceActions } from "../../../../reducers/services";
import {
  required,
  minimum,
  onlyAlphaNumberic,
  maximum,
  handleError,
} from "../../../../utils";
const CreateService = (props: any) => {
  const { workspace, stage } = useParams<any>();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [called, setCalled] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [isOpen, toggleModal] = useState(false);
  const [formData, setFormData] = useState<any>({});
  const [file, setFile] = useState<any>();
  const { loading, error, data, execute } = useCreateService();
  const updateService = (service: any) =>
    dispatch(serviceActions.updateStageService(service));
  const createStage = (form: any) => {
    form
      .validateFields()
      .then((values: any) => {
        form.resetFields();
        values = {
          ...values,
          account: workspace,
          stage,
          file,
        };
        const formData = new FormData();
        const envVariables = _map(values.environment, (env: any) => {
          return { key: env.key, value: env.value };
        });
        values.environment = JSON.stringify(envVariables);
        setFormData(values);
        Object.keys(values).forEach((key) => {
          if (values[key]) {
            formData.append(key, values[key]);
          }
        });
        execute({
          data: formData,
        });
        setCalled(true);
      })
      .catch((info: any) => {
        console.log("Validate Failed:", info);
      });
  };
  const _setFile = (e: any) => {
    setFile(e.target.files[0]);
  };

  useEffect(() => {
    if (!loading && !error && called && data) {
      const id = new Date().getTime();
      updateService({
        id: data.id || id,
        name: formData?.serviceName,
        version: 1,
        status: data.status || "Deploying",
        deployDate: id,
        envVariables: formData?.environment,
      });
      toggleModal(false);
      setCalled(false);
    } else if (!loading && error && called) {
      setCalled(false);
      handleError(error);
    }
  }, [loading, error, data]);

  const _cancelCreate = () => {
    toggleModal(false);
    setHasErrors(false);
  };

  const isVariablesValid = (fields: any, add: any) => {
    let valid = true;
    fields.forEach((field: any) => {
      if (!field.key || !field.value) {
        valid = false;
      }
    });
    if (valid) {
      add();
    }
  };

  return (
    <>
      <Space className="w-100 justify-content-end">
        <Button
          type="primary"
          className="mb-0"
          onClick={() => toggleModal(true)}
        >
          <FontAwesomeIcon icon={faPlus} />
          &nbsp; Create Deployment
        </Button>
      </Space>
      <Modal
        title="Create Deployment"
        visible={isOpen}
        width="80%"
        centered
        destroyOnClose
        onOk={() => createStage(form)}
        onCancel={_cancelCreate}
        okButtonProps={{
          disabled: hasErrors,
        }}
        okText="Create"
        cancelText="Cancel"
        className="create-service"
      >
        <Spin spinning={loading}>
          <Form layout="vertical" form={form}>
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane tab="Function Details" key="1">
                <Form.Item
                  label="Service Name"
                  name="serviceName"
                  rules={[
                    required("Service Name"),
                    minimum("Service Name", 3),
                    maximum("Service Name", 30),
                  ]}
                  normalize={(value: string) => value.trim()}
                >
                  <Input placeholder="Service Name" />
                </Form.Item>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={6}>
                    <Form.Item label="Memory" name="memory">
                      <Select placeholder="Select Memory">
                        <Select.Option value={256}>256 Mb</Select.Option>
                        <Select.Option value={512}>512 Mb</Select.Option>
                        <Select.Option value={1024}>1024 Mb</Select.Option>
                        <Select.Option value={2048}>2048 Mb</Select.Option>
                        <Select.Option value={4096}>4096 Mb</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Time Out (seconds)" name="timeout">
                      <InputNumber min={1} max={900} placeholder="30" />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  label="Project zip file"
                  name="file"
                  rules={[{ required: true, message: "Missing File" }]}
                >
                  <Input
                    type="file"
                    value={file}
                    onChange={(e) => _setFile(e)}
                    accept=".zip"
                  />
                </Form.Item>
                <Form.Item
                  label="Comments"
                  name="comments"
                  rules={[
                    onlyAlphaNumberic(),
                    minimum("Comments", 5),
                    maximum("Comments", 150),
                  ]}
                >
                  <Input.TextArea placeholder="Comments" />
                </Form.Item>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Environment Variables" key="2">
                <Form.List name="environment">
                  {(fields, { add, remove }) => (
                    <>
                      <div className="h-350">
                        {fields.map((field) => (
                          <Space
                            key={field.key}
                            align="center"
                            className="w-100"
                          >
                            <Form.Item
                              {...field}
                              label="Key"
                              name={[field.name, "key"]}
                              rules={[
                                required("Key"),
                                minimum("Key"),
                                maximum("Key"),
                              ]}
                              normalize={(value: string) => value.trim()}
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              label="Value"
                              name={[field.name, "value"]}
                              rules={[
                                required("Value"),
                                minimum("Value"),
                                maximum("Value"),
                              ]}
                            >
                              <Input />
                            </Form.Item>
                            <div onClick={() => remove(field.name)}>
                              <FontAwesomeIcon icon={faTrash} />
                            </div>
                          </Space>
                        ))}
                      </div>
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => isVariablesValid(fields, add)}
                          block
                          icon={<FontAwesomeIcon icon={faPlus} />}
                        >
                          &nbsp;Add Variable
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Tabs.TabPane>
            </Tabs>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default CreateService;
