import useAxios from "axios-hooks";

import { ROOT_URL } from "../../utils";
import { useAuthHeaders } from "../auth";

const useDeleteService = () => {
  const headers = useAuthHeaders();

  const [{ data, loading, error }, execute] = useAxios(
    {
      url: `${ROOT_URL}/deployer-service/delete`,
      method: "POST",
      headers,
    },
    { manual: true }
  );

  return {
    data,
    loading,
    error,
    execute,
  };
};

export default useDeleteService;

/**
 * Post Data
 * [
 *  {"workspace": "NonProd"},
 *  {"stage": "UAT"},
 *  {"serviceName": "orders"}
 * ]
 */
