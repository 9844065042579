import moment from "moment";
import _filter from "lodash/filter";
import _includes from "lodash/includes";
import _lowercase from "lodash/lowerCase";
import _find from "lodash/find";
import _transform from "lodash/transform";
import _isEqual from "lodash/isEqual";
import _isObject from "lodash/isObject";
import _isNil from "lodash/isNil";

import { GLOBAL_DATE_FORMAT } from "./constants";

const KNOWN_STATUS_CODES = [409, 400];

/**
 * Format the date into defined format
 * @param date date to be converted to format
 * @returns formatted date
 */
export const globalDateFormat = (date: string) => {
  return moment(date).format(GLOBAL_DATE_FORMAT);
};

export const localStorage = window.localStorage;

export const knownStatusCode = (statusCode: number) => {
  return _includes(KNOWN_STATUS_CODES, statusCode);
};

/**
 * Extarcts error message from error object
 * @param error error object
 * @returns clear error message from error object
 */
export const getErrorMessage = (error: any) => {
  const { data, status } = error?.response ?? {};
  if (status) {
    if (knownStatusCode(status)) {
      return data?.errorMessage ?? data?.error;
    }
  }
  // Sending empty string so that the Result component's subtitle will be empty
  return "";
};

/**
 * Filter the workspaces and return the selected one
 * @param workspaces list of workspaces
 * @param selected selected workspace
 * @returns workspace that is selected
 */
export const filterWorkspace = (workspaces: any, selected: any) => {
  return _find(
    workspaces,
    (workspace: any) => selected.value === workspace.name
  );
};

/**
 * Filter the stages and return the selected one
 * @param stages list of stages
 * @param selected selected stage
 * @returns stage that is selected
 */
export const filterStages = (stages: any, searchTerm: string) => {
  return _filter(stages, (stage: any) =>
    _includes(_lowercase(stage.name), _lowercase(searchTerm))
  );
};

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export const difference = (object: any, base: any) => {
  function changes(object: any, base: any) {
    return _transform(object, function (result: any, value: any, key: string) {
      if (
        (!_isNil(value) || !_isNil(base[key])) &&
        !_isEqual(value, base[key])
      ) {
        result[key] =
          _isObject(value) && _isObject(base[key])
            ? changes(value, base[key])
            : value;
      }
    });
  }
  return changes(object, base);
};

/**
 *
 * @param workspaces list of workspaces to build map data
 * @returns map data with names of workspaces stages of those workspaces
 */
export const buildWorkspaceAndStageMap = (workspaces: any[] = []) => {
  const result: {
    workspaceOptions: string[];
    [key: string]: string[];
  } = {
    workspaceOptions: [],
  };

  workspaces.forEach((workspace: any) => {
    const { name, stages } = workspace;
    result.workspaceOptions.push(name);
    result[name] = stages.map((stage: any) => stage.name);
  });
  return result;
};

/**
 * To get window.location.orgin
 * @returns location origin
 */
export const getOrigin = () => {
  if (!window) {
    return "http://localhost:3000";
  }
  return window.location.origin;
};

export const customIncludes = (str1: string, str2: string) => {
  return _includes(_lowercase(str1), _lowercase(str2));
};
