import _find from "lodash/find";
import _map from "lodash/map";
import _filter from "lodash/filter";
import _includes from "lodash/includes";
import _lowercase from "lodash/lowerCase";

import { globalDateFormat } from "../../utils";

export const getStageDetails = (
  workspaces: any,
  workspaceName: string,
  stage: string
) => {
  const workspace = _find(workspaces, {
    name: workspaceName,
  });
  return _find(workspace?.stages, {
    name: stage,
  });
};

export const getStagesByAcccount = (workspaces: any, workspaceName: string) => {
  const workspace = _find(workspaces, {
    name: workspaceName,
  });

  return _map(workspace?.stages, (stage: any) => stage.name);
};

export const getServicesList = (services: any = []) => {
  return _map(services, (service: any) => {
    return {
      value: service.id,
      label: service.name,
    };
  });
};

export const filterServices = (services: any, searchTerm: string) => {
  return _filter(services, (service: any) =>
    _includes(_lowercase(service?.name), _lowercase(searchTerm))
  );
};

export const filterVariables = (variables: any, searchTerm: string) => {
  return _filter(
    variables,
    (variable: any) =>
      _includes(_lowercase(variable?.name), _lowercase(searchTerm)) ||
      _includes(_lowercase(variable?.value), _lowercase(searchTerm))
  );
};

export const buildMetricData = (
  data: any,
  xValueKey: string,
  yValueKey: string
) => {
  let result: any[] = [];
  if (Array.isArray(data)) {
    data = data[0];
  }
  const xValues = data[xValueKey] ?? [];
  const yValues = data[yValueKey] ?? [];
  if (xValues.length !== yValues.length) {
    return result;
  }
  xValues.forEach((value: any, i: number) => {
    result = [
      ...result,
      {
        x: globalDateFormat(value),
        y: yValues[i],
      },
    ];
  });
  return result;
};

export const filterObjectStores = (objectStores: any, searchTerm: string) => {
  return _filter(objectStores, (variable: any) =>
    _includes(_lowercase(variable?.name), _lowercase(searchTerm))
  );
};

export const filterStoreValues = (storeValues :any , searchTerm:string) => {
  return _filter(storeValues, (variable: any) =>
    { 
      let found = false;
      found = found || _includes(_lowercase(variable?.name), _lowercase(searchTerm))
      found = found || _includes(_lowercase(variable?.value), _lowercase(searchTerm))
      if(typeof variable?.value === 'object'){
        try{
          let _str = JSON.stringify(variable?.value);
          found = found || _includes(_lowercase(_str), _lowercase(searchTerm))
        }
        catch(e){
        }
      }
      return found
    }
  );
}
