import React from "react";
import DangerZone from "./danger-zone";

const OrgAccount = (props: any) => {
  return (
    <section className="scroll-y" style={{ height: "65vh" }}>
      <DangerZone />
    </section>
  );
};

export default OrgAccount;
