/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Spin, Table, Modal, Empty, Card, Input, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  faExclamationCircle,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useEnableScheduler, useDisableScheduler } from "../../../../../hooks";
import { customIncludes, handleError } from "../../../../../utils";
import { actions } from "../../../../../reducers/schedulers";
import { actions as serviceActions } from "../../../../../reducers/services";
import SchedulerOptions from "./SchedulerOptions";
import EditScheduler from "./EditScheduler";
import { SCHEDULER_ENABLED, SCHEDULER_DISABLED } from "../../../../../utils";
import { useQuery } from "../../../../../components";
import useRunNowScheduler from "../../../../../hooks/schedulers/runNowScheduler";

const columns = [
  { title: "ID", dataIndex: "id", key: "id" },
  { title: "Function Name", dataIndex: "functionName", key: "functionName" },
  { title: "Type", dataIndex: "type", key: "type" },
  {
    title: "Expression",
    dataIndex: "expression",
    key: "expression",
  },
  {
    title: "Reference",
    dataIndex: "reference",
    key: "reference",
  },
  { title: "Description", dataIndex: "description", key: "description" },
  { title: "State", dataIndex: "state", key: "state" },
  {
    title: "Action",
    key: "action",
    render: (cell: string, row: any) => <SchedulerOptions {...row} />,
  },
];

const { confirm } = Modal;

const Schedulers = () => {
  const { stageServices = [] } = useSelector((state: any) => state.services);
  const { workspace, stage }: any = useParams();
  const [schedulerSearchTerm, setSchedulerSearchTerm] = useState("");
  const [schedulers, setSchedulers] = useState([]);
  const dispatch = useDispatch();
  const query = useQuery();
  const schedulerIdFromParams = query.get("scheduler");

  const {
    enableScheduler,
    disableScheduler,
    editScheduler,
    scheduler,
    runNowScheduler,
  } = useSelector((state: any) => state.schedulers);
  const toggleEnableScheduler = (scheduler: any) =>
    dispatch(actions.toggleEnableScheduler(scheduler));
  const toggleDisableScheduler = (scheduler: any) =>
    dispatch(actions.toggleDisableScheduler(scheduler));
  const toggleEditScheduler = (scheduler: any) =>
    dispatch(actions.toggleEditScheduler(scheduler));
  const toggleRunNowScheduler = (scheduler: any) =>
    dispatch(actions.toggleRunNowScheduler(scheduler));
  const updateStageScheduler = (scheduler: any) =>
    dispatch(serviceActions.updateStageScheduler(scheduler));
  const {
    loading: enableLoading,
    error: enableError,
    execute: executeEnable,
    data: enableResponse,
  } = useEnableScheduler();
  const {
    loading: disableLoading,
    error: disableError,
    execute: executeDisable,
    data: disableResponse,
  } = useDisableScheduler();

  const {
    loading: runNowLoading,
    error: runNowError,
    execute: executeRunNow,
    data: runNowResponse,
  } = useRunNowScheduler();

  // Enable Scheduler Effect
  useEffect(() => {
    if (!enableLoading && enableResponse) {
      updateStageScheduler({
        ...scheduler,
        state: SCHEDULER_ENABLED,
      });
      toggleEnableScheduler(null);
    } else if (!enableLoading && enableError) {
      handleError(enableError);
    }
  }, [enableLoading, enableError, enableResponse]);

  // Disable Scheduler Effect
  useEffect(() => {
    if (!disableLoading && disableResponse) {
      updateStageScheduler({
        ...scheduler,
        state: SCHEDULER_DISABLED,
      });
      toggleDisableScheduler(null);
    } else if (!disableLoading && disableError) {
      handleError(disableError);
    }
  }, [disableLoading, disableError, disableResponse]);

  // Run Now Scheduler Effect
  useEffect(() => {
    if (!runNowLoading && runNowResponse) {
      notification.success({
        message: runNowResponse?.message ?? "Scheduler ran successfully",
      });
      toggleRunNowScheduler(null);
    } else if (!disableLoading && disableError) {
      handleError(disableError);
    }
  }, [runNowLoading, runNowError, runNowResponse]);

  // When Enable is triggered
  useEffect(() => {
    if (enableScheduler && scheduler) {
      const { functionName, id } = scheduler;
      executeEnable({
        data: {
          id,
          workspace,
          stage,
          serviceName: functionName,
        },
      });
    }
  }, [enableScheduler]);

  // When Disable is triggered
  useEffect(() => {
    if (disableScheduler && scheduler) {
      confirm({
        title: "Are you sure, you want to disable ?",
        icon: (
          <FontAwesomeIcon icon={faExclamationCircle} className="anticon" />
        ),
        onOk() {
          const { functionName, id } = scheduler;
          executeDisable({
            data: {
              id,
              workspace,
              stage,
              serviceName: functionName,
            },
          });
        },
        onCancel() {
          toggleDisableScheduler(null);
        },
      });
    }
  }, [disableScheduler]);

  // When Run Now is triggered
  useEffect(() => {
    if (runNowScheduler && scheduler) {
      confirm({
        title: "Are you sure you want to run now?",
        icon: (
          <FontAwesomeIcon icon={faExclamationCircle} className="anticon" />
        ),
        onOk() {
          const { functionName, reference } = scheduler;
          executeRunNow({
            data: {
              reference,
              workspace,
              stage,
              serviceName: functionName,
            },
          });
        },
        onCancel() {
          toggleRunNowScheduler(null);
        },
      });
    }
  }, [runNowScheduler]);

  useEffect(() => {
    const _schedulers = stageServices.reduce((acc: any, func: any) => {
      const _schedulers: any[] = [];
      func?.schedulers.forEach((sch: any) => {
        if (customIncludes(sch.id, schedulerSearchTerm)) {
          _schedulers.push({
            ...sch,
            functionName: func.name,
          });
        }
      });
      acc = [...acc, ..._schedulers];
      return acc;
    }, []);
    setSchedulers(_schedulers);
  }, [stageServices, schedulerSearchTerm]);

  useEffect(() => {
    if (schedulerIdFromParams) {
      setSchedulerSearchTerm(schedulerIdFromParams);
    }
  }, [schedulerIdFromParams]);

  useEffect(() => {
    return () => {
      setSchedulerSearchTerm("");
    };
  }, []);
  return (
    <Card
      title="Schedulers"
      bordered={false}
      extra={
        <Input
          value={schedulerSearchTerm}
          onChange={(e) => setSchedulerSearchTerm(e.target.value)}
          placeholder="Search by id"
          suffix={<FontAwesomeIcon icon={faSearch} />}
        />
      }
    >
      <div className="schedulers scroll-y" style={{ height: "65vh" }}>
        <Spin spinning={enableLoading || disableLoading}>
          <Table
            size="small"
            columns={columns}
            rowKey={(record: any) => record.id + record.functionName}
            scroll={{ y: 320 }}
            dataSource={schedulers}
            locale={{
              emptyText: <Empty description="No scheduler created yet" />,
            }}
          />
        </Spin>
      </div>
      <EditScheduler
        editScheduler={editScheduler}
        toggleEditScheduler={toggleEditScheduler}
        scheduler={scheduler}
      />
    </Card>
  );
};

export default Schedulers;
