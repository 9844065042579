import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Layout,
  Menu,
  Dropdown,
  Avatar,
  Space,
  Typography,
  Button,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";

import { signOut } from "../../auth/utils";
import { ROUTES } from "../../utils";
import kumologicaLogo from "../../assets/kumohub-logo.png";
import "./Header.less";

const { Header: AntdHeader } = Layout;
const { Title } = Typography;

interface HeaderProps {
  [key: string]: any;
}

const getUserMenu = (userData: any) => {
  return (
    <Menu className="user-dropdown">
      <Menu.Item key="-1" disabled>
        {userData.userName}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1">
        <Link to={ROUTES.SETTINGS}>Settings</Link>
      </Menu.Item>
      <Menu.Item key="2">
        <a
          href="https://docs.kumologica.com/docs/guide/Intro.html"
          target="_black"
        >
          Help
        </a>
      </Menu.Item>
      <Menu.Item key="3">
        <Button type="text" onClick={signOut} style={{ padding: "0 0 0 11px" }}>
          Log Out
        </Button>
      </Menu.Item>
    </Menu>
  );
};

const getInitials = (username: string = "") => {
  return username
    ?.split(" ")
    .map((x) => x.charAt(0))
    .join("")
    .substr(0, 2)
    .toUpperCase();
};
const Header: React.FC<HeaderProps> = (props) => {
  const { userData } = useSelector((state: any) => state.auth);
  return (
    <AntdHeader className="header fixed">
      <Link className="logo" to={ROUTES.WORKSPACES}>
        <img alt="Kumologica logo" src={kumologicaLogo} className="brand" />
      </Link>
      <Space className="index-right" align="center">
        <Title level={5} className="m-0">
          {userData.company ? (
            <>
              <FontAwesomeIcon icon={faBuilding} />
              {"  " + userData.company}
            </>
          ) : (
            ""
          )}
        </Title>
        <Dropdown
          overlay={getUserMenu(userData)}
          placement="bottomRight"
          arrow
          trigger={["click"]}
        >
          <Avatar>{getInitials(userData.userName)}</Avatar>
        </Dropdown>
      </Space>
    </AntdHeader>
  );
};

export default Header;
